import React, { useEffect, useState } from "react";
import styles from "../styles/VendorRFQStyles.module.css";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import MiniDrawer from "../components/MiniDrawer";
import MUIDataTable from "mui-datatables";
import { Drawer, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddNewTransaction from "../Modals/AddNewTransaction";
import PaymentTimelineDrawer from "../components/PaymentTimelineDrawer";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import moment from "moment";

import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import addWeeks from "date-fns/addWeeks";
// import { DatePicker, Space } from 'antd';
// const { RangePicker } = DatePicker;


import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import PO_Payment_List from "../Modals/PO_Payment_List";

function PurchaseOrderList({ base_url, msg_popUp }) {
  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");
  let role_id = storage.getItem("Role_id");
  let pr_id = storage.getItem("PR_ID");

  const navigate = useNavigate();

  const [POListData, setPOListData] = useState([]);

  const [POPaymentDetails, setPOPaymentDetails] = useState([]);
  const [POPaymentTimeline, setPOPaymentTimeline] = useState([]);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentRefNo, setPaymentRefNo] = useState("");
  const [paymentFile, setPaymentFile] = useState({});
  const [checkPaymentFile, setCheckPaymentFile] = useState("");
  const [po_id, setPo_id] = useState("");
  const [isTimelineLoading, setIsTimelineLoading] = useState(false);
  const [controller, setController] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [filteredRows, setFilteredRows] = useState(POListData);
  const [sendFromDate, setSendFromDate] = useState("");
  const [sendToDate, setSendToDate] = useState("");

  // *******************ADD PAYMENTS*******************
  const [openAddPayment, setOpenAddPayment] = useState(false);
  const handleOpenAddPayment = () => setOpenAddPayment(true);
  const handleCloseAddPayment = () => setOpenAddPayment(false);

  // *******************PAYMENT TIMELINE*******************
  const [openPaymentTimeline, setOpenPaymentTimeline] = useState(false);
  const handleOpenPaymentTimeline = () => setOpenPaymentTimeline(true);
  const handleClosePaymentTimeline = () => setOpenPaymentTimeline(false);

  const getPurchaseOrderList = async () => {
    setisLoading(true);
    try {
      let res = await fetch(
        `${base_url}/Purchase_Order.svc/get_PURCHASE_ORDER_LIST`
      );
      let data = await res.json();
      if (data) {
        setPOListData(data?.PURCHASE_ORDER_LIST);
        setisLoading(false);
      } else {
        setPOListData([]);
      }
    } catch (error) {
      msg_popUp("error", "Something went wrong!");
      console.log(error);
      setisLoading(false);
    }
  };

  useEffect(() => {
    getPurchaseOrderList();
  }, []);

  // const handleSelectDateRange = (dateRange) => {
  //   if(dateRange){
  //     const startDate = moment(dateRange[0].toISOString().split('T')[0]).add(1, 'days').format("YYYY-MM-DD");
  //     const endDate = moment(dateRange[1].toISOString().split('T')[0]).add(1, 'days').format("YYYY-MM-DD");
  //     console.log(startDate, endDate)
  //     setSelectedFromDate(dateRange[0])
  //     setSelectedToDate(dateRange[1])
  //     const filteredData = POListData.filter((row) => {
  //       const rowDate = moment(row.PO_DATE).format("YYYY-MM-DD");
  //       return rowDate >= startDate && rowDate <= endDate;
  //     });
  //     // setFilteredRows(filteredData);
  //     setPOListData(filteredData)
  //     console.log(filteredData)
  //   }
  //   else {
  //     setSelectedFromDate(new Date())
  //     setSelectedToDate(new Date())
  //     getPurchaseOrderList();

  //     // setFilteredRows(POListData);              // Reset if no date range is selected
  //   }

  // }

  // const predefinedRanges = [
  //   {
  //     label: "Today",
  //     value: [new Date(), new Date()],
  //     placement: "left",
  //   },
  //   {
  //     label: "Yesterday",
  //     value: [addDays(new Date(), -1), addDays(new Date(), -1)],
  //     placement: "left",
  //   },
  //   {
  //     label: "This week",
  //     value: [startOfWeek(new Date()), endOfWeek(new Date())],
  //     placement: "left",
  //   },
  //   {
  //     label: "Last week",
  //     value: [
  //       startOfWeek(addWeeks(new Date(), -1)),
  //       endOfWeek(addWeeks(new Date(), -1)),
  //     ],
  //     placement: "left",
  //   },
  //   // {
  //   //   label: 'Last 7 days',
  //   //   value: [subDays(new Date(), 6), new Date()],
  //   //   placement: 'left'
  //   // },
  //   // {
  //   //   label: 'Last 30 days',
  //   //   value: [subDays(new Date(), 29), new Date()],
  //   //   placement: 'left'
  //   // },
  //   {
  //     label: "This month",
  //     value: [startOfMonth(new Date()), new Date()],
  //     placement: "left",
  //   },
  //   {
  //     label: "Last month",
  //     value: [
  //       startOfMonth(addMonths(new Date(), -1)),
  //       endOfMonth(addMonths(new Date(), -1)),
  //     ],
  //     placement: "left",
  //   },
  //   {
  //     label: "This year",
  //     value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
  //     placement: "left",
  //   },
  //   {
  //     label: "Last year",
  //     value: [
  //       new Date(new Date().getFullYear() - 1, 0, 1),
  //       new Date(new Date().getFullYear(), 0, 0),
  //     ],
  //     placement: "left",
  //   },
  //   {
  //     label: "All time",
  //     value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
  //     placement: "left",
  //   },
  //   // {
  //   //   label: 'Last week',
  //   //   closeOverlay: false,
  //   //   value: value => {
  //   //     const [start = new Date()] = value || [];
  //   //     return [
  //   //       addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
  //   //       addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
  //   //     ];
  //   //   },
  //   //   appearance: 'default'
  //   // },
  //   // {
  //   //   label: 'Next week',
  //   //   closeOverlay: false,
  //   //   value: value => {
  //   //     const [start = new Date()] = value || [];
  //   //     return [
  //   //       addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
  //   //       addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
  //   //     ];
  //   //   },
  //   //   appearance: 'default'
  //   // }
  // ];

 // Date Range presets
//  const rangePresets = [
//   {
//     label: "Today",
//     value: [dayjs(), dayjs()],
//   },
//   {
//     label: "Yesterday",
//     value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
//   },
//   {
//     label: "Last 7 Days",
//     value: [dayjs().add(-7, "d"), dayjs()],
//   },
//   {
//     label: "Last 14 Days",
//     value: [dayjs().add(-14, "d"), dayjs()],
//   },
//   {
//     label: "Last 30 Days",
//     value: [dayjs().add(-30, "d"), dayjs()],
//   },
//   {
//     label: "Last 90 Days",
//     value: [dayjs().add(-90, "d"), dayjs()],
//   },
// ];

  // const columns = [
  //   {
  //     name: "PO_ID",
  //     label: "PO ID",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       display: false,
  //     },
  //   },
  //   // {
  //   //
  //   {
  //     name: "PO_NUMBER",
  //     label: "P.O Number",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customHeadLabelRender: () => {
  //         return (
  //           <div
  //             style={{
  //               textAlign: "left",
  //               color: "white",
  //               marginTop: "3px",
  //               marginLeft: "10px",
  //             }}
  //           >
  //             P.O Number
  //           </div>
  //         );
  //       },
  //       customBodyRender: (row, data) => {
  //         return (
  //           <>
  //             {row ? (
  //               <div
  //                 style={{
  //                   width: "100%",
  //                   marginLeft: "10px",
  //                   textAlign: "left",
  //                 }}
  //               >
  //                 {row}
  //               </div>
  //             ) : (
  //               "-"
  //             )}
  //           </>
  //         );
  //       },
  //     },
  //   },
  //   {
  //     name: "PO_DATE",
  //     label: "P.O Date",
  //     options: {
  //       filter: true,
  //       sort: false,
  //       filterType: 'custom',
  //       customFilterListOptions: {

  //       },
  //       filterOptions: {
  //         logic: (age, filters) => {

  //         },
  //         display: (filterList, onChange, index, column) => (
  //           <div>
  //             <label id={styles.filter_label} htmlFor="">Payment Date</label>
  //            <DateRangePicker
  //                   format="dd MMM yyyy"
  //                   ranges={predefinedRanges}
  //                   value={[selectedFromDate, selectedToDate]}
  //                   onChange={handleSelectDateRange}
  //                   style={{ width: "400px" }}
  //                   size="md"
  //                   placement="bottomEnd"
  //                   preventOverflow
  //                 /> 
  //           </div>
  //         ),
  //       },
  //     },
  //   },
  //   {
  //     name: "VENDOR_NAME",
  //     label: "Vendor Name",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       display: false,
  //     },
  //   },
  //   {
  //     name: "TOTAL_PO_AMOUNT",
  //     label: "P.O Price (₹)",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (row, data) => {
  //         let price = new Intl.NumberFormat("en-IN").format(row);
  //         return <>{price || price != "NaN" ? <div>{price}</div> : "-"}</>;
  //       },
  //     },
  //   },
  //   {
  //     name: "TOTAL_PAID_PO_AMOUNT",
  //     label: "P.O Price Paid (₹)",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       display: false,
  //       customBodyRender: (row, data) => {
  //         let price = new Intl.NumberFormat("en-IN").format(row);
  //         return <>{price || price != "NaN" ? <div>{price}</div> : "-"}</>;
  //       },
  //     },
  //   },
  //   {
  //     name: "TOTAL_REMAINING_PO_AMOUNT",
  //     label: "P.O Remaining Amount (₹)",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (row, data) => {
  //         let price = new Intl.NumberFormat("en-IN").format(row);
  //         return <>{price || price != "NaN" ? <div>{price}</div> : "-"}</>;
  //       },
  //     },
  //   },
  //   // {
  //   //   name: "PO_COPY_PATH",
  //   //   label: "P.O Copy",
  //   //   options: {
  //   //     filter: false,
  //   //     sort: false,
  //   //     display: false,
  //   //     customBodyRender: (row, data) => {
  //   //       return (
  //   //         <>
  //   //           {
  //   //           row ?
  //   //           <a href={row} target="blank"><PictureAsPdfIcon style={{color:"red"}} /></a>
  //   //           :
  //   //           "-"
  //   //           }
  //   //         </>
  //   //       );
  //   //     },
  //   //   },
  //   // },
  //   // {
  //   //   name: "",
  //   //   label: "Add Bills",
  //   //   options: {
  //   //     filter: false,
  //   //     sort: false,
  //   //     display: false,
  //   //     customBodyRender: (row, data) => {
  //   //       return (
  //   //         <div>
  //   //            <Tooltip title="Add Bills" placement="top">
  //   //           <AddIcon
  //   //             style={{fontSize:"18px", color:"#007cc3", border:"1px solid #007cc3", borderRadius:"50%"}}
  //   //             onClick={(e) => handleAddBills(data)}
  //   //           />
  //   //           </Tooltip>
  //   //         </div>
  //   //       );
  //   //     },
  //   //   },
  //   // },
  //   {
  //     name: "",
  //     label: "Action",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (row, data) => {
  //         return (
  //           <div
  //           // style={{display:"flex", gap:"8px"}}
  //           >
  //             <Tooltip title="Add Payments" placement="top">
  //               <button
  //                 className="btn btn-primary btn-sm"
  //                 // onClick={(e) => handleAddPayment(data)}
  //                 onClick={(e) => handleClickAddPayment(data)}
  //                 style={{
  //                   display: "flex",
  //                   alignItems: "center",
  //                   fontSize: "12px",
  //                   display: Number(data?.rowData[6]) <= 0 ? "none" : "flex",
  //                 }}
  //               >
  //                 <AddIcon style={{ fontSize: "14px" }} /> Payment
  //               </button>
  //             </Tooltip>
  //           </div>
  //         );
  //       },
  //     },
  //   },
  //   {
  //     name: "",
  //     label: "Timeline",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (row, data) => {
  //         return (
  //           <div
  //           // style={{ border:"1px solid #007cc3", borderRadius:"50%", padding:"2px"}}
  //           >
  //             <Tooltip title="Payments Timeline" placement="top">
  //               <TrendingUpIcon
  //                 style={{ fontSize: "22px", color: "#007cc3" }}
  //                 // onClick={(e) => handleTimeline(data)}
  //                 onClick={(e) => handleClickTimeline(data)}
  //               />
  //             </Tooltip>
  //           </div>
  //         );
  //       },
  //     },
  //   },
  // ];

  // const options = {
  //   filterType: "checkbox",
  //   // filterType: "dropdown",
  //   // responsive: "standard",
  //   // customFilterDialogFooter: () => null,
  // };

  const handleClickAddPayment = (data) => {
    if (controller) {
      controller.abort();
    }
    handleAddPayment(data);
  };

  const handleAddPayment = async (data) => {
    handleOpenAddPayment();
    const abortController = new AbortController();
    setController(abortController);
    const signal = abortController.signal;
    let PO_id = data.rowData[0];
    setPo_id(data.rowData[0]);
    try {
      // let res = await fetch(`${base_url}/Purchase_Order.svc/get_PURCHASE_ORDER_PAYMENT_TIMELINE/${PO_id}`);
      let res = await fetch(
        `${base_url}/Purchase_Order.svc/get_PURCHASE_ORDER_PAYMENT_DATA/${PO_id}`,
        { signal }
      );
      let data = await res.json();
      if (data?.response?.CODE == "200") {
        setPOPaymentDetails(data?.PURCHASE_ORDER_PAYMENT);
      } else {
        handleCloseAddPayment();
        msg_popUp("error", "Something went wrong! Please try again.");
      }
    } catch (error) {
      handleCloseAddPayment();
      console.log(error);
      // msg_popUp("error", "Something went wrong!")
    }
  };

  const handleClickTimeline = (data) => {
    if (controller) {
      controller.abort();
    }
    handleTimeline(data);
  };

  const handleTimeline = async (data) => {
    setIsTimelineLoading(true);
    handleOpenPaymentTimeline();
    const abortController = new AbortController();
    setController(abortController);
    const signal = abortController.signal;
    let PO_id = data.rowData[0];
    setPo_id(data.rowData[0]);
    try {
      let res = await fetch(
        `${base_url}/Purchase_Order.svc/get_PURCHASE_ORDER_PAYMENT_TIMELINE/${PO_id}`,
        { signal }
      );
      let data = await res.json();
      if (data?.response?.CODE == "200") {
        // setPOPaymentDetails(data?.PURCHASE_ORDER_PAYMENT);
        setPOPaymentTimeline(data?.PURCHASE_ORDER_PAYMENT_TIMELINE);
        setIsTimelineLoading(false);
      } else {
        setIsTimelineLoading(false);
        handleClosePaymentTimeline();
        msg_popUp("error", "Something went wrong! Please try again.");
      }
    } catch (error) {
      setIsTimelineLoading(false);
      handleClosePaymentTimeline();
      console.log(error);
    }
  };

  return (
    <>
      <MiniDrawer header_name={"Purchase Order Payment"} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <PO_Payment_List
            POListData={POListData}
            setPOListData={setPOListData}
            isLoading={isLoading}
            handleClickAddPayment={handleClickAddPayment}
            handleClickTimeline={handleClickTimeline}
          />
          {/* <MUIDataTable
            title={<h4 style={{ textAlign: "left" }}>P.O Payment</h4>}
            // data={filteredRows}
            data={POListData}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: true,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              tableBodyMaxHeight: "63vh",
              rowsPerPageOptions: [10, 25, 50, 100],
              setTableProps: () => {
                return {
                  padding: "default",
                };
              },
              textLabels: {
                body: {
                  noMatch: (
                    <p style={{ fontSize: "16px", margin: "5px 0px" }}>
                      Data Not Available
                    </p>
                  ),
                },
              },
            }}
          /> */}
        </div>
      )}

      <AddNewTransaction
        base_url={base_url}
        user_id={user_id}
        open={openAddPayment}
        handleOpen={handleOpenAddPayment}
        handleClose={handleCloseAddPayment}
        po_id={po_id}
        POPaymentDetails={POPaymentDetails}
        setPOPaymentDetails={setPOPaymentDetails}
        paymentAmount={paymentAmount}
        setPaymentAmount={setPaymentAmount}
        paymentDate={paymentDate}
        setPaymentDate={setPaymentDate}
        paymentType={paymentType}
        setPaymentType={setPaymentType}
        paymentRefNo={paymentRefNo}
        setPaymentRefNo={setPaymentRefNo}
        paymentFile={paymentFile}
        setPaymentFile={setPaymentFile}
        checkPaymentFile={checkPaymentFile}
        setCheckPaymentFile={setCheckPaymentFile}
        getPurchaseOrderList={getPurchaseOrderList}
        msg_popUp={msg_popUp}
      />

      <Drawer
        anchor="right"
        open={openPaymentTimeline}
        onClose={handleClosePaymentTimeline}
        sx={{ zIndex: "9999" }}
      >
        <PaymentTimelineDrawer
          base_url={base_url}
          user_id={user_id}
          open={openPaymentTimeline}
          handleOpen={handleOpenPaymentTimeline}
          handleClose={handleClosePaymentTimeline}
          po_id={po_id}
          POPaymentTimeline={POPaymentTimeline}
          isTimelineLoading={isTimelineLoading}
        />
      </Drawer>
      
    </>
  );
}

export default PurchaseOrderList;
