import React, { useState } from "react";
import styles from "../styles/Login.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import recruitment from "../Media/hr_illustrations.jpg";
import equinoxLogo from "../Media/equinox_logo.png";
import Swal from "sweetalert2";

function Login({msg_popUp}) {

  const storage = window.sessionStorage;
  const navigate = useNavigate();

  const [mobNumber, setMobNumber] = useState("");
  const [password, setPassword] = useState("");

   const handleLogin = () => {
    let obj = {
      MOBILE: mobNumber,
      PASSWORD: password,
    };
    login_post(obj);
  };

  const login_post = async (obj) => {
    if (obj.MOBILE == "" || obj.PASSWORD == "") {
      msg_popUp("error", "All Fields Are Compulsory!")
      return;
    }
    else {
      try {
        let res = await fetch(
          "https://orion.equinoxlab.com/api/User.svc/User_login",
          {
            method: "Post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              MOBILE: obj.MOBILE,
              PASSWORD: obj.PASSWORD,
            }),
          }
        );
        let data = await res.json();
        if (data.response[0].MESSAGE == "Insert valid Login id and Password") {
          msg_popUp("error", "Please enter right credentials!")
        }
        if (data.response[0].MESSAGE == "Please check user id or password") {
          msg_popUp("error", "Please check user id or password")
        }
        if (
            data.response[0].MESSAGE == "Login successfully" && 
            data.data[0].DEPT == "Purchase" || 
            data.data[0].DEPT == "Office of CEO" 
             ) {
          storage.setItem("USER_GUID", data.data[0].USER_GUID);
          storage.setItem("profile_img", data.data[0].PROFILE_IMG);
          storage.setItem("first_name", data.data[0].F_NAME);
          storage.setItem("last_name", data.data[0].L_NAME);
          storage.setItem("Role_id", data.data[0].ROLE_ID);
          storage.setItem("EMAIL", data.data[0].EMAIL);
          storage.setItem("DEPT_ID", data.data[0].DEPT_ID);
          storage.setItem("DEPT", data.data[0].DEPT);
          storage.setItem("DESIGNATION_ID", data.data[0].DESIGNATION_ID);
          storage.setItem("DESIGNATION", data.data[0].DESIGNATION);
          msg_popUp("success", "Login successful!")
          navigate("/purchaseRequest");
        } 
        else{
          msg_popUp("error", "You do not have access to this software!")
        }
      } catch (error) {
          console.log(error);
          msg_popUp("error", "Please enter right credential")
      }
    }
    
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      handleLogin();
    }
  };

  return (
    <>
      <div
        className="container-fluid"
        id={styles.main_contain}
        style={{ marginTop: "-5em", }}
      >
        <div className="row">
          <div className="col-md-12" style={{display:"flex", justifyContent:"flex-end"}}>
            <img
              src={equinoxLogo}
              alt="Equinox logo"
              className="img-fluid"
              id={styles.logo}
            />
          </div>
          <div
            className="col-sm-12 col-md-8"
            //  id={styles.left_part}
            //   style={{width:"auto"}}
          >
            <img
            id={styles.loginImage}
              src={recruitment}
              alt=""
              className="img-fluid"
              style={{ height: "80vh" }}
            />
          </div>

          <div className="col-sm-12 col-md-3" id={styles.right_part}>
            <div
              className="row"
              style={{ color: "grey" }}
              class={styles.logoContainer}
            >
              <h1
                className={styles.venusLogo}
                style={{ fontSize: "35px", marginTop: "6%" }}
              >
                Procure Hub
              </h1>
            </div>

            {/* <h2 style={{ color: "grey" }}>Welcome back</h2> */}
              <p style={{ fontSize: "14px" }}>Welcome back</p>
            <TextField
            
              id="standard-basic"
              label="Mobile Number"
              type="number"
               onInput={(e) => {
                 e.target.value = Math.max(0, parseInt(e.target.value))
                   .toString()
                   .slice(0, 10);
               }}
              inputProps={{ maxLength: 10 }}
              variant="standard"
              sx={{ width: "80%" }}
               onChange={(e) => setMobNumber(e.target.value)}
            />
            <TextField
              id="standard-basic"
              label="Password"
              type="password"
              variant="standard"
              sx={{ width: "80%", marginTop: "20px" }}
               onKeyUp={handlePassword}
            />
            <Button
              variant="contained"
              id={styles.login_btn}
               onClick={handleLogin}
            >
              Log In
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

