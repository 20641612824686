import { React, useState } from "react";
import MUIDataTable from "mui-datatables";
import Box from "@mui/material/Box";
import { Drawer, Modal, Tooltip, Typography } from "@mui/material";
import Swal from "sweetalert2";
import styles from "../styles/Modals.module.css";
import moment from "moment";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 850,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  zIndex: "9",
};

function AddNewTransaction({
    base_url,
    user_id,
    open,
    handleOpen,
    handleClose,
    po_id,
    POPaymentDetails,
    setPOPaymentDetails,
    paymentAmount,
    setPaymentAmount,
    paymentDate,
    setPaymentDate,
    paymentType,
    setPaymentType,
    paymentRefNo,
    setPaymentRefNo,
    paymentFile,
    setPaymentFile,
    checkPaymentFile,
    setCheckPaymentFile,
    getPurchaseOrderList,
    msg_popUp,
}) {

    const paymentTypeList = [
        "Cash",
        "Cheque",
        "RTGS",
        "NEFT",
        "Instamojo",
        "IMPS",
        "UPI",
    ] 
    const [disableSubmit, setDisableSubmit] = useState(false);

  const handleCloseModal = () => {
    handleClose();
    setPaymentAmount("");
    setPaymentDate("");
    setPaymentType("");
    setPaymentRefNo("");
    setPaymentFile({});
    setCheckPaymentFile("");
    setPOPaymentDetails([])
  };

  const onChangePaymentAmount = (e) => {

    let value = Number(e.target.value);
    if(e.target.value  == ""){
      setPaymentAmount("")
    }
    else if(value > Number(POPaymentDetails[0].TOTAL_PO_AMOUNT)){
      // setPaymentAmount("")
      msg_popUp("error", "Payment amount should be less than or equal to PO Amount!")
    }
   else if (value > Number(POPaymentDetails[0].REMAINING_PO_AMOUNT)) {
    // setPaymentAmount("")
    msg_popUp("error", "Payment amount should be less than or equal to remaining PO Amount!")
   }
   else if (value <= 0){
    // setPaymentAmount("")
    msg_popUp("error", "Please enter valid number!") 
    }
    else {
        setPaymentAmount(value)
    }
  }

  const handleOnDateChange = (date, dateStrings) => {
    if(date){
      // moment(dates.$d).format("DD-MM-YYYY")
      setPaymentDate(date)
     }
    else {
      setPaymentDate("")
    }
   
  }

    const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value)
  }

  // const handleOnchangeRefNo = (e) => {
  //   let value = e.target.value;
  //   // *************************CHEQUE******************
  //   if (paymentType == "Cheque"){
  //     if(value.length == 6){
  //       setPaymentRefNo(value)
  //     console.log("Cheque", value)
  //     }
  //     else {
  //       console.log("else", value)
  //         const Toast = Swal.mixin({
  //             toast: true,
  //             position: "top-end",
  //             showConfirmButton: false,
  //             timer: 1500,
  //             timerProgressBar: true,
  //             didOpen: (toast) => {
  //               toast.addEventListener("mouseenter", Swal.stopTimer);
  //               toast.addEventListener("mouseleave", Swal.resumeTimer);
  //             },
  //           });
  //           Toast.fire({
  //             icon: "error",
  //             title: "Please enter 6 digit cheque number!",
  //           });  
  //     }
  //     }
  //     // *************************RTGS******************
  //     if (paymentType == "RTGS" ){
  //     if( value.length == 22){
  //       setPaymentRefNo(value)
  //     }
  //     else {
  //       const Toast = Swal.mixin({
  //           toast: true,
  //           position: "top-end",
  //           showConfirmButton: false,
  //           timer: 1500,
  //           timerProgressBar: true,
  //           didOpen: (toast) => {
  //             toast.addEventListener("mouseenter", Swal.stopTimer);
  //             toast.addEventListener("mouseleave", Swal.resumeTimer);
  //           },
  //         });
  //         Toast.fire({
  //           icon: "error",
  //           title: "Please enter 22 digit RTGS number!",
  //         });  
  //   }
  //     }
  //   // *************************NEFT******************
  //   if (paymentType == "NEFT" ){
  //   if( value.length == 16){
  //     setPaymentRefNo(value)
  //   }
  //   else {
  //     const Toast = Swal.mixin({
  //         toast: true,
  //         position: "top-end",
  //         showConfirmButton: false,
  //         timer: 1500,
  //         timerProgressBar: true,
  //         didOpen: (toast) => {
  //           toast.addEventListener("mouseenter", Swal.stopTimer);
  //           toast.addEventListener("mouseleave", Swal.resumeTimer);
  //         },
  //       });
  //       Toast.fire({
  //         icon: "error",
  //         title: "Please enter 22 digit NEFT number!",
  //       });  
  // }
  //     }
  //     // *************************IMPS******************
  //   if (paymentType == "IMPS" ){
  //   if( value.length == 12){
  //     setPaymentRefNo(value)
  //   }
  //   else {
  //     const Toast = Swal.mixin({
  //         toast: true,
  //         position: "top-end",
  //         showConfirmButton: false,
  //         timer: 1500,
  //         timerProgressBar: true,
  //         didOpen: (toast) => {
  //           toast.addEventListener("mouseenter", Swal.stopTimer);
  //           toast.addEventListener("mouseleave", Swal.resumeTimer);
  //         },
  //       });
  //       Toast.fire({
  //         icon: "error",
  //         title: "Please enter 12 digit IMPS number!",
  //       });  
  // }
  //     }
  //     else {
  //       setPaymentRefNo(value)
  //     }
  // }

  const handleSubmit = () => {
    setDisableSubmit(true)
    if(
      checkPaymentFile == "" ||
      checkPaymentFile == undefined ||
        paymentAmount == "" ||
        paymentDate == "" ||
        paymentType == "" ||
        paymentRefNo == "" 
    ){
      setDisableSubmit(false)
      msg_popUp("error", "All Fields are mandatory!")
    } 
    else {
      let formattedDate = moment(paymentDate.$d).format("DD-MM-YYYY")
      const formData = new FormData();
      formData.append("USER_ID", user_id);
      formData.append("PO_ID", po_id);
      formData.append("PAYMENT_TYPE", paymentType);
      formData.append("PAYMENT_ID", paymentRefNo);
      formData.append("PAYMENT_DATE", formattedDate);
      formData.append("PAYMENT_AMOUNT", paymentAmount);
      formData.append("Files", paymentFile);

      axios({
        // url: `${base_url}/Purchase_Order.svc/add_PURCHASE_ORDER_PAYMENT`,
        url: `${base_url}/Purchase_Order.svc/add_PURCHASE_ORDER_PAYMENT`,
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res?.data?.CODE == "200") {
            msg_popUp("success", res?.data?.MESSAGE)
            setDisableSubmit(false)
            // window.location.reload();
            handleCloseModal();
            getPurchaseOrderList();
          } else {
            setDisableSubmit(false)
            msg_popUp("error", res?.data?.MESSAGE)
          }
        })
        .catch((err) => {
          setDisableSubmit(false)
          console.log(err);
        });
    }
  }

  function ValidateFile(e) {
    
    let path = e.target.value;
    let extension = path.substring(path.lastIndexOf(".") + 1, path.length).toLowerCase();
    let validFilesTypes = ["pdf", "jpg", "jpeg", "png"];
    let isValidFile = validFilesTypes.includes(extension);
    if (!isValidFile) {
      e.target.value = "";
      setCheckPaymentFile("");
      msg_popUp("error", "Invalid File. Please upload pdf or image file.")
      return false;
    } else {
        setPaymentFile(e.target.files[0])
        setCheckPaymentFile(e.target.files[0])
        return true;
    }
  }

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 style={{ fontSize: "20px" }}>Add New Payment</h5>
                <button
                  className={styles.closeButton}
                  // type="button"
                  // class="close"
                  onClick={handleCloseModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div
                // className={` col-12`}
                className="col-12"
              >
                <div className="row mt-3" style={{paddingBottom:"2vh", borderBottom:"1px solid grey"}}  >
                  <div
                    className="col-md-12 col-sm-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1vh",
                    }}
                  >
                    <label>P.O :&nbsp;</label>
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {POPaymentDetails.length > 0 ? POPaymentDetails[0].PO_NUMBER : "---"}
                    </p>
                  </div>
                  <div
                    className="col-md-6 col-sm-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1vh",
                    }}
                  >
                    <label>P.O Amount :&nbsp;</label>
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {/* {POPaymentDetails.length > 0 ? POPaymentDetails[0].PO_DATE : "---"} */}
                      ₹ {POPaymentDetails.length > 0 ?
                       new Intl.NumberFormat("en-IN").format(POPaymentDetails[0].TOTAL_PO_AMOUNT)  
                       : 
                       "---"}
                    </p>
                  </div>
                  <div
                    className="col-md-6 col-sm-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent:"flex-end",
                      marginBottom: "1vh",
                    }}
                  >
                    <label>Remaining Amount :&nbsp;</label>
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                      ₹ {POPaymentDetails.length > 0 ?
                        new Intl.NumberFormat("en-IN").format(POPaymentDetails[0].REMAINING_PO_AMOUNT )  
                       :
                        "---"}
                    </p>
                  </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-6 col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                    <label style={{ width: "13vw" }}>
                    Payment Amt
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                    id="paymentAmount"
                      style={{ fontSize: "14px" }}
                      className="form-control"
                      type="number"
                    //   maxlength="350"
                      placeholder="Payment Amt"
                      value={paymentAmount}
                    // onChange={(e) => setPaymentAmount(e.target.value)}
                      onChange={(e) => onChangePaymentAmount(e)}
                    // onFocus={(e) => onChangePaymentAmount(e)}
                      required
                    />
                  </div>
                 
                  <div className="col-md-6 col-sm-12" style={{display:"flex", alignItems: "center"}}>
                  <label style={{ width: "13vw" }}>
                  Payment Date
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Space direction="vertical">
                      <DatePicker
                      style={{
                        width: '19vw',
                      }}
                      disabledDate={(current) => current.isAfter(moment())}
                        onChange={handleOnDateChange}
                        picker="date"
                        format="DD MMM YYYY"
                        value={paymentDate}
                      />
                    </Space>
                  </div>

                  <div className="col-md-6 col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                    <label style={{ width: "13vw" }}>
                      Payment Type
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      onChange={handlePaymentTypeChange}
                      value={paymentType}
                      style={{
                        marginTop: "2vh",
                        padding: "8px",
                        fontSize: "14px",
                      }}
                    >
                      <option value="">Select</option>
                      {paymentTypeList.map((ele) => (
                        <option value={ele}>{ele}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-6 col-sm-12" 
                  style={{ 
                    display: paymentType == "" ? "none" : "flex", 
                    alignItems: "flex-end",
                   }}>
                    <input
                      style={{ fontSize: "14px" }}
                      className="form-control"
                      type="text"
                      placeholder={
                        paymentType == "Cash" ? "Reference No." :
                        paymentType == "Cheque" ? "Enter first 6 digits" :
                        paymentType == "RTGS" ? "Enter RTGS Number" :
                        paymentType == "NEFT" ? "Enter NEFT Number" :
                        paymentType == "Instamojo" ? " Enter Payment ID" :
                        paymentType == "IMPS" ? "Enter IMPS Number" :
                        paymentType == "UPI" ? "Enter UPI Number" :
                        ""
                    } 
                      value={paymentRefNo}
                      onChange={(e) => setPaymentRefNo(e.target.value)}
                      // onChange={(e) => handleOnchangeRefNo(e)}
                      required
                    />
                  </div>

                  <div class="col-md-6 col-sm-12" 
                  style={{
                    display:"flex", justifySelf:"center", alignItems:"center",
                    marginTop: paymentType == "" ? "0px" : "10px", 
                }}
                  >
                    <label style={{width:"13vw"}}>
                    Upload
                    <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                    class="form-control"
                    type="file"
                    id="billsUpload"
                    onChange={(e) => ValidateFile(e)}
                    />
                </div>

                    <div className="mt-4"
                    style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"2vw"}}
                    >
                        <button
                         className="btn btn-primary btn-sm"
                         onClick={handleSubmit}
                         disabled={disableSubmit}
                         >
                            Submit
                        </button>
                        <button 
                        className="btn btn-danger btn-sm"
                        onClick={handleCloseModal}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
              </div>

            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default AddNewTransaction;
