import React, { useEffect, useState } from "react";
import styles from "../styles/VendorRFQStyles.module.css";
import Loader from "../components/Loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import MiniDrawer from "../components/MiniDrawer";
import MUIDataTable from "mui-datatables";
import { Tooltip } from "@mui/material";
import AddBillsModal from "../Modals/AddBillsModal";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PurchaseOrderViewBills from "../Modals/PurchaseOrderViewBills";
import PurchaseOrderListTable from "../Modals/PurchaseOrderListTable";

function PurchaseOrderList({ base_url, msg_popUp }) {
  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");
  let role_id = storage.getItem("Role_id");
  let pr_id = storage.getItem("PR_ID");

  const navigate = useNavigate();

  const [POListData, setPOListData] = useState([]);
  const [poAddBillList, setPoAddBillList] = useState([]);
  const [poAddBillAttachment, setPoAddBillAttachment] = useState([]);
  const [controller, setController] = useState(null);
  const [po_id, setPo_id] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [po_price, setPo_price] = useState(false);

  // *******************ADD BILLS*******************
  const [openAddBills, setOpenAddBills] = useState(false);
  const handleOpenAddBills = () => setOpenAddBills(true);
  const handleCloseAddBills = () => setOpenAddBills(false);

// *******************VIEW BILLS*******************
  const [openViewBills, setOpenViewBills] = useState(false);
  const handleOpenViewBills = () => setOpenViewBills(true);
  const handleCloseViewBills = () => setOpenViewBills(false);

  const getPurchaseOrderList = async () => {
    setisLoading(true);
    try {
      let res = await fetch(
        `${base_url}/Purchase_Order.svc/get_PURCHASE_ORDER_LIST`
      );
      let data = await res.json();
      if (data) {
        setPOListData(data?.PURCHASE_ORDER_LIST);
        setisLoading(false);
      } else {
        setPOListData([]);
      }
    } catch (error) {
      console.log(error);
      msg_popUp("error", "Something went wrong!");
      setisLoading(false);
    }
  };

  useEffect(() => {
    getPurchaseOrderList();
  }, []);

  // const columns = [
  //   {
  //     name: "PO_ID",
  //     label: "PO ID",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       display: false,
  //     },
  //   },
  //   {
  //     name: "PO_NUMBER",
  //     label: "P.O Number",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customHeadLabelRender: () => {
  //         return (
  //           <div
  //             style={{
  //               textAlign: "left",
  //               color: "white",
  //               marginTop: "3px",
  //               marginLeft: "10px",
  //             }}
  //           >
  //             P.O Number
  //           </div>
  //         );
  //       },
  //       customBodyRender: (row, data) => {
  //         return (
  //           <>
  //             {row ? (
  //               <div
  //                 style={{
  //                   width: "100%",
  //                   marginLeft: "15px",
  //                   textAlign: "left",
  //                 }}
  //               >
  //                 {row}
  //               </div>
  //             ) : (
  //               "-"
  //             )}
  //           </>
  //         );
  //       },
  //     },
  //   },
  //   {
  //     name: "PO_DATE",
  //     label: "P.O Date",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (row, data) => {
  //         return (
  //           <>
  //             {row || row != "NaN" ? (
  //               <div>{moment(row).format("DD MMM YYYY")}</div>
  //             ) : (
  //               "-"
  //             )}
  //           </>
  //         );
  //       },
  //     },
  //   },
  //   {
  //     name: "VENDOR_NAME",
  //     label: "Vendor Name",
  //     options: {
  //       filter: false,
  //       sort: false,
  //     },
  //   },
  //   {
  //     name: "TOTAL_PO_AMOUNT",
  //     label: "P.O Price (₹)",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       display: false,
  //       customBodyRender: (row, data) => {
  //         let price = new Intl.NumberFormat("en-IN").format(row);
  //         return <>{price || price != "NaN" ? <div>{price}</div> : "-"}</>;
  //       },
  //     },
  //   },
  //   {
  //     name: "TOTAL_PAID_PO_AMOUNT",
  //     label: "P.O Price Paid (₹)",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       display: false,
  //       customBodyRender: (row, data) => {
  //         let price = new Intl.NumberFormat("en-IN").format(row);
  //         return <>{price || price != "NaN" ? <div>{price}</div> : "-"}</>;
  //       },
  //     },
  //   },
  //   {
  //     name: "TOTAL_REMAINING_PO_AMOUNT",
  //     label: "P.O Remaining Amount (₹)",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       display: false,
  //       customBodyRender: (row, data) => {
  //         let price = new Intl.NumberFormat("en-IN").format(row);
  //         return <>{price || price != "NaN" ? <div>{price}</div> : "-"}</>;
  //       },
  //     },
  //   },
  //   {
  //     name: "PO_COPY_PATH",
  //     label: "P.O Copy",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (row, data) => {
  //         return (
  //           <>
  //             {row ? (
  //               <a href={row} target="blank">
  //                 <PictureAsPdfIcon style={{ color: "red" }} />
  //               </a>
  //             ) : (
  //               "-"
  //             )}
  //           </>
  //         );
  //       },
  //     },
  //   },
  //   {
  //     name: "",
  //     label: "Add Bills",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (row, data) => {
  //         return (
  //           <div>
  //             <Tooltip title="Add Bills" placement="top">
  //               <button
  //                 className="btn btn-primary btn-sm"
  //                 // onClick={(e) => handleAddBills(data)}
  //                 onClick={(e) => handleClickAddBill(data)}
  //                 style={{
  //                   display: "flex",
  //                   alignItems: "center",
  //                   fontSize: "12px",
  //                 }}
  //               >
  //                 <AddIcon style={{ fontSize: "14px" }} /> Bill
  //               </button>
  //             </Tooltip>
  //           </div>
  //         );
  //       },
  //     },
  //   },
  //   {
  //     name: "",
  //     label: "View Bills",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (row, data) => {
  //         return (
  //           <div>
  //             <VisibilityIcon
  //               onClick={() => handleOpenView(data)}
  //               style={{ 
  //                 fill: "#007cc3", 
  //                 cursor: "pointer",
  //                 // display: data.rowData[7] > 0 ? "flex" : "none",
  //               }}
  //             />
  //           </div>
  //         );
  //       },
  //     },
  //   },
  // ];

  // const options = {
  //   filterType: "checkbox",
  // };

  const handleClickAddBill = (data) => {
    if (controller) {
      controller.abort();
    }
    handleAddBills(data);
    handleOpenAddBills();
  };

  const handleOpenView = (data) => {
    if (controller) {
      controller.abort();
    }
    handleAddBills(data);
    handleOpenViewBills()
  }

  const handleAddBills = async (data) => {
    const abortController = new AbortController();
    setController(abortController);
    const signal = abortController.signal;
    let PO_id = data.rowData[0];
    setPo_id(data.rowData[0]);
    setPo_price(data.rowData[4]);
    try {
      let res = await fetch(
        `${base_url}/Purchase_Order.svc/get_PURCHASE_ORDER_INVOICE_DETAILS/${PO_id}`,
        { signal }
      );
      let data = await res.json();
      if (data?.response?.CODE == "200") {
        setPoAddBillList(data?.PURCHASE_ORDER_INVOICE);
        setPoAddBillAttachment(data?.PURCHASE_ORDER_BILLS_ATTACHMENT);
      } else {
        handleCloseAddBills();
        msg_popUp("error", "Something went wrong! Please try again.");
      }
    } catch (error) {
      console.log(error);
      handleCloseAddBills();
      msg_popUp("error", "Something went wrong!");
    }
  };

  return (
    <>
      <MiniDrawer header_name={"Purchase Order List"} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <PurchaseOrderListTable
            POListData={POListData}
            setPOListData={setPOListData}
            handleClickAddBill={handleClickAddBill}
            handleOpenView={handleOpenView}
          />
          {/* <MUIDataTable
            title={<h4 style={{ textAlign: "left" }}>P.O List</h4>}
            data={POListData}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: false,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              tableBodyMaxHeight: "63vh",
              rowsPerPageOptions: [10, 25, 50, 100],
              setTableProps: () => {
                return {
                  padding: "default",
                };
              },
              textLabels: {
                body: {
                  noMatch: (
                    <p style={{ fontSize: "16px", margin: "5px 0px" }}>
                      Data Not Available
                    </p>
                  ),
                },
              },
            }}
          /> */}
        </div>
      )}
      <AddBillsModal
        base_url={base_url}
        user_id={user_id}
        PO_id={po_id}
        open={openAddBills}
        handleOpen={handleOpenAddBills}
        handleClose={handleCloseAddBills}
        poAddBillList={poAddBillList}
        setPoAddBillList={setPoAddBillList}
        poAddBillAttachment={poAddBillAttachment}
        setPoAddBillAttachment={setPoAddBillAttachment}
        po_price={po_price}
        getPurchaseOrderList={getPurchaseOrderList}
        msg_popUp={msg_popUp}
      />

      <PurchaseOrderViewBills 
        open={openViewBills}
        handleOpen={handleOpenViewBills}
        handleClose={handleCloseViewBills}
        poAddBillList={poAddBillList}
        poAddBillAttachment={poAddBillAttachment}
        setPoAddBillAttachment={setPoAddBillAttachment}
      />
    </>
  );
}

export default PurchaseOrderList;
