import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import styles from "../styles/VendorRFQStyles.module.css";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import equinoxLogo from "../Media/equinox_logo.png";
import notAccepting from "../Media/no.png";

function VendorRFQ({ base_url, msg_popUp }) {
  const { vendorID, PQ_ID, PR_ID, USER_ID } = useParams();

  const [rfqData, setRfqData] = useState([]);
  const [subTotalPerUnit, setSubTotalPerUnit] = useState("00");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGst, setSelectedGst] = useState("18");

  const [pq_id, setpq_id] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [overAllSubCost, setOverAllSubCost] = useState("");
  const [gstAmount, setGstAmount] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [disableInput, setDisableInput] = useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  const [vendorName, setVendorName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [sentBy, setSentBy] = useState("");
  const [sentDate, setSentDate] = useState("");
  const [isQuoteApproved, setIsQuoteApproved] = useState(false);

  const get_rfq_data = async () => {
    setIsLoading(true);
    setShowSubmitBtn(false);
    try {
      let res = await fetch(
        `${base_url}/Purchase_Request.svc/get_ALL_SEND_PURCHASE_REQUEST_QUOTATION_DETAILS_V1/${vendorID}/${PQ_ID}/${PR_ID}`
      );
      let data = await res.json();
      setRfqData(data?.data);
      if (data?.data.length == 0 && data.Is_Already_Quots_Approved > 0) {
        setIsQuoteApproved(true);      //Show Approved msg to vendor
      } else {
        setIsQuoteApproved(false);     //Show RFQ data to vendor
      }
      data?.data.forEach((ele) => {
        setVendorName(ele.VENDOR_NAME);
        setCompanyName(ele.VENDOR_COMPANY_NAME);
        setSentBy(ele.QUOTATION_SENT_BY);
        setSentDate(ele.QUOTATION_SENT_DATE);
        if (ele.IS_QUOTATION_SUBMITTED == "1") {
          setDisableInput(true);
          setShowSubmitBtn(false);
          setShowMsg(true);
        } else {
          setDisableInput(false);
          setShowSubmitBtn(false);
          setShowMsg(false);
        }
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const on_submit_get_rfq_data = async () => {
    setIsLoading(true);
    try {
      let res = await fetch(
        // `${base_url}/Purchase_Request.svc/get_ALL_SEND_PURCHASE_REQUEST_QUOTATION_DETAILS/${vendorID}/${PQ_ID}`
        `${base_url}/Purchase_Request.svc/get_ALL_SEND_PURCHASE_REQUEST_QUOTATION_DETAILS_V1/${vendorID}/${PQ_ID}/${PR_ID}`
      );
      let data = await res.json();
      setRfqData(data?.data);
      data?.data.forEach((ele) => {
        setVendorName(ele.VENDOR_NAME);
        setCompanyName(ele.VENDOR_COMPANY_NAME);
        setSentBy(ele.QUOTATION_SENT_BY);
        setSentDate(ele.QUOTATION_SENT_DATE);
      });
      setShowSubmitBtn(true);
      setShowMsg(false);
      setDisableInput(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setShowSubmitBtn(false);
    get_rfq_data();
  }, []);

  const columns = [
    {
      name: "PRODUCT_ID",
      label: "Product ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "CATEGORY_ID",
      label: "Category ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PQI_ID",
      label: "PQI ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PRIORITY_ID",
      label: "PRIORITY ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PQ_ID",
      label: "PQ ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "VENDOR_ID",
      label: "VENDOR ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: "Sr. No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <p
              style={{ marginBottom: "0px", padding: "5px" }}
              // style={{width:"3vw", textAlign:"center", marginBottom:"0px", padding:"7px", fontSize:"14px"}}
            >
              {data.rowIndex + 1}
            </p>
          );
        },
      },
    },
    {
      name: "PRODUCT_NAME",
      label: "Product Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return <p style={{ maxWidth: "40vw" }}>{row}</p>;
        },
      },
    },
    {
      name: "PACKAGING_UNIT",
      label: "Description",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return <p style={{ maxWidth: "18vw" }}>{row}</p>;
        },
      },
    },
    {
      name: "QUANTITY",
      label: "Quantity",
      options: {
        filter: false,
        sort: false,
      },
    },
    disableInput
      ? {
          name: "PER_UNIT_COST",
          label: "Price per Unit (₹)",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              let formattedAmt = new Intl.NumberFormat("en-IN").format(row);
              return (
                <>
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                      padding: "7px",
                      fontSize: "14px",
                    }}
                  >
                    {row ? formattedAmt : "0.00"}
                  </p>
                </>
              );
            },
          },
        }
      : {
          name: "",
          label: "Price per Unit (₹)",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "2px" }}
                >
                  {/* ₹ &nbsp; */}
                  <input
                    type="number"
                    onChange={(e) => handleChange(e, data)}
                    onBlur={(e) => handleOnBlur(e, data)}
                    style={{
                      width: "125px",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                    className={` form-control`}
                    id={`perUnit-${data.rowData[0]}`}
                    disabled={disableInput}
                    required
                  />
                </div>
              );
            },
          },
        },

    disableInput
      ? {
          name: "SUB_COST",
          label: "Sub Total (₹)",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data, updateValue) => {
              let formattedAmt = new Intl.NumberFormat("en-IN").format(row);
              return (
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "0px",
                    padding: "7px",
                    fontSize: "14px",
                  }}
                >
                  {row ? formattedAmt : "0.00"}
                </p>
              );
            },
          },
        }
      : {
          name: "",
          label: "Sub Total (₹)",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data, updateValue) => {
              return (
                <>
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                      padding: "7px",
                      fontSize: "14px",
                    }}
                    id={`amount-${data.rowData[0]}`}
                    // defaultValue={data.rowData[10] ? data.rowData[10] : 0}
                  >
                    {/* ₹ {subTotalPerUnit? subTotalPerUnit : "0.00"} */}
                  </p>
                </>
              );
            },
          },
        },

    disableInput
      ? {
          name: "DISCOUNT",
          label: "Discount (%)",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "2px" }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                      padding: "7px",
                      fontSize: "14px",
                    }}
                  >
                    {row ? row : "0"}
                  </p>
                </div>
              );
            },
          },
        }
      : {
          name: "",
          label: "Discount (%)",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "2px" }}
                >
                  <input
                    type="number"
                    onChange={(e) => handleChange(e, data)}
                    onBlur={(e) => handleOnBlur(e, data)}
                    style={{
                      width: "100px",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                    id={`dis-${data.rowData[0]}`}
                    className={` form-control`}
                    disabled={disableInput}
                    // defaultValue={data.rowData[12] ? data.rowData[12] : 0}
                    required
                  />
                  {/* &nbsp; % */}
                </div>
              );
            },
          },
        },

    disableInput
      ? {
          name: "TOTAL_COST",
          label: "Discounted Price (₹)",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data, updateValue) => {
              let formattedAmt = new Intl.NumberFormat("en-IN").format(row);
              return (
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "0px",
                    padding: "7px",
                    fontSize: "14px",
                  }}
                >
                  {row ? formattedAmt : "0.00"}
                </p>
              );
            },
          },
        }
      : {
          name: "",
          label: "Discounted Price (₹)",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data, updateValue) => {
              return (
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "0px",
                    padding: "7px",
                    fontSize: "14px",
                  }}
                  id={`subTotal-${data.rowData[0]}`}
                  defaultValue={subPrice}
                >
                  {/* ₹ {subTotalPerUnit? subTotalPerUnit : "0.00"} */}
                </p>
              );
            },
          },
        },
  ];

  const options = {
    filterType: "checkbox",
  };

  let subPrice = 0;
  let amount = 0;
  let dicounterPrice = 0;
  const calculationFunc = (quantity, pricePerUnit, Dis, data, gst) => {
    setpq_id(data.rowData[4]);
    Dis = Dis ? Dis : "0";
    let subTotal = Number(quantity) * Number(pricePerUnit ? pricePerUnit : "0"); //amount without discount
    let x =
      subTotal > 0
        ? (
            subTotal - (Number(Dis) > 0 ? subTotal * (Number(Dis) / 100) : 0)
          ).toFixed(2)
        : "0";
    // dicounterPrice = x;
    //  x = x.toFixed(2);
    subPrice = new Intl.NumberFormat("en-IN").format(x);
    amount = new Intl.NumberFormat("en-IN").format(subTotal);

    setSubTotalPerUnit(x.toString());
    //  document.getElementById(`subTotal-${data.rowData[0]}`).innerHTML = subPrice.toString();
    x > 0
      ? (document.getElementById(`subTotal-${data.rowData[0]}`).innerHTML =
          subPrice.toString())
      : (document.getElementById(`subTotal-${data.rowData[0]}`).innerHTML =
          "00");

    subTotal > 0
      ? (document.getElementById(`amount-${data.rowData[0]}`).innerHTML =
          amount.toString())
      : (document.getElementById(`amount-${data.rowData[0]}`).innerHTML = "00");

    //  document.getElementById(`amount-${data.rowData[0]}`).innerHTML = amount.toString();
    handleGstChange();
  };

  let changeArr = [];
  const handleOnBlur = (e, data) => {
    let Dis = document.getElementById(`dis-${data.rowData[0]}`).value;
    let gst = document.getElementById("gstPercent").value;
    let quantity = data.rowData[9] ? data.rowData[9] : "0";
    // setQuantity(quantity);
    let flag = false;
    for (let i = 0; i < rfqData.length; i++) {
      let new_val_dis = document.getElementById(
        `dis-${rfqData[i].PRODUCT_ID}`
      ).value;
      let new_val_perUnitPrice = document.getElementById(
        `perUnit-${rfqData[i].PRODUCT_ID}`
      ).value;
      if (new_val_dis == "" || new_val_perUnitPrice == "") {
        flag = true;
      }
      // calculationFunc(quantity,new_val_perUnitPrice, new_val_dis, data,gst)
    }
    if (flag == false) {
      setDisableSubmit(true);
    }
  };

  const handleChange = (e, data) => {
    let quantity = data.rowData[9] ? data.rowData[9] : "0";
    // setQuantity(quantity);
    let pricePerUnit = Number(
      document.getElementById(`perUnit-${data.rowData[0]}`).value
    );
    if (pricePerUnit < 0) {
      //********if price per unit is less than 0, show error */
      pricePerUnit = 0;
      document.getElementById(`perUnit-${data.rowData[0]}`).value = "";
      msg_popUp("error", "Please enter valid Price.");
    }
    let Dis = document.getElementById(`dis-${data.rowData[0]}`).value;
    // let gst = document.getElementById("gstPercent").value;
    // calculationFunc(quantity,pricePerUnit, Dis, data,gst)
    if (Dis < 0 || Dis > 100) {
      //********if discount is invalid */
      let gst = document.getElementById("gstPercent").value;
      document.getElementById(`dis-${data.rowData[0]}`).value = "";
      // document.getElementById(`perUnit-${data.rowData[0]}`).value = "";
      Dis = 0;
      calculationFunc(quantity, 0, 0, data, gst);
      msg_popUp("error", "Please enter valid discount percent.");
    } else {
      let gst = document.getElementById("gstPercent").value;
      calculationFunc(quantity, pricePerUnit, Dis, data, gst);
    }

    if (Dis >= 0) {
      //*************Discount validation */
      let gst = document.getElementById("gstPercent").value;
      calculationFunc(quantity, pricePerUnit, Dis, data, gst);
    } else {
      let gst = document.getElementById("gstPercent").value;
      Dis = 0;
      calculationFunc(quantity, 0, 0, data, gst);
    }
  };

  let gstAmountFormat = "00";
  const handleGstChange = () => {
    let gst = document.getElementById("gstPercent").value;
    setSelectedGst(gst);
    let overallSubTotal = 0;
    // let overallSubTotal1 = 0;
    let data = rfqData;
    for (let i = 0; i < data.length; i++) {
      let prodId = data[i].PRODUCT_ID;
      let rowsubTotal = document.getElementById(`subTotal-${prodId}`).innerText;
      // console.log(rowsubTotal.replace(/[#_,]/g,''))
      overallSubTotal =
        overallSubTotal +
        Number(rowsubTotal ? rowsubTotal.replace(/[#_,]/g, "") : 0);
      // overallSubTotal1=overallSubTotal1+Number(rowsubTotal ? rowsubTotal : '0')
    }
    let finalAmount = overallSubTotal;
    setOverAllSubCost(overallSubTotal);
    // setOverAllSubCost(overallSubTotal1)
    let withGst =
      finalAmount > 0
        ? (
            finalAmount +
            (Number(gst) > 0 ? finalAmount * (Number(gst) / 100) : 0)
          ).toFixed(2)
        : 0;
    if (withGst <= 0) {
      //******** if total is less then 0*/
      setDisableSubmit(false); //******** Disable buttton*/
    }
    let gst_price = new Intl.NumberFormat("en-IN").format(withGst);
    setFinalAmount(withGst);
    // setGstAmount(Number(finalAmount * (Number(gst) / 100)).toFixed(2));
    // gstAmountFormat = (Number(finalAmount) * (Number(gst) / 100)).toFixed(2);
    //  setGstAmount(new Intl.NumberFormat("en-IN").format((finalAmount * (Number(gst)/100)).toFixed(2)))        //convert gst amount format
    let temp = Number(finalAmount * Number(gst / 100)).toFixed(2);
    setGstAmount(temp);
    let sub_price = new Intl.NumberFormat("en-IN").format(overallSubTotal);

    document.getElementById("overallSubTotalAmount").innerHTML =
      sub_price.toString();
    document.getElementById("overallTotalAmount").innerHTML =
      gst_price.toString();
  };

  const handleSubmit = async () => {
    let indQuotationDetails = [];
    let gst_amount = gstAmount.replace(/[#_,]/g, "");
    for (let i = 0; i < rfqData.length; i++) {
      let prodId = rfqData[i].PRODUCT_ID;
      indQuotationDetails.push({
        PQI_ID: rfqData[i].PQI_ID,
        PER_UNIT_COST: document.getElementById(`perUnit-${prodId}`).value,
        TOTAL_COST: document
          .getElementById(`subTotal-${prodId}`)
          .innerText.replace(/[#_,]/g, ""),
        IS_QUOTATION_APPROVED: null,
        REMARKS: null,
        // SUB_COST: Number(document.getElementById(`perUnit-${prodId}`).value) * Number(rfqData[i].QUANTITY),
        SUB_COST: document
          .getElementById(`amount-${prodId}`)
          .innerText.replace(/[#_,]/g, ""),
        DISCOUNT: document.getElementById(`dis-${prodId}`).value,
      });
    }
    try {
      let res = await fetch(
        `${base_url}/Purchase_Quotation.svc/update_PURCHASE_QUOTATION_RATE`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            PQ_ID: pq_id,
            SUB_COST: overAllSubCost,
            FINAL_COST: finalAmount,
            GST: selectedGst,
            TOTAL_GST_AMOUNT: gst_amount,
            // TOTAL_GST_AMOUNT: gstAmount,
            VENDOR_ID: vendorID,
            IS_QUOTATION_MANUALLY_SUBMITTED:
              typeof USER_ID === "undefined" || USER_ID == null ? "0" : "1",
            QUOTATION_MANUALLY_SUBMITTED_USER_ID:
              typeof USER_ID === "undefined" || USER_ID == null
                ? null
                : USER_ID,
            Purchase_Quotation_Send: indQuotationDetails,
          }),
        }
      );
      let data = await res.json();
      if (data?.CODE == "200") {
        // setDisableInput(true);
        // window.location.reload();
        on_submit_get_rfq_data();
        msg_popUp("success", data?.MESSAGE);
      } else {
        msg_popUp("error", data?.MESSAGE);
      }
    } catch (error) {
      console.log(error);
      msg_popUp("error", "Something went wrong!");
    }
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          marginLeft: "-4em",
          marginTop: "-4em",
          marginBottom: "8vh",
          width: "auto",
          marginRight: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "3vh",
          }}
        >
          <img style={{ width: "10%" }} src={equinoxLogo} alt="" />
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {isQuoteApproved ? (
              <div style={{ position: "absolute", left: "35%", top: "30%" }}>
                <img src={notAccepting} alt="" style={{ height: "250px" }} />
                <h4>
                  Sorry, we are no longer accepting the quotation request.
                </h4>
              </div>
            ) : (
              <div className="row">
                <>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      // marginBottom:"2vh"
                    }}
                  >
                    <div
                      className=""
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <label style={{ color: "grey" }}>Vendor :</label>
                      <h6 style={{ marginBottom: "0px", fontSize: "14px" }}>
                        {companyName} ({vendorName})
                      </h6>
                    </div>
                    <div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <label style={{ color: "grey" }}>Sent On :</label>
                        <h6 style={{ marginBottom: "0px", fontSize: "14px" }}>
                          {moment(sentDate).format("DD MMM YYYY")}
                        </h6>
                        {/* <h6 style={{marginBottom:"0px"}}>{sentDate}</h6> */}
                      </div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <label style={{ color: "grey" }}>Sent By :</label>
                        <h6 style={{ marginBottom: "0px", fontSize: "14px" }}>
                          {sentBy}
                        </h6>
                      </div>
                    </div>
                  </div>
                </>

                {showSubmitBtn ? (
                  <>
                    <div style={{ marginTop: "10vh", marginBottom: "10vh" }}>
                      <h2>Response is submitted successfully!</h2>
                      <h5 style={{ color: "grey" }}>
                        We will get back to you shortly. Please review the
                        details provided below.
                      </h5>
                    </div>
                  </>
                ) : showMsg ? (
                  <>
                    <div style={{ marginTop: "10vh", marginBottom: "10vh" }}>
                      <h2>Response is already Submitted!</h2>
                      <h5 style={{ color: "grey" }}>
                        We will get back to you shortly. Please review the
                        details provided below.
                      </h5>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <>
                  <div className="col-12">
                    <MUIDataTable
                      title={
                        <h4
                          className="tableName"
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Quotation Request
                        </h4>
                      }
                      data={rfqData}
                      columns={columns}
                      options={{
                        options: options,
                        selectableRows: "none",
                        viewColumns: false,
                        filter: false,
                        print: false,
                        responsive: "standard",
                        download: false,
                        fixedHeader: true,
                        tableBodyMaxHeight: "57vh",
                        pagination: false,
                        rowsPerPageOptions: [10, 25, 50, 100],
                        setTableProps: () => {
                          return {
                            padding: "default",
                          };
                        },
                        textLabels: {
                          body: {
                            noMatch: (
                              <p
                                style={{ fontSize: "16px", margin: "5px 0px" }}
                              >
                                Data Not Available
                              </p>
                            ),
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="row" style={{ padding: "0px" }}>
                    <div className="col-md-7 col-sm-12"></div>
                    <div className="col-md-5 col-sm-12" id={styles.totalCard}>
                      <h6
                        style={{
                          paddingBottom: "15px",
                          paddingTop: "15px",
                          fontWeight: "bold",
                          borderBottom: "1px solid grey",
                          textAlign: "left",
                          fontSize: "14px",
                        }}
                      >
                        Final Estimate
                      </h6>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          // border:"1px solid red"
                        }}
                      >
                        <div
                          className="col-6"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>Subtotal:</p>
                        </div>
                        <div
                          className="col-6"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {disableInput ? (
                            // <p id="">₹ {rfqData[0].FINAL_SUB_TOTAL_COST}</p>
                            <p id="">
                              ₹{" "}
                              {rfqData[0].FINAL_SUB_TOTAL_COST
                                ? new Intl.NumberFormat("en-IN").format(
                                    rfqData[0].FINAL_SUB_TOTAL_COST
                                  )
                                : "00"}
                            </p>
                          ) : (
                            <>
                              ₹ &nbsp; <p id="overallSubTotalAmount"> 00</p>
                            </>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          alignItems: "center",
                          padding: "10px 0px",
                          // border:"1px solid red"
                        }}
                      >
                        <div
                          className="col-6"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              textAlign: "center",
                              marginBottom: "0px",
                              marginRight: "1vh",
                              width: "fit-content",
                            }}
                          >
                            GST
                          </p>
                          {disableInput ? (
                            <p>({rfqData[0].GST}%)</p>
                          ) : (
                            <select
                              className="form-select"
                              id="gstPercent"
                              style={{ width: "6vw", fontSize: "12px" }}
                              onChange={handleGstChange}
                              disabled={disableInput}
                            >
                              <option value="18">18%</option>
                              <option value="12">12%</option>
                              <option value="5">5%</option>
                              <option value="0">0%</option>
                            </select>
                          )}
                        </div>
                        <div
                          className="col-6"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {disableInput ? (
                            <p id="">
                              ₹{" "}
                              {rfqData[0].TOTAL_GST_AMOUNT
                                ? new Intl.NumberFormat("en-IN").format(
                                    rfqData[0].TOTAL_GST_AMOUNT
                                  )
                                : "00"}
                            </p>
                          ) : (
                            <p>
                              ₹{" "}
                              {new Intl.NumberFormat("en-IN").format(gstAmount)}
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          // marginLeft:"3vh"
                        }}
                      >
                        <div
                          className="col-6"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>Final Price:</p>
                        </div>
                        <div
                          className="col-6"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {disableInput ? (
                            <p id="">
                              ₹{" "}
                              {rfqData[0].FINAL_COST
                                ? new Intl.NumberFormat("en-IN").format(
                                    rfqData[0].FINAL_COST
                                  )
                                : "00"}
                            </p>
                          ) : (
                            <>
                              ₹ &nbsp; <p id="overallTotalAmount">00</p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {showSubmitBtn || showMsg ? (
                      <></>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "5vh",
                          }}
                        >
                          <button
                            className="btn btn-primary btn-sm"
                            id="addSubmitButton"
                            onClick={handleSubmit}
                            disabled={!disableSubmit}
                          >
                            Submit
                            {/* <KeyboardArrowRightIcon style={{ fontSize: "20px" }} /> */}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </>
                {/* // )} */}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default VendorRFQ;
