import { React, useState, } from "react";
import MUIDataTable from "mui-datatables";
import Box from "@mui/material/Box";
import { Drawer, Modal, Tooltip, Typography } from "@mui/material";
import Swal from "sweetalert2";
import styles from "../styles/Modals.module.css";
import Select from "react-select";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  zIndex: "9",
};

function AddNewVendors({
  base_url,
  open,
  handleOpen,
  handleClose,
  categoryList,
  stateList,
  cityList,
  setCityList,
  // selectedCategory,
  // setSelectedCategory,
  companyName,
  setCompanyName,
  contactNumber,
  setContactNumber,
  contactPerson,
  setContactPerson,
  email,
  setEmail,
  selectedState,
  setSelectedState,
  selectedCity,
  setSelectedCity,
  gstNo,
  setGstNo,
  address,
  setAddress,
  get_city_list,
  handleAddNewVendor,
  isEdit,
  setIsEdit,
  categoryId,
  setCategoryId,
  setVendorId,
  companyLabel,
  setCompanyLabel,
  vendorTitle,
  setVendorTitle,
  msg_popUp,
}) {

  const colourOptions = []
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      companyName == "" ||
      categoryId == null ||
      contactPerson == "" ||
      contactNumber == "" ||
      email == "" ||
      address == "" ||
      selectedCity == "" ||
      selectedState == "" ||
      companyLabel == "" ||
      vendorTitle == ""
    ) {
      setCategoryId(null);
      msg_popUp("error", "All fields are compulsory!")
    } else {
      handleAddNewVendor();
    }
  };

  const handleState = (e) => {
    setSelectedState(e.label);
    setSelectedCity("")
    get_city_list(e.label);
  };
  const handlechangeCity = (e) => {
    setSelectedCity(e.value);
  };

  const handleCloseModal = () => {
    setIsEdit(false);
    handleClose();
    setCompanyName("");
    setContactPerson("");
    setContactNumber("");
    setEmail("");
    setSelectedState("");
    setSelectedCity("");
    // setSelectedCategory("")
    setAddress("");
    setGstNo("");
    setCategoryId(null);
    setVendorId("");
    setCompanyLabel("");
    setCityList([]);
    setVendorTitle("")
  };

  const handleCategoryChange = (e) => {
    if (e.length == 0) {
      setCategoryId(null); 
      msg_popUp("error", "All fields are compulsory!")
    } else {
      setCategoryId(e);
    }
  };

  const handleContactNoChange = (e) => {
    let value = e.target.value;
    if (value.length > 10) {
      msg_popUp("error", "Contact number should not exceed 10 digit!")
    } else {
      setContactNumber(value);
    }
  };

  const handleGstChange = (e) => {
    let value = e.target.value;
    if (value.length > 15) {
      msg_popUp("error", "GST number should not exceed 15 digit!")
    } else {
      setGstNo(value);
    }
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '12px',
      color: 'grey',
      minWidth:"200px",
      // fontWeight: 400,
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
  };

  const handleCompanyLabelChange = (e) => {
    if (e.target.value.length > 10) {
      msg_popUp("error", "Company initial should be less than 10 character!")
    } else {
      setCompanyLabel(e.target.value);
    }
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={handleSubmit}>
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5 style={{ fontSize: "20px" }}>Add Vendors</h5>
                  <button
                    className={styles.closeButton}
                    // type="button"
                    // class="close"
                    onClick={handleCloseModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div
                  className="col-12"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2vh",
                  }}
                >
                  <div className="row">
                    <div className="col-md-5 col-sm-12">
                      <label>
                        Category<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        isMulti
                        name="label"
                        options={categoryList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        sx={{
                          color: "black",
                          maxHeight: "80px",
                          overflow: "auto",
                        }}
                        defaultValue={categoryId}
                        value={categoryId}
                        closeMenuOnSelect={false}
                        onChange={(e) => handleCategoryChange(e)}
                        maxMenuHeight="250px"
                        styles={customStyles}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <label>
                        Company Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        // style={{ fontSize:"14px" }}
                        className="form-control fs-14"
                        placeholder="Enter Company Name"
                        type="text"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>
                        Company Initial<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="form-control fs-14"
                        placeholder="Enter Company Initial"
                        type="text"
                        value={companyLabel}
                        // onChange={(e) => setCompanyLabel(e.target.value)}
                        onChange={(e) => handleCompanyLabelChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-2 col-sm-12" 
                  style={{display:"flex", flexDirection:"column", justifyContent:"flex-end"}}
                  >
                      <label>
                        Title<span style={{ color: "red" }}>*</span>
                      </label>
                     <select 
                      className="form-select" 
                      onChange={(e)=>setVendorTitle(e.target.value)}
                      value={vendorTitle}
                      style={{fontSize:"12px",padding:"6px", borderColor:"#d7cbcb", borderRadius:"6px"}}
                    >
                        <option value="">Select</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="Mrs.">Mrs.</option>
                     </select>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <label>
                        Contact Person<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        // style={{ fontSize:"14px" }}
                        className="form-control fs-14"
                        placeholder="Contact Person Name"
                        type="text"
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>
                        Contact No.<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        // style={{ fontSize:"14px" }}
                        className="form-control fs-14"
                        placeholder="Contact Number"
                        type="number"
                        value={contactNumber}
                        // onChange={(e)=> setContactNumber(e.target.value)}
                        onChange={(e) => handleContactNoChange(e)}
                        required
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>
                        Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        // style={{ fontSize:"14px" }}
                        className="form-control fs-14"
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <label>
                        State<span style={{ color: "red" }}>*</span>
                      </label>

                      {/* <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={{ value: selectedState, label: selectedState }}
                        isSearchable={true}
                        options={stateList}
                        onChange={handleState}
                        styles={customStyles}
                        maxMenuHeight="200px"
                      /> */}

                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        // value={stateList.length == 0 ? null : { value: selectedState, label: selectedState }}
                        value={selectedState == ""? null : 
                          { value: selectedState,
                             label: selectedState}}
                        isSearchable={true}
                        options={stateList}
                        onChange={handleState}
                        styles={customStyles}
                        maxMenuHeight="200px"
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <label>
                        City<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        options={cityList}
                        value={selectedCity == "" ? null : { value: selectedCity, label: selectedCity }}
                        // value={selectedCity}
                        // onChange={(e)=>setSelectedCity(e)}
                        onChange={(e) => handlechangeCity(e)}
                        styles={customStyles}
                        maxMenuHeight="200px"
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <label>GST No.</label>
                      <input
                        className="form-control fs-14"
                        placeholder="GST No."
                        type="text"
                        value={gstNo}
                        // onChange={(e)=> setGstNo(e.target.value)}
                        onChange={(e) => handleGstChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <label>
                      Vendor's Address<span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      style={{ minHeight: "70px", maxHeight: "130px" }}
                      className="form-control fs-14"
                      id="exampleFormControlTextarea1"
                      placeholder="Enter Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      className="btn btn-primary btn-sm"
                      id="addSubmitButton"
                      // style={{ width:"35vh", display:"flex", alignItems:"center", justifyContent:"center"}}
                    >
                      Submit
                      {/* <KeyboardArrowRightIcon style={{ fontSize: "20px" }} /> */}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default AddNewVendors;
