import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/MiniDrawer";
import MUIDataTable from "mui-datatables";
import AddNewCategory from "../Modals/AddNewCategory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, createChainedFunction } from "@mui/material";
import { ConstructionOutlined } from "@mui/icons-material";
import Swal from "sweetalert2";
import Loader from "../components/Loader";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function PriorityPage({ base_url, msg_popUp }) {

    const storage = window.sessionStorage;
    let user_id = storage.getItem("USER_GUID");
    let role_id = storage.getItem("Role_id");

    const [isLoading, setIsLoading] = useState(false)
    const [priorityList, setPriorityList] = useState([])

    const [open, setOpen] = useState(false);
    const handleOpenAddPriority = () => setOpen(true);
    const handleCloseAddPriority = () => setOpen(false);

    const get_priority_list = async () => {
        try{
            setIsLoading(true)
            let res = await fetch(`${base_url}/Priority.svc/get_ALL_RECORDS/ALL`);
            let data = await res.json();
            setPriorityList(data?.data);
            setIsLoading(false)
        }
        catch(error){
            console.log(error)
            setIsLoading(false)
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: "Something went wrong!",
            });
        }
    }

    useEffect(() => {
        get_priority_list();
    }, [])

    const columns = [
        {
          name: "PRIORITY_ID",
          label: "PRIORITY ID",
          options: {
            filter: false,
            sort: false,
            display: false,
          },
        },
        {
          name: "sr_no",
          label: "Sr. No.",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              return (
                <p
                  style={{
                    width: "3vw",
                    textAlign: "center",
                    marginBottom: "0px",
                    padding: "7px",
                  }}
                >
                  {data.rowIndex + 1}
                </p>
              );
            },
          },
        },
        {
          name: "PRIORITY_TITLE",
          label: "Priority Name",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "DAYS",
          label: "Days",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "",
          label: "Actions",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              return (
                <div>
                  <Tooltip title="Edit" placement="top">
                    <EditIcon onClick={()=>onClickEdit(data)} style={{color:"grey", cursor:"pointer"}} />
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <DeleteForeverIcon onClick={()=>onClickDelete(data)} style={{color:"red", cursor:"pointer"}} />
                  </Tooltip>
                </div>
              );
            },
          },
        },
    
      ];

      const options = {
        filterType: "checkbox",
      };

      const onClickEdit = (data) => {
        // console.log(data)
      }

      const onClickDelete = (data) => {
        // console.log(data)
      }
    

  return (
    <>
    <MiniDrawer />
    <div className='container-fluid'>
    {isLoading ? (
          <Loader />
        ) : (
          <div id="categoryTable">
            <MUIDataTable
              title={<h4 style={{ textAlign: "left" }}>Priority List</h4>}
              data={priorityList}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: false,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                tableBodyMaxHeight: "63vh",
                rowsPerPageOptions:[10,25,50,100],
                setTableProps: () => {
                  return {
                    padding: "default",
                  };
                },
                customToolbar: () => {
                  return (
                    <button
                      className="btn btn-outline-primary btn-sm"
                    //   onClick={handleOpenAddCategory}
                    >
                      Add Priority &nbsp;
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  );
                },
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },
              }}
            />
          </div>
        )}
    </div>
    </>
  )
}

export default PriorityPage;
