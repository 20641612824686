import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/MiniDrawer";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Chip, Tooltip,} from "@mui/material";
import AddNewVendors from "../Modals/AddNewVendors";
import Loader from "../components/Loader";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function VendorPage({ base_url, msg_popUp }) {
  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");
  let role_id = storage.getItem("Role_id");

  const [vendorList, setVendorList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  // const [selectedCategory, setSelectedCategory] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [email, setEmail] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [vendorId, setVendorId] = useState("");
  const [companyLabel, setCompanyLabel] = useState("");
  const [vendorTitle, setVendorTitle] = useState("");
  

  const [open, setOpen] = useState(false);
  const handleOpenAddVendor = () => setOpen(true);
  const handleCloseAddVendor = () => setOpen(false);

  // get vendor master all record
  const get_vendor_list = async () => {
    setIsLoading(true);
    try {
      let res = await fetch(`${base_url}/Vendor.svc/get_ALL_RECORDS/ALL`);
      let data = await res.json();
      if (data?.data) {
        setVendorList(data?.data);

      } else {
        setVendorList([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      msg_popUp("error", "Something went wrong!")
    }
  };

  //****************** get all category dropdown******************
  let categoryListData = [];
  const get_category_list = async () => {
    try {
      let res = await fetch(
        `${base_url}/Category.svc/get_ALL_RECORDS_DROPDOWN/ALL`
      );
      let data = await res.json();
      data?.data?.forEach((ele) => {
        categoryListData.push({
          value: ele.id,
          label: ele.text,
        });
      });
      setCategoryList(categoryListData);
    } catch (error) {
      console.log(error);
    }
  };

  // ******************get state list******************
  let stateListData = [];
  const get_state_list = async () => {
    try {
      let res = await fetch(
        `https://venus.equinoxlab.com/api/Candidate_Dashboard.svc/get_state`
      );
      let data = await res.json();
      data?.data?.forEach((ele) => {
        stateListData.push({
          label: ele.STATE,
          value: ele.STATE_ID,
        });
      });
      setStateList(stateListData);
    } catch (error) {
      console.log(error);
    }
  };

  // ******************get city list******************
  let cityListData = [];
  const get_city_list = async (state) => {
    try {
      let res = await fetch(
        `https://venus.equinoxlab.com/api/Candidate_Dashboard.svc/get_city/${state}`
      );
      let data = await res.json();
      data?.data?.forEach((ele) => {
        cityListData.push({
          label: ele.CITY,
          value: ele.CITY,
        });
      });
      setCityList(cityListData);
    } catch (error) {
      console.log(error);
    }
  };

  // ******************add new vendor and edit vendors******************
  const handleAddNewVendor = async () => {
    if (isEdit) {
      handleEditVendor();
    } else {
      try {
        let res = await fetch(`${base_url}/Vendor.svc/insert_Record`, {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            VENDOR_NAME: contactPerson,
            VENDOR_COMPANY_NAME: companyName,
            MOBILE: contactNumber,
            EMAIL: email,
            STATE: selectedState,
            CITY: selectedCity,
            // ZIPCODE: ,
            GST_IN: gstNo,
            ADDRESS: address,
            USER_ID: user_id,
            CATEGORY_ID: JSON.stringify(categoryId),
            VENDOR_INITIAL: companyLabel,
            TITLE: vendorTitle,
          }),
        });
        let data = await res.json();
        if (data?.response?.CODE == "200") {
          handleCloseAddVendor();
          setCompanyName("");
          setContactPerson("");
          setContactNumber("");
          setEmail("");
          setSelectedState("");
          setSelectedCity("");
          setAddress("");
          setGstNo("");
          setCategoryId(null);
          setVendorId("");
          setCompanyLabel("")
          setCityList([])
          setVendorTitle("")
          get_vendor_list();
          msg_popUp("success", data?.response?.MESSAGE)
        } else {
          msg_popUp("error", data?.response?.MESSAGE)
        }
      } catch (error) {
        console.log(error);
        msg_popUp("error", "Something Went Wrong!")
      }
    }
  };

  // ******************On edit vendors******************
  const handleEditVendor = async () => {
    try {
      let res = await fetch(`${base_url}/Vendor.svc/update_Record`, {
        method: "POST",
        headers: { "Content-Type": "Application/JSON" },
        body: JSON.stringify({
          VENDOR_NAME: contactPerson,
          VENDOR_COMPANY_NAME: companyName,
          MOBILE: contactNumber,
          EMAIL: email,
          STATE: selectedState,
          CITY: selectedCity,
          ZIPCODE: "",
          GST_IN: gstNo,
          ADDRESS: address,
          VENDOR_ID: vendorId,
          USER_ID: user_id,
          CATEGORY_ID: JSON.stringify(categoryId),
          VENDOR_INITIAL: companyLabel,
          TITLE: vendorTitle,
        }),
      });
      let data = await res.json();
      if (data.response.CODE == "200") {
        get_vendor_list();
        setCategoryId(null);
        setVendorId("");
        setCompanyName("");
        setContactPerson("");
        setContactNumber("");
        setEmail("");
        setSelectedState("");
        setSelectedCity("");
        setGstNo("");
        setAddress("");
        setCompanyLabel("");
        setCityList([]);
        setVendorTitle("");
        setIsEdit(false);
        handleCloseAddVendor();
        msg_popUp("success", data?.response?.MESSAGE)
      } else {
        msg_popUp("error", data?.response?.MESSAGE)
      }
    } catch (error) {
      console.log(error);
      msg_popUp("error", "Something went wrong!")
      setIsEdit(false);
    }
  };

  const handleDeleteVendor = async (vendor_ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let res = await fetch(
            `${base_url}/Vendor.svc/delete_RECORD/${vendor_ID}`
          );
          let data = await res.json();
          if(data.CODE == "200") {
            handleCloseAddVendor();
            Swal.fire({
              title: "Deleted!",
              text: data?.MESSAGE,
              icon: "success",
            });
            get_vendor_list();
            setCategoryId(null);
            setVendorId("");
            setCompanyName("");
            setContactPerson("");
            setContactNumber("");
            setEmail("");
            setSelectedState("");
            setSelectedCity("");
            setGstNo("");
            setAddress("");
            setCompanyLabel("");
            setCityList([]);
            setVendorTitle("");
            setIsEdit(false);
          }
          else {
            handleCloseAddVendor();
            setCategoryId(null);
            setVendorId("");
            setCompanyName("");
            setContactPerson("");
            setContactNumber("");
            setEmail("");
            setSelectedState("");
            setSelectedCity("");
            setGstNo("");
            setAddress("");
            setCompanyLabel("");
            setCityList([]);
            setVendorTitle("");
            setIsEdit(false);
            msg_popUp("error", data?.MESSAGE)
          }
        } catch (error) {
          console.log(error);
        }
      }
      else {
        setCategoryId(null);
        setVendorId("");
        setCompanyName("");
        setContactPerson("");
        setContactNumber("");
        setEmail("");
        setSelectedState("");
        setSelectedCity("");
        setGstNo("");
        setAddress("");
        setCompanyLabel("");
        setCityList([]);
        setVendorTitle("");
        setIsEdit(false);
      }
    });
  };

  useEffect(() => {
    get_vendor_list();
    get_category_list();
    get_state_list();
  }, []);

  const columns = [
    {
      name: "CATEGORY_ID",
      label: "Category ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "VENDOR_ID",
      label: "Vendor ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "GST_IN",
      label: "GST No.",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "ZIPCODE",
      label: "Zip Code",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "sr_no",
      label: "Sr. No.",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (row, data) => {
          return (
            <p
              style={{
                width: "5vw",
                textAlign: "center",
                marginBottom: "0px",
                padding: "5px",
              }}
            >
              {data.rowIndex + 1}
            </p>
          );
        },
      },
    },
    {
      name: "VENDOR_COMPANY_NAME",
      label: "Company Name",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: ()=>{
          return (
            <div
                style={{
                  textAlign:"left", color:"white", marginTop:"3px", marginLeft:"10px"}}>
                  Company Name
              </div>)
        },
        customBodyRender: (row, data) => {
          return (
            <>
              <p
                style={{
                  marginBottom: "0px",
                  textAlign: "left",
                  paddingRight:"20px",
                  minWidth:"150px",
                  // marginLeft: "10px",
                  margin:"5px 15px 5px 15px",
                }}
              >
                {row}
              </p>
            </>
          );
        },
      },
    },
    {
      name: "VENDOR_INITIAL",
      label: "Initial",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <>
              <p
                style={{
                  marginBottom: "0px",
                  textAlign: "center",
                  paddingRight:"20px",
                  minWidth:"5vw"
                }}
              >
                {row}
              </p>
            </>
          )
        }
      },
    },
    {
      name: "VENDOR_NAME",
      label: "Contact Person",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <>
              <p
                style={{
                  textAlign: "left",
                  paddingRight:"20px",
                  minWidth:"150px",
                }}
              >
                {row}
              </p>
            </>
          );
        },
      },
    },
    {
      name: "MOBILE",
      label: "Mobile No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <>
              <p
                style={{
                  margin: "0px 10px 0px 10px",
                  textAlign: "center",
                  width: "7vw",
                }}
              >
                {row}
              </p>
            </>
          );
        },
      },
    },
    {
      name: "EMAIL",
      label: "E-mail",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <>
            <p
              style={{
                textAlign: "left",
                paddingRight:"20px",
                minWidth:"150px"
              }}
            >
              {row}
            </p>
          </>
          );
        },
      },
    },
    {
      name: "STATE",
      label: "Location",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <>
            <p
              style={{
                textAlign: "center",
                paddingRight:"20px",
                width: "15vw",
              }}
            >
              {data.rowData[12]}, {data.rowData[11]}
            </p>
          </>
          );
        },
      },
    },
    {
      name: "STATE",
      label: "State",
      options: {
        filter: true,
        sort: false,
        display: false,
        customBodyRender: (row, data) => {
          return (
            <>
            <p
              style={{
                textAlign: "center",
                paddingRight:"20px",
                minWidth:"150px"
                // width: "9vw",
              }}
            >
              {row}
            </p>
          </>
          );
        },
      },
    },
    {
      name: "CITY",
      label: "City",
      options: {
        filter: true,
        sort: false,
        display: false,
        customBodyRender: (row, data) => {
          return (
            <>
              <p
              style={{
                textAlign: "center",
                paddingRight:"20px",
                minWidth:"150px",
              }}
            >
              {row}
            </p>
            </>
          );
        },
      },
    },
    {
      name: "CATEGORIES_NAME",
      label: "Category",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "300px", maxWidth: "300px" },
        }),
        customBodyRender: (row, data) => {
          if(row == ""){
            return<></>
          }
         let cat_arr = row.split("$")
          return (
            <div style={{width:"300px"}}>
              {cat_arr?.map((ele)=>(
                <Chip
                label={ele}
                color="primary"
                sx={{ marginLeft: "4px", marginTop: "5px", fontSize:"12px"}}
                variant="outlined"
              />
              ))}
            </div>
          );
        },
      },
    },

    {
      name: "",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <div
              style={{
                width: "6vw",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tooltip title="Edit" placement="top">
                <EditIcon
                  onClick={() => onClickEdit(data)}
                  className="editButton"
                />
              </Tooltip>
              <Tooltip title="Delete" placement="top">
                <DeleteForeverIcon
                  onClick={() => onClickDelete(data)}
                  className="deleteButton"
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
      name: "ADDRESS",
      label: "ADDRESS",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "TITLE",
      label: "TITLE",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  const onClickEdit = (data) => {
    let updated_cat = JSON.parse(data?.rowData[0]?.replaceAll("[]", ""));
    setCategoryId(updated_cat);
    setIsEdit(true);
    handleOpenAddVendor();
    setVendorId(data?.rowData[1]);
    setGstNo(data?.rowData[2]);
    setCompanyName(data?.rowData[5]);
    setCompanyLabel(data?.rowData[6])
    setContactPerson(data?.rowData[7]);
    setContactNumber(data?.rowData[8]);
    setEmail(data?.rowData[9]);
    setSelectedState(data?.rowData[11]);
    setSelectedCity(data?.rowData[12]);
    setAddress(data?.rowData[15]);
    setVendorTitle(data?.rowData[16])
    get_city_list(data?.rowData[11]);
  };

  const onClickDelete = (data) => {
    handleDeleteVendor(data.rowData[1]);
  };

  return (
    <>
      <MiniDrawer header_name="Vendors Master" />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div>
            <MUIDataTable
              title={
                <h4
                  className="tableName"
                  style={{ textAlign: "left", marginBottom: "0px" }}
                >
                  Vendors List
                </h4>
              }
              data={vendorList}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: true,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                tableBodyMaxHeight: "63vh",
                rowsPerPageOptions: [10, 25, 50, 100],
                setTableProps: () => {
                  return {
                    padding: "default",
                  };
                },
                customToolbar: () => {
                  return (
                    <button
                      className="btn btn-outline-primary btn-sm addButton"
                      onClick={handleOpenAddVendor}
                      >
                      <FontAwesomeIcon icon={faPlus} /> &nbsp;
                      Vendor &nbsp;
                    </button>
                  );
                },
                textLabels: {
                  body: {
                    noMatch: (
                      <p style={{ fontSize: "16px", margin: "5px 0px" }}>
                        Data Not Available
                      </p>
                    ),
                  },
                },
              }}
            />
          </div>
        </div>
      )}

      <AddNewVendors
        base_url={base_url}
        open={open}
        handleOpen={handleOpenAddVendor}
        handleClose={handleCloseAddVendor}
        categoryList={categoryList}
        stateList={stateList}
        cityList={cityList}
        setCityList={setCityList}
        // selectedCategory={selectedCategory}
        // setSelectedCategory={setSelectedCategory}
        companyName={companyName}
        setCompanyName={setCompanyName}
        contactNumber={contactNumber}
        setContactNumber={setContactNumber}
        contactPerson={contactPerson}
        setContactPerson={setContactPerson}
        email={email}
        setEmail={setEmail}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
        gstNo={gstNo}
        setGstNo={setGstNo}
        address={address}
        setAddress={setAddress}
        get_city_list={get_city_list}
        handleAddNewVendor={handleAddNewVendor}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        setVendorId={setVendorId}
        companyLabel={companyLabel}
        setCompanyLabel={setCompanyLabel}
        vendorTitle={vendorTitle}
        setVendorTitle={setVendorTitle}
        msg_popUp={msg_popUp}
      />
    </>
  );
}

export default VendorPage;
