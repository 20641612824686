import React, { useEffect, useState } from "react";
// import ReactDOMServer from 'react-dom/server';
import styles from "../styles/Modals.module.css";
import MUIDataTable from "mui-datatables";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import Box from "@mui/material/Box";
import { Drawer, Modal, Tooltip, Typography } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  overflowY: "auto",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  zIndex: "9",
};

function PurchaseOrderViewBills({
    open,
    handleOpen,
    handleClose,
    poAddBillList,
    poAddBillAttachment,
    setPoAddBillAttachment,
}) {

    let po_amount = poAddBillList[0]?.TOTAL_PO_AMOUNT ? 
    new Intl.NumberFormat("en-IN").format(poAddBillList[0]?.TOTAL_PO_AMOUNT)  : "---";

  return (
    <>
      <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 style={{ fontSize: "20px" }}>Previous Uploaded Bills</h5>
            <button
              className={styles.closeButton}
              // type="button"
              // class="close"
              onClick={handleClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div >
          <div className="row mt-3" style={{borderBottom:"1px solid grey"}}>
            <div
              className="col-md-8 col-sm-12"
              style={{ display: "flex", alignItems:"center", marginBottom: "1vh" }}
            >
              <label>P.O No. :&nbsp;</label>
              <p style={{ fontSize: "14px", fontWeight: "bold", marginLeft:"32px" }}>
                {poAddBillList.length > 0 ? poAddBillList[0].PO_NUMBER : "---"}
              </p>
            </div>
            <div
              className="col-md-4 col-sm-12"
              style={{ display: "flex",alignItems:"center", marginBottom: "1vh" }}
            >
              <label>P.O Date :&nbsp;</label>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                {poAddBillList.length > 0 ? moment(poAddBillList[0].PO_DATE).format("DD MMM YYYY") : "---"}
              </p>
            </div>
            <div
              className="col-md-8 col-sm-12"
              style={{ display: "flex", alignItems:"center", marginBottom: "1vh" }}
            >
              <label>Vendor Name :&nbsp;</label>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                {poAddBillList.length > 0
                  ? poAddBillList[0].VENDOR_COMPANY_NAME
                  : "---"}
              </p>
            </div>
            <div
              className="col-md-4 col-sm-12"
              style={{ display: "flex", alignItems:"center", marginBottom: "1vh" }}
            >
              <label>P.O Price :&nbsp;</label>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                ₹ {po_amount || po_amount != "NaN" ? po_amount : "---"}
              </p>
            </div>
          </div>

          {poAddBillAttachment.length > 0 ? (
            <>
              <div className="row mt-4">
                {poAddBillAttachment.map((ele) => (
                  <>
                    <div
                      className="col-1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <a target="blank" href={ele.BILL_ATTACHMENT_PATH}>
                        <PictureAsPdfIcon
                          style={{ color: "red", fontSize: "30px" }}
                        />
                      </a>
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : (
            <>
                <div style={{display:"flex", justifyContent:"center", margin:"20px"}}>
                    Bills Not available
                </div>
            </>
          )}
          </div>
        </Box>
      </Modal>
      </div>
    </>
  );
}

export default PurchaseOrderViewBills;
