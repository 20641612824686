import React, {useState} from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarehouse, faList, faIndustry } from '@fortawesome/free-solid-svg-icons';
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import PaymentIcon from '@mui/icons-material/Payment';
import equinoxLogo from "../Media/Screenshot 2024-05-15 155449.png";



const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen, 
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function MiniDrawer({ header_name }) {
  const storage = window.sessionStorage;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [change1, setChange1] = useState(false);
  const [change2, setChange2] = useState(false);
  const [change3, setChange3] = useState(false);
  const [change4, setChange4] = useState(false);
  const [change5, setChange5] = useState(false);
  const [change6, setChange6] = useState(false);
  const [change7, setChange7] = useState(false);
  const [change8, setChange8] = useState(false);
  const [change9, setChange9] = useState(false);
  const [change10, setChange10] = useState(false);

  let img = storage.getItem("profile_img");
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");
  let DEPT_ID = storage.getItem("DEPT_ID");
  let department = storage.getItem("DEPT");
  let l_name = storage.getItem("last_name");
  let f_name = storage.getItem("first_name");

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
    // setShow(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setShow(false);
    setShow1(false);
  };

  const handleLogout = () => {
    storage.setItem("USER_GUID", "");
    storage.setItem("profile_img", "");
    storage.setItem("Role_id", "");
    storage.setItem("DEPT_ID", "");
    // storage.setItem("DEPT", "");
    window.location.reload();
  };

  const handlePath = (e, path) => {
    if (e.ctrlKey) {
      window.open(path, "_blank");
    } else {
      navigate(path);
    }
  };

//   React.useEffect(() => {
//     if (
//       USER_GUID == "" ||
//       Role_id == "" ||
//       DEPT_ID == "" ||
//       USER_GUID == null ||
//       Role_id == null ||
//       DEPT_ID == null
//     ) {
//       navigate("/");
//     }
//   }, [storage]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            backgroundColor: "#007cc3",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{ marginTop: "1.8%", fontSize:"20px" }}
            >
              {header_name}
            </Typography>
          </Box>
          <Box
            sx={{
              // marginLeft: "70%",
              display: "flex",
              marginTop: "5px",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <img
              src={img}
              alt=""
              style={{
                height: "40px",
                width: "40px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
            <p style={{}}>{f_name} {l_name}</p>
             <Tooltip title="Sign Out" placement="top">
              <div
                style={{
                  marginLeft: "0px",
                  color: "#F0EEED",
                  backgroundColor: "#007cc3",
                  height: "30px",
                  width: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  borderRadius: "50%",
                  border:"1px solid white"
                }}
                onClick={handleLogout}
              >
                <PowerSettingsNewIcon sx={{ height: "20px" }} />
              </div>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>

                          {/* ************Drawer************ */}

<Drawer
  variant="permanent"
  open={open}
  onMouseLeave={handleDrawerClose}
  onMouseOver={handleDrawerOpen}
>
<DrawerHeader sx={{ backgroundColor: "white" }}>
          {/* <IconButton
            //  onClick={handleDrawerClose}
            sx={{ color: "#007cc3" }}
            id={styles.headerIcon}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon id={styles.headerIcon} />
            ) : (
              <ChevronLeftIcon id={styles.headerIcon} />
            )}
          </IconButton> */}
        </DrawerHeader>
        <Divider />
        <List>

          {/* ************************Priority Page************************ */}
          
          {/* <ListItem
          disablePadding
          sx={{display: "block",}}
          onClick={() => navigate("/priority")}
          >
            <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            >
              <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PriorityHighIcon style={{color:"#007cc3", fontSize:"18px"}}/>
                </ListItemIcon>
                <ListItemText
                  primary={"Priority"}
                  sx={{
                    opacity: open ? 1 : 0,
                    marginTop: "7px",
                    color: change5 ? "#007cc3" : "black",
                  }}
                  onMouseEnter={() => setChange5(true)}
                  onMouseLeave={() => setChange5(false)}
                />
            </ListItemButton>
          </ListItem> */}

          {/* ************************Category Page******************* */}
          <ListItem
          disablePadding
          // sx={{ display: "block",backgroundColor:show?"#444a4f":"#89BBE0"}}
          sx={{
            display: "block",
            // backgroundColor: show ? "white" : "#007cc3",
          }}
          onClick={() => navigate("/category")}
          // onClick={() => setShow(!show)}
          >
            <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            >
              <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faList}
                    style={{color:"#007cc3", fontSize:"18px"}}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Category"}
                  sx={{
                    opacity: open ? 1 : 0,
                    marginTop: "7px",
                    color: change1 ? "#007cc3" : "black",
                    // color: show
                    //   ? "#007cc3"
                    //   : !show && change1
                    //   ? "white"
                    //   : "white",
                  }}
                  onMouseEnter={() => setChange1(true)}
                  onMouseLeave={() => setChange1(false)}
                />
            </ListItemButton>
          </ListItem>


                  {/************************* Product page *************************/}
          <ListItem
          disablePadding
          // sx={{ display: "block",backgroundColor:show?"#444a4f":"#89BBE0"}}
          sx={{
            display: "block",
            // backgroundColor: show ? "white" : "#007cc3",
          }}
          onClick={() => navigate("/products")}
          // onClick={() => setShow(!show)}
          >
            <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            >
              <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faWarehouse} 
                    style={{color:"#007cc3", fontSize:"18px"}}
                    />
                </ListItemIcon>
                <ListItemText
                  primary={"Product"}
                  sx={{
                    opacity: open ? 1 : 0,
                    marginTop: "7px",
                    color: change2 ? "#007cc3" : "black",
                  }}
                  onMouseEnter={() => setChange2(true)}
                  onMouseLeave={() => setChange2(false)}
                />
            </ListItemButton>
          </ListItem>


            {/* ************************Vendors page************************ */}
            <ListItem
          disablePadding
          sx={{
            display: "block",
            // backgroundColor: show ? "white" : "#007cc3",
          }}
          onClick={() => navigate("/vendors")}
          >
            <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            >
              <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faIndustry}
                    style={{color:"#007cc3", fontSize:"18px"}}
                    />
                </ListItemIcon>
                <ListItemText
                  primary={"Vendors"}
                  sx={{
                    opacity: open ? 1 : 0,
                    marginTop: "7px",
                    color: change3 ? "#007cc3" : "black",
                  }}
                  onMouseEnter={() => setChange3(true)}
                  onMouseLeave={() => setChange3(false)}
                />
            </ListItemButton>
          </ListItem>

        {/************************* Purchase request************************ */}
        <ListItem
          disablePadding
          sx={{
            display: "block",
            // backgroundColor: show ? "white" : "#007cc3",
          }}
          onClick={() => navigate("/purchaseRequest")}
          >
            <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            >
              <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  
                  <RequestQuoteIcon 
                    style={{color:"#007cc3", fontSize:"18px"}}
                    />
                    
                </ListItemIcon>
                <ListItemText
                  primary={"Purchase Request"}
                  sx={{
                    opacity: open ? 1 : 0,
                    marginTop: "7px",
                    color: change4 ? "#007cc3" : "black",
                    // color: show
                    //   ? "#007cc3"
                    //   : !show && change4
                    //   ? "white"
                    //   : "white",
                  }}
                  onMouseEnter={() => setChange4(true)}
                  onMouseLeave={() => setChange4(false)}
                />
            </ListItemButton>
          </ListItem>


        {/************************* Vendor RFQ Bypass************************ */}
        {/* <ListItem
          disablePadding
          sx={{
            display: "block",
            // backgroundColor: show ? "white" : "#007cc3",
          }}
          onClick={() => navigate("/vendorRFQBypass")}
          >
            <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            >
              <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  
                  <CallSplitIcon 
                    style={{color:"#007cc3", fontSize:"18px"}}
                    />
                    
                </ListItemIcon>
                <ListItemText
                  primary={"RFQ By Pass"}
                  sx={{
                    opacity: open ? 1 : 0,
                    marginTop: "7px",
                    color: change5 ? "#007cc3" : "black",
                    // color: show
                    //   ? "#007cc3"
                    //   : !show && change4
                    //   ? "white"
                    //   : "white",
                  }}
                  onMouseEnter={() => setChange5(true)}
                  onMouseLeave={() => setChange5(false)}
                />
            </ListItemButton>
          </ListItem> */}


        {/************************* Purchase Quotation************************ */}
        <ListItem
          disablePadding
          sx={{
            display: "block",
            // backgroundColor: show ? "white" : "#007cc3",
          }}
          onClick={() => navigate("/purchaseQuotation")}
          >
            <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            >
              <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  
                  <AddBusinessIcon
                    style={{color:"#007cc3", fontSize:"18px"}}
                    />
                    
                </ListItemIcon>
                <ListItemText
                  primary={"Purchase Quotation"}
                  sx={{
                    opacity: open ? 1 : 0,
                    marginTop: "7px",
                    color: change5 ? "#007cc3" : "black",
                    // color: show
                    //   ? "#007cc3"
                    //   : !show && change4
                    //   ? "white"
                    //   : "white",
                  }}
                  onMouseEnter={() => setChange5(true)}
                  onMouseLeave={() => setChange5(false)}
                />
            </ListItemButton>
          </ListItem>

          
        {/************************* Purchase PO list************************ */}
        <ListItem
          disablePadding
          sx={{
            display: "block",
            // backgroundColor: show ? "white" : "#007cc3",
          }}
          onClick={() => navigate("/purchaseOrderList")}
          >
            <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            >
              <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  
                  <ReceiptIcon
                    style={{color:"#007cc3", fontSize:"18px"}}
                    />
                    
                </ListItemIcon>
                <ListItemText
                  primary={"Purchase Order List"}
                  sx={{
                    opacity: open ? 1 : 0,
                    marginTop: "7px",
                    color: change6 ? "#007cc3" : "black",
                  }}
                  onMouseEnter={() => setChange6(true)}
                  onMouseLeave={() => setChange6(false)}
                />
            </ListItemButton>
          </ListItem>


        {/************************* PO Payment************************ */}
          <ListItem
          disablePadding
          sx={{
            display: "block",
          }}
          onClick={() => navigate("/po_payment")}
          >
            <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            >
              <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PaymentIcon
                    style={{color:"#007cc3", fontSize:"18px"}}
                    />
                </ListItemIcon>
                <ListItemText
                  primary={"Purchase Order Payment"}
                  sx={{
                    opacity: open ? 1 : 0,
                    marginTop: "7px",
                    color: change7 ? "#007cc3" : "black",
                  }}
                  onMouseEnter={() => setChange7(true)}
                  onMouseLeave={() => setChange7(false)}
                />
            </ListItemButton>
          </ListItem>
       













        </List>
</Drawer>
    </Box>
  );
}

export default MiniDrawer;
