import React, { useEffect, useState } from "react";
import styles from "../styles/VendorRFQStyles.module.css";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import MiniDrawer from "../components/MiniDrawer";
import MUIDataTable from "mui-datatables";
import { Drawer, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddNewTransaction from "../Modals/AddNewTransaction";
import PaymentTimelineDrawer from "../components/PaymentTimelineDrawer";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import moment from "moment";

import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import addWeeks from "date-fns/addWeeks";
// import { DatePicker, Space } from 'antd';
// const { RangePicker } = DatePicker;

import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

function PO_Payment_List({
  POListData,
  isLoading,
  handleClickAddPayment,
  handleClickTimeline,
}) {
  const [filteredRows, setFilteredRows] = useState(POListData);
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());

  const handleSelectDateRange = (dateRange, dateString) => {
    if (dateRange) {
      //   const startDate = moment(dateRange[0].toISOString().split("T")[0])
      //     .add(1, "days")
      //     .format("YYYY-MM-DD");
      //   const endDate = moment(dateRange[1].toISOString().split("T")[0])
      //     .add(1, "days")
      //     .format("YYYY-MM-DD");
      const startDate = moment(dateRange[0]).format("YYYY-MM-DD");
      const endDate = moment(dateRange[1]).format("YYYY-MM-DD");
      setSelectedFromDate(dateRange[0]);
      setSelectedToDate(dateRange[1]);
      const filteredData = POListData.filter((row) => {
        const rowDate = moment(row.PO_DATE).format("YYYY-MM-DD");
        return rowDate >= startDate && rowDate <= endDate;
      });
      // setFilteredRows(filteredData);
      setFilteredRows(filteredData);
    } else {
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      setFilteredRows(POListData); // Reset if no date range is selected
    }
  };

  const predefinedRanges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
      placement: "left",
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: "left",
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
    },
    {
      label: "Last week",
      value: [
        startOfWeek(addWeeks(new Date(), -1)),
        endOfWeek(addWeeks(new Date(), -1)),
      ],
      placement: "left",
    },
    // {
    //   label: 'Last 7 days',
    //   value: [subDays(new Date(), 6), new Date()],
    //   placement: 'left'
    // },
    // {
    //   label: 'Last 30 days',
    //   value: [subDays(new Date(), 29), new Date()],
    //   placement: 'left'
    // },
    {
      label: "This month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
    },
    {
      label: "All time",
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: "left",
    },
    // {
    //   label: 'Last week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
    //     ];
    //   },
    //   appearance: 'default'
    // },
    // {
    //   label: 'Next week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
    //     ];
    //   },
    //   appearance: 'default'
    // }
  ];

  const columns = [
    {
      name: "PO_ID",
      label: "PO ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    // {
    //
    {
      name: "PO_NUMBER",
      label: "P.O Number",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: () => {
          return (
            <div
              style={{
                textAlign: "left",
                color: "white",
                marginTop: "3px",
                marginLeft: "10px",
              }}
            >
              P.O Number
            </div>
          );
        },
        customBodyRender: (row, data) => {
          return (
            <>
              {row ? (
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  {row}
                </div>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    },
    {
      name: "PO_DATE",
      label: "P.O Date",
      options: {
        filter: true,
        sort: false,
        filterType: "custom",
        customFilterListOptions: {},
        filterOptions: {
          logic: (age, filters) => {},
          display: (filterList, onChange, index, column) => (
            <div>
              <label id={styles.filter_label} htmlFor="">
                P.O Date
              </label>
              <DateRangePicker
                format="dd MMM yyyy"
                ranges={predefinedRanges}
                value={[selectedFromDate, selectedToDate]}
                onChange={handleSelectDateRange}
                style={{ width: "500px" }}
                size="md"
                placement="bottomEnd"
                preventOverflow
              />
            </div>
          ),
        },
      },
    },
    {
      name: "VENDOR_NAME",
      label: "Vendor Name",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "TOTAL_PO_AMOUNT",
      label: "P.O Price (₹)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          let price = new Intl.NumberFormat("en-IN").format(row);
          return <>{price || price != "NaN" ? <div>{price}</div> : "-"}</>;
        },
      },
    },
    {
      name: "TOTAL_PAID_PO_AMOUNT",
      label: "P.O Price Paid (₹)",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (row, data) => {
          let price = new Intl.NumberFormat("en-IN").format(row);
          return <>{price || price != "NaN" ? <div>{price}</div> : "-"}</>;
        },
      },
    },
    {
      name: "TOTAL_REMAINING_PO_AMOUNT",
      label: "P.O Remaining Amount (₹)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          let price = new Intl.NumberFormat("en-IN").format(row);
          return <>{price || price != "NaN" ? <div>{price}</div> : "-"}</>;
        },
      },
    },
    // {
    //   name: "PO_COPY_PATH",
    //   label: "P.O Copy",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     display: false,
    //     customBodyRender: (row, data) => {
    //       return (
    //         <>
    //           {
    //           row ?
    //           <a href={row} target="blank"><PictureAsPdfIcon style={{color:"red"}} /></a>
    //           :
    //           "-"
    //           }
    //         </>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "",
    //   label: "Add Bills",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     display: false,
    //     customBodyRender: (row, data) => {
    //       return (
    //         <div>
    //            <Tooltip title="Add Bills" placement="top">
    //           <AddIcon
    //             style={{fontSize:"18px", color:"#007cc3", border:"1px solid #007cc3", borderRadius:"50%"}}
    //             onClick={(e) => handleAddBills(data)}
    //           />
    //           </Tooltip>
    //         </div>
    //       );
    //     },
    //   },
    // },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <div
            // style={{display:"flex", gap:"8px"}}
            >
              <Tooltip title="Add Payments" placement="top">
                <button
                  className="btn btn-primary btn-sm"
                  // onClick={(e) => handleAddPayment(data)}
                  onClick={(e) => handleClickAddPayment(data)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                    display: Number(data?.rowData[6]) <= 0 ? "none" : "flex",
                  }}
                >
                  <AddIcon style={{ fontSize: "14px" }} /> Payment
                </button>
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Timeline",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <div
            // style={{ border:"1px solid #007cc3", borderRadius:"50%", padding:"2px"}}
            >
              <Tooltip title="Payments Timeline" placement="top">
                <TrendingUpIcon
                  style={{ fontSize: "22px", color: "#007cc3" }}
                  // onClick={(e) => handleTimeline(data)}
                  onClick={(e) => handleClickTimeline(data)}
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    // filterType: "dropdown",
    // responsive: "standard",
    // customFilterDialogFooter: () => null,
  };

  return (
    <>
      <div>
        <MUIDataTable
          title={<h4 style={{ textAlign: "left" }}>P.O Payment</h4>}
          data={filteredRows}
          columns={columns}
          options={{
            options: options,
            selectableRows: "none",
            viewColumns: false,
            filter: true,
            print: false,
            responsive: "standard",
            download: false,
            fixedHeader: true,
            tableBodyMaxHeight: "63vh",
            rowsPerPageOptions: [10, 25, 50, 100],
            setTableProps: () => {
              return {
                padding: "default",
              };
            },
            textLabels: {
              body: {
                noMatch: (
                  <p style={{ fontSize: "16px", margin: "5px 0px" }}>
                    Data Not Available
                  </p>
                ),
              },
            },
          }}
        />
      </div>
    </>
  );
}

export default PO_Payment_List;
