import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/MiniDrawer";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import ViewPurchaseRequest from "../Modals/ViewPurchaseRequest";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../components/Loader";

function PurchaseRequestPage({ base_url,msg_popUp }) {

  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");
  let role_id = storage.getItem("Role_id");

  const [purchaseData, setPurchaseData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(dayjs(new Date()));
  const [purchaseDetailData, setPurchaseDetailData] = useState([]);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [indentNo, setIndentNo] =useState("")
  const [addedOn, setAddedOn] = useState("")
  const [addedBy, setAddedBy] = useState("")
  const [vendorList, setVendorList] = useState([])
  const [vendorDataList, setVendorDataList] = useState([])
  const [selectedVendors, setSelectedVendors] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [open, setOpen] = useState(false);
  const handleOpenViewPurchase = () => setOpen(true);
  const handleCloseViewPurchase = () => setOpen(false);

  const getPurchaseRequest = async () => {
    setIsLoading(true)
    try {
      let res = await fetch(
        `${base_url}/Purchase_Request.svc/get_ALL_PURCHASE_REQUEST_APPROVED/ALL/${moment(selectedMonth.$d).format("MM/YYYY")}`
      );
      let data = await res.json();
      setPurchaseData(data.data);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  };

  let vendorListData = [];
  const getVendorList = async () => {
    try{
      let res = await fetch(`${base_url}/Vendor.svc/get_ALL_RECORDS_DROPDOWN/ALL`)
      let data = await res.json();
      data?.data.forEach((ele) => {
        vendorListData.push({
          value: ele.id,
          label: ele.text
        })
      })
      setVendorList(vendorListData);
    }
    catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    getVendorList();
  }, []);

  useEffect(() => {
    getPurchaseRequest();
  }, [selectedMonth]);

  const handleOpenDetails = async (data) => {
    setIsDetailsLoading(true)
    setIsDetailsLoading(true);
    let pr_id = data.rowData[0];
    setIndentNo(data.rowData[5])
    setAddedBy(data.rowData[2])
    setAddedOn(data.rowData[6])
    try {
      let res = await fetch(
        `${base_url}/Purchase_Request.svc/get_ALL_PURCHASE_REQUEST_INVENTORY/${pr_id}`
      );
      let data = await res.json();
    
      setPurchaseDetailData(data?.data);
      setIsDetailsLoading(false);
      handleOpenViewPurchase();
      // getVendorList(cat_id);
    } catch (error) {
      setIsDetailsLoading(false);
      console.log(error);
    }
  };

  const columns = [
    {
      name: "PR_ID",
      label: "PR ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "DEPT_ID",
      label: "Dept ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "CREATED_BY_NAME",
      label: "CREATED BY NAME",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "APPROVED_BY",
      label: "Approved By ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "APPROVED_BY_NAME",
      label: "Approved By",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "INDENT_NO",
      label: "Indent No.",
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (row) => {
        //   return <p style={{ width: "3vw" }}>{row}</p>;
        // },
      },
    },
    {
      name: "CREATED_ON",
      label: "Added On",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <p style={{ margin: "5px", marginBottom:"0px" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },
    {
      name: "CREATED_BY_NAME",
      label: "Added By",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "DEPT_NAME",
      label: "Department",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "",
      label: "Details",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <p
              style={{ 
                margin: "5px",
                display: data?.rowData[10] == "Quotation Sent" ? "none" : "flex" 
            }}
              onClick={() => handleOpenDetails(data)}
            >
              <EditIcon style={{ fill: "#007cc3", cursor: "pointer" }} />
            </p>
          );
        },
      },
    },
    {
      name: "STATUS",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  const onMonthChange = (date, dateString) => {
    if (date) {
      setSelectedMonth(date);
      
    } else {
      setSelectedMonth(dayjs(new Date()));
    }
  };

  return (
    <>
      <MiniDrawer header_name="Purchase Request" />

      <div className="container-fluid" style={{overflowY:"scroll"}}>
        {
          isLoading ? (
            <Loader />
          ) : (
            <div id="categoryTable">
          <MUIDataTable
            title={
            <h4 className="tableName"
              style={{ textAlign: "left", marginBottom: "0px" }}
              >
                Purchase Request List
            </h4>
              }
            data={purchaseData}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: false,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              rowsPerPageOptions: [10, 25, 50, 100],
              tableBodyMaxHeight: "63vh",
              setTableProps: () => {
                return {
                  padding: "default",
                };
              },
              customToolbar: () => {
                return (
                  <div style={{ display: "inline" }}>
                    <Space direction="vertical">
                      <DatePicker
                        onChange={onMonthChange}
                        picker="month"
                        format="MMM YYYY"
                        value={selectedMonth}
                      />
                    </Space>
                  </div>
                );
              },
              textLabels: {
                body: {
                  noMatch: ( 
                  <p style={{ fontSize: "14px", margin: "5px 0px" }}>
                  Data Not Available
                </p>
                  ),
                },
              },
            }}
          />
        </div>
          )
        }
        
      </div>
      <ViewPurchaseRequest
        user_id={user_id}
        base_url={base_url}
        purchaseData={purchaseData}
        purchaseDetailData={purchaseDetailData}
        open={open}
        handleOpen={handleOpenViewPurchase}
        handleClose={handleCloseViewPurchase}
        setIsDetailsLoading={setIsDetailsLoading}
        isDetailsLoading={isDetailsLoading}
        indentNo={indentNo}
        addedBy={addedBy}
        addedOn={addedOn}
        vendorList={vendorList}
        selectedVendors={selectedVendors}
        setSelectedVendors={setSelectedVendors}
        // getVendorList={getVendorList}
        vendorDataList={vendorDataList}
        setVendorDataList={setVendorDataList}
        msg_popUp={msg_popUp}
      />
    </>
  );
}

export default PurchaseRequestPage;
