import React, { useEffect, useState } from "react";
// import ReactDOMServer from 'react-dom/server';
import styles from "../styles/Modals.module.css";
import MUIDataTable from "mui-datatables";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import Box from "@mui/material/Box";
import { Drawer, Modal, Tooltip, Typography } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  overflowY: "auto",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  zIndex: "9",
};

function AddBillsModal({
  base_url,
  user_id,
  PO_id,
  open,
  handleOpen,
  handleClose,
  poAddBillList,
  setPoAddBillList,
  poAddBillAttachment,
  setPoAddBillAttachment,
  po_price,
  getPurchaseOrderList,
  msg_popUp,
}) {
  let po_amount = poAddBillList[0]?.TOTAL_PO_AMOUNT ? 
  new Intl.NumberFormat("en-IN").format(poAddBillList[0]?.TOTAL_PO_AMOUNT)  : "---";

  const [recordQty, setRecordQty] = useState("0");
  const [file, setFile] = useState({});
  const [fileCheck, setFileCheck] = useState("");
  const [po_id, setPo_id] = useState("");
  const [poi_id, setPoi_id] = useState("");
  const [perUnitCost, setPerUnitCost] = useState("");
  const [totalDeliveryQty, setTotalDeliveryQty] = useState("");
  const [inputArray, setInputArray] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);

  

  const columns = [
    {
      name: "PO_ID",
      label: "PO ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "POI_ID",
      label: "POI ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PRODUCT_ID",
      label: "Product ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "VENDOR_ID",
      label: "Vendor ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PQ_PER_UNIT_COST",
      label: "PQ PER UNIT COST",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "TOTAL_DELIVERY_QUANTITY",
      label: "TOTAL DELIVERY QUANTITY",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: "Sr. No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <p
              style={{
                // width: "3vw",
                // textAlign: "center",
                marginBottom: "0px",
                padding: "5px",
                // fontSize:"14px"
              }}
            >
              {data.rowIndex + 1}
            </p>
          );
        },
      },
    },
    {
      name: "PRODUCT_NAME",
      label: "Product Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "PACKAGING_UNIT",
      label: "Description",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "PO_QTY",
      label: "P.O Quantity",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "REMAINING_DELIVERY_QUANTITY",
      label: "Remaining Quantity",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "",
      label: "Received Quantity",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <>
              <input
                className=" form-control fs-14"
                style={{
                  padding: "0px",
                  height: "25px",
                  width: "75%",
                  textAlign: "center",
                }}
                type="number"
                id={`recd-${data.rowData[1]}`}
                placeholder="Recd Qty"
                // onChange={(e) => setRecordQty(e.target.value)}
                onChange={(e) => handleQtyChange(e, data)}
                // value={recordQty}
                disabled={data.rowData[10] == 0 ? true : false}
                required
              />
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };


  const handleQtyChange = (e, data) => {
    let remainingQty = data.rowData[10];
    setPo_id(data.rowData[0]);
    setPoi_id(data.rowData[1]);
    setPerUnitCost(data.rowData[4]);
    setTotalDeliveryQty(data.rowData[5]);

    let recdValue = document.getElementById(`recd-${data.rowData[1]}`).value;
    if (Number(recdValue) < 0) {
      setDisableSubmit(true);
      msg_popUp("error", "Received quantity can not be negative!")
      // const Toast = Swal.mixin({
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 1500,
      //   timerProgressBar: true,
      //   didOpen: (toast) => {
      //     toast.addEventListener("mouseenter", Swal.stopTimer);
      //     toast.addEventListener("mouseleave", Swal.resumeTimer);
      //   },
      // });
      // Toast.fire({
      //   icon: "error",
      //   title: "Received quantity can not be negative!",
      // });
      document.getElementById(`recd-${data.rowData[1]}`).value = "";
    }
    if (Number(remainingQty) >= Number(recdValue)) {
      setRecordQty(recdValue);
      document.getElementById(`recd-${data.rowData[1]}`).value = e.target.value;
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
      setRecordQty(0);
      document.getElementById(`recd-${data.rowData[1]}`).value = "";
      msg_popUp("error", "Received quantity should be less than remaining quantity!")
      // const Toast = Swal.mixin({
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 1500,
      //   timerProgressBar: true,
      //   didOpen: (toast) => {
      //     toast.addEventListener("mouseenter", Swal.stopTimer);
      //     toast.addEventListener("mouseleave", Swal.resumeTimer);
      //   },
      // });
      // Toast.fire({
      //   icon: "error",
      //   title: "Received quantity should be less than remaining quantity!",
      // });
    }
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);
    if(recordQty != "" && recordQty != "0"){
      let billsData = [];
      for (let i = 0; i < poAddBillList.length; i++) {
        billsData.push({
          POLD_ID: null,
          POL_ID: null, 
          POI_ID: poAddBillList[i].POI_ID,
          DELIVERY_QUANTITY: document.getElementById(`recd-${poAddBillList[i].POI_ID}`).value ? 
            document.getElementById(`recd-${poAddBillList[i].POI_ID}`).value
            : 
            "0",
          TOTAL_COST:
            Number(poAddBillList[i].PQ_PER_UNIT_COST) *
            Number(document.getElementById(`recd-${poAddBillList[i].POI_ID}`).value),
          TOTAL_DELIVERY_QUANTITY: poAddBillList[i].TOTAL_DELIVERY_QUANTITY,
          IS_PO_FOR_UPDATION : null,
        });
      }
      const formData = new FormData();
      formData.append("USER_ID", user_id);
      // formData.append("PO_ID", po_id);
      formData.append("PO_ID", PO_id);
      formData.append("UDT_POLD", JSON.stringify(billsData));
      formData.append("Files", fileCheck == "" ? fileCheck : file);
      axios({
        url: `${base_url}/Purchase_Order.svc/add_PURCHASE_ORDER_BILL_V1`,
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res?.data?.response.CODE == "200") {
            msg_popUp("success", res?.data?.response.MESSAGE)
            // const Toast = Swal.mixin({
            //   toast: true,
            //   position: "top-end",
            //   showConfirmButton: false,
            //   timer: 1500,
            //   timerProgressBar: true,
            //   didOpen: (toast) => {
            //     toast.addEventListener("mouseenter", Swal.stopTimer);
            //     toast.addEventListener("mouseleave", Swal.resumeTimer);
            //   },
            // });
            // Toast.fire({
            //   icon: "success",
            //   title: res?.data?.response.MESSAGE,
            // });
            setDisableSubmit(false);
            handleModalClose()
            getPurchaseOrderList()
            setFile({})
            setFileCheck("")
            setPo_id("")
            setRecordQty("0")
            // window.location.reload();
          } 
          else if(res?.data?.response.CODE == "409"){
            msg_popUp("success", res?.data?.response.MESSAGE)
            // const Toast = Swal.mixin({
            //   toast: true,
            //   position: "top-end",
            //   showConfirmButton: false,
            //   timer: 1500,
            //   timerProgressBar: true,
            //   didOpen: (toast) => {
            //     toast.addEventListener("mouseenter", Swal.stopTimer);
            //     toast.addEventListener("mouseleave", Swal.resumeTimer);
            //   },
            // });
            // Toast.fire({
            //   icon: "success",
            //   title: res?.data?.response.MESSAGE,
            // });
            setDisableSubmit(false);
            // window.location.reload();
            handleModalClose()
            getPurchaseOrderList()
            setFile({})
            setFileCheck("")
            setPo_id("")
            setRecordQty("0")
          }
          else {
            setDisableSubmit(false);
            msg_popUp("error", res?.data?.response.MESSAGE)
            // const Toast = Swal.mixin({
            //   toast: true,
            //   position: "top-end",
            //   showConfirmButton: false,
            //   timer: 1500,
            //   timerProgressBar: true,
            //   didOpen: (toast) => {
            //     toast.addEventListener("mouseenter", Swal.stopTimer);
            //     toast.addEventListener("mouseleave", Swal.resumeTimer);
            //   },
            // });
            // Toast.fire({
            //   icon: "error",
            //   title: res?.data?.response.MESSAGE,
            // });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else if (fileCheck != ""){
      let billsData = [];
      for (let i = 0; i < poAddBillList.length; i++) {
        // let poi_id = poAddBillList[i].POI_ID;
        billsData.push({
          POLD_ID: null,
          POL_ID: null, 
          POI_ID: poAddBillList[i].POI_ID,
          DELIVERY_QUANTITY: document.getElementById(`recd-${poAddBillList[i].POI_ID}`).value ? 
            document.getElementById(`recd-${poAddBillList[i].POI_ID}`).value
            : 
            "0",
          TOTAL_COST:
            Number(poAddBillList[i].PQ_PER_UNIT_COST) *
            Number(document.getElementById(`recd-${poAddBillList[i].POI_ID}`).value),
          TOTAL_DELIVERY_QUANTITY: poAddBillList[i].TOTAL_DELIVERY_QUANTITY,
          IS_PO_FOR_UPDATION : null,
        });
      }
      const formData = new FormData();
      formData.append("USER_ID", user_id);
      // formData.append("PO_ID", po_id);
      formData.append("PO_ID", PO_id);
      formData.append("UDT_POLD", JSON.stringify(billsData));
      formData.append("Files", fileCheck == "" ? fileCheck : file);
      axios({
        url: `${base_url}/Purchase_Order.svc/add_PURCHASE_ORDER_BILL_V1`,
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res?.data?.response.CODE == "200") {
            msg_popUp("success", res?.data?.response.MESSAGE)
            // const Toast = Swal.mixin({
            //   toast: true,
            //   position: "top-end",
            //   showConfirmButton: false,
            //   timer: 1500,
            //   timerProgressBar: true,
            //   didOpen: (toast) => {
            //     toast.addEventListener("mouseenter", Swal.stopTimer);
            //     toast.addEventListener("mouseleave", Swal.resumeTimer);
            //   },
            // });
            // Toast.fire({
            //   icon: "success",
            //   title: res?.data?.response.MESSAGE,
            // });
            setDisableSubmit(false);
            // window.location.reload();
            handleModalClose()
            getPurchaseOrderList()
            setFile({})
            setFileCheck("")
            setPo_id("")
            setRecordQty("0")
          } 
          else if(res?.data?.response.CODE == "409"){
            msg_popUp("success", res?.data?.response.MESSAGE)
            // const Toast = Swal.mixin({
            //   toast: true,
            //   position: "top-end",
            //   showConfirmButton: false,
            //   timer: 1500,
            //   timerProgressBar: true,
            //   didOpen: (toast) => {
            //     toast.addEventListener("mouseenter", Swal.stopTimer);
            //     toast.addEventListener("mouseleave", Swal.resumeTimer);
            //   },
            // });
            // Toast.fire({
            //   icon: "success",
            //   title: res?.data?.response.MESSAGE,
            // });
            setDisableSubmit(false);
            // window.location.reload();
            handleModalClose()
            getPurchaseOrderList()
            setFile({})
            setFileCheck("")
            setPo_id("")
            setRecordQty("0")
            
          }
          else {
            setDisableSubmit(false);
            msg_popUp("error", res?.data?.response.MESSAGE)
            // const Toast = Swal.mixin({
            //   toast: true,
            //   position: "top-end",
            //   showConfirmButton: false,
            //   timer: 1500,
            //   timerProgressBar: true,
            //   didOpen: (toast) => {
            //     toast.addEventListener("mouseenter", Swal.stopTimer);
            //     toast.addEventListener("mouseleave", Swal.resumeTimer);
            //   },
            // });
            // Toast.fire({
            //   icon: "error",
            //   title: res?.data?.response.MESSAGE,
            // });
          }
        })
        .catch((err) => {
          console.log(err);
          msg_popUp("error", "something went wrong!")
        });
    }
    else {
      msg_popUp("error", "Please upload file or enter quantity received.")
      // const Toast = Swal.mixin({
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 1500,
      //   timerProgressBar: true,
      //   didOpen: (toast) => {
      //     toast.addEventListener("mouseenter", Swal.stopTimer);
      //     toast.addEventListener("mouseleave", Swal.resumeTimer);
      //   },
      // });
      // Toast.fire({
      //   icon: "error",
      //   title: "Please upload file or enter quantity received.",
      // });
    }
  }

  // const handleSubmitBills = async () => {
  //   setDisableSubmit(true);
  //   if (recordQty != "" ) {
  //     if(fileCheck == "") {
  //       setDisableSubmit(true);
  //       const Toast = Swal.mixin({
  //         toast: true,
  //         position: "top-end",
  //         showConfirmButton: false,
  //         timer: 1500,
  //         timerProgressBar: true,
  //         didOpen: (toast) => {
  //           toast.addEventListener("mouseenter", Swal.stopTimer);
  //           toast.addEventListener("mouseleave", Swal.resumeTimer);
  //         },
  //       });
  //       Toast.fire({
  //         icon: "error",
  //         title: "Please upload file or enter quantity received.",
  //       });
  //     }
  //     else{
  //       let billsData = [];
  //     for (let i = 0; i < poAddBillList.length; i++) {
  //       // let poi_id = poAddBillList[i].POI_ID;
  //       billsData.push({
  //         POLD_ID: null,
  //         POL_ID: null, 
  //         POI_ID: poAddBillList[i].POI_ID,
  //         DELIVERY_QUANTITY: document.getElementById(`recd-${poAddBillList[i].POI_ID}`).value ? 
  //           document.getElementById(`recd-${poAddBillList[i].POI_ID}`).value
  //           : 
  //           "0",
  //         TOTAL_COST:
  //           Number(poAddBillList[i].PQ_PER_UNIT_COST) *
  //           Number(document.getElementById(`recd-${poAddBillList[i].POI_ID}`).value),
  //         TOTAL_DELIVERY_QUANTITY: poAddBillList[i].TOTAL_DELIVERY_QUANTITY,
  //         IS_PO_FOR_UPDATION : null,
  //       });
  //     }
  //     const formData = new FormData();
  //     formData.append("USER_ID", user_id);
  //     // formData.append("PO_ID", po_id);
  //     formData.append("PO_ID", PO_id);
  //     formData.append("UDT_POLD", JSON.stringify(billsData));
  //     formData.append("Files", fileCheck == "" ? fileCheck : file);
  //     axios({
  //       url: `${base_url}/Purchase_Order.svc/add_PURCHASE_ORDER_BILL_V1`,
  //       method: "POST",
  //       data: formData,
  //     })
  //       .then((res) => {
  //         if (res?.data?.response.CODE == "200") {
  //           const Toast = Swal.mixin({
  //             toast: true,
  //             position: "top-end",
  //             showConfirmButton: false,
  //             timer: 1500,
  //             timerProgressBar: true,
  //             didOpen: (toast) => {
  //               toast.addEventListener("mouseenter", Swal.stopTimer);
  //               toast.addEventListener("mouseleave", Swal.resumeTimer);
  //             },
  //           });
  //           Toast.fire({
  //             icon: "success",
  //             title: res?.data?.response.MESSAGE,
  //           });
  //           setDisableSubmit(false);
  //           // window.location.reload();
  //         } 
  //         else if(res?.data?.response.CODE == "409"){
  //           const Toast = Swal.mixin({
  //             toast: true,
  //             position: "top-end",
  //             showConfirmButton: false,
  //             timer: 1500,
  //             timerProgressBar: true,
  //             didOpen: (toast) => {
  //               toast.addEventListener("mouseenter", Swal.stopTimer);
                
  //               toast.addEventListener("mouseleave", Swal.resumeTimer);
  //             },
  //           });
  //           Toast.fire({
  //             icon: "warning",
  //             title: res?.data?.response.MESSAGE,
  //           });
  //           setDisableSubmit(false);
  //           // window.location.reload();
  //         }
  //         else {
  //           setDisableSubmit(false);
  //           const Toast = Swal.mixin({
  //             toast: true,
  //             position: "top-end",
  //             showConfirmButton: false,
  //             timer: 1500,
  //             timerProgressBar: true,
  //             didOpen: (toast) => {
  //               toast.addEventListener("mouseenter", Swal.stopTimer);
  //               toast.addEventListener("mouseleave", Swal.resumeTimer);
  //             },
  //           });
  //           Toast.fire({
  //             icon: "error",
  //             title: res?.data?.response.MESSAGE,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     }
  //   } 
  //   else {
  //     setDisableSubmit(false);
  //     const Toast = Swal.mixin({
  //       toast: true,
  //       position: "top-end",
  //       showConfirmButton: false,
  //       timer: 1500,
  //       timerProgressBar: true,
  //       didOpen: (toast) => {
  //         toast.addEventListener("mouseenter", Swal.stopTimer);
  //         toast.addEventListener("mouseleave", Swal.resumeTimer);
  //       },
  //     });
  //     Toast.fire({
  //       icon: "error",
  //       title: "Please enter received quantity!",
  //     });
  //   }
  // };


  function ValidateFile(e) {
    // var path = document.getElementById("billsUpload").value;
    let path = e.target.value;
    let extension = path.substring(path.lastIndexOf(".") + 1, path.length).toLowerCase();
    let validFilesTypes = ["pdf"];
    let isValidFile = validFilesTypes.includes(extension);
    if (!isValidFile) {
      e.target.value = "";
      // setFile({});
      setFileCheck("");
      setDisableSubmit(true)
      msg_popUp("error", "Invalid File. Please upload pdf file only.")
      // const Toast = Swal.mixin({
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 1500,
      //   timerProgressBar: true,
      //   didOpen: (toast) => {
      //     toast.addEventListener("mouseenter", Swal.stopTimer);
      //     toast.addEventListener("mouseleave", Swal.resumeTimer);
      //   },
      // });
      // Toast.fire({
      //   icon: "error",
      //   title: "Invalid File. Please upload pdf file only.",
      // });
      return false;
    } else {
      setFile(e.target.files[0]);
      setFileCheck(e.target.files[0]);
      setDisableSubmit(false)
      return true;
    }
  }

  const handleModalClose = () => {
    handleClose();
    setInputArray([]);
    setFile({})
    setFileCheck("")
    setPo_id("")
    setDisableSubmit(false);
    setPoAddBillAttachment([])
    setPoAddBillList([])
  };

  return (
    <>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 style={{ fontSize: "20px" }}>Add Bills</h5>
            <button
              className={styles.closeButton}
              // type="button"
              // class="close"
              onClick={handleModalClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div >
          <div className="row mt-3">
            <div
              className="col-md-8 col-sm-12"
              style={{ display: "flex", alignItems:"center", marginBottom: "1vh" }}
            >
              <label>P.O No. :&nbsp;</label>
              <p style={{ fontSize: "14px", fontWeight: "bold", marginLeft:"32px" }}>
                {poAddBillList.length > 0 ? poAddBillList[0].PO_NUMBER : "---"}
              </p>
            </div>
            <div
              className="col-md-4 col-sm-12"
              style={{ display: "flex",alignItems:"center", marginBottom: "1vh" }}
            >
              <label>P.O Date :&nbsp;</label>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                {poAddBillList.length > 0 ? moment(poAddBillList[0].PO_DATE).format("DD MMM YYYY") : "---"}
              </p>
            </div>
            <div
              className="col-md-8 col-sm-12"
              style={{ display: "flex", alignItems:"center", marginBottom: "1vh" }}
            >
              <label>Vendor Name :&nbsp;</label>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                {poAddBillList.length > 0
                  ? poAddBillList[0].VENDOR_COMPANY_NAME
                  : "---"}
              </p>
            </div>
            <div
              className="col-md-4 col-sm-12"
              style={{ display: "flex", alignItems:"center", marginBottom: "1vh" }}
            >
              <label>P.O Price :&nbsp;</label>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                ₹ {po_amount || po_amount != "NaN" ? po_amount : "---"}
                {/* {
                      poAddBillList.length>0 && poAddBillList[0].PO_PRICE != "" ? 
                      poAddBillList[0].PO_PRICE : 
                      po_price ? 
                      po_price :
                        "---"
                        } */}
              </p>
            </div>
          </div>
          <div>
            <MUIDataTable
              data={poAddBillList}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: false,
                search: false,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                tableBodyMaxHeight: "35vh",
                rowsPerPageOptions: [10, 25, 50, 100],
                setTableProps: () => {
                  return {
                    padding: "default",
                  };
                },
                textLabels: {
                  body: {
                    noMatch: (
                      <p style={{ fontSize: "16px", margin: "5px 0px" }}>
                        Data Not Available
                      </p>
                    ),
                  },
                },
              }}
            />
          </div>
          <div class="mt-5 col-12">
            <label for="formFile" class="form-label">
              Upload Bill &nbsp;
              <span style={{ color: "red" }}>(pdf only)</span>
            </label>
            <input
              class="form-control"
              type="file"
              id="billsUpload"
              accept=".pdf"
              //  value={file}
              onChange={(e) => ValidateFile(e)}
            />
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              marginTop: "2vh",
            }}
          >
            <button
              className="btn btn-primary btn-sm"
              // onClick={handleSubmitBills}
              onClick={handleSubmit}
              disabled={disableSubmit}
            >
              Submit
            </button>
          </div>

          {/* {poAddBillAttachment.length > 0 ? (
            <>
              <div className="row mt-4">
                <div className="" style={{ margin: "3vh 3vh 3vh 0vh" }}>
                  <h6 style={{ fontSize: "18px", fontWeight: "600" }}>
                    Previously Uploaded Bills
                  </h6>
                </div>
                {poAddBillAttachment.map((ele) => (
                  <>
                    <div
                      className="col-1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <a target="blank" href={ele.BILL_ATTACHMENT_PATH}>
                        <PictureAsPdfIcon
                          style={{ color: "red", fontSize: "30px" }}
                        />
                      </a>
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : (
            <></>
          )} */}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AddBillsModal;
