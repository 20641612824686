import React, { useState } from "react";
import styles from "../styles/Modals.module.css";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import Box from "@mui/material/Box";
import { Drawer, Modal, Tooltip, Typography } from "@mui/material";
import Swal from "sweetalert2";
import Select from "react-select";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  zIndex: "9",
};

function VendorRFQByPass({
  base_url,
  user_id,
  open,
  handleOpen,
  handleClose,
  indentNoList,
  indentDropdownData,
  setIndentDropdownData,
  msg_popUp,
}) {
  const navigate = useNavigate();

  const [selectedIndent, setSelectedIndent] = useState("");
  const [selectedIndentValue, setSelectedIndentValue] = useState("");
  const [vendorDropdownData, setVendorDropdownData] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedVendorLabel, setSelectedVendorLabel] = useState("");
  const [desc, setDesc] = useState("");
  const [selected_pr_id, setSelected_pr_id] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const handleIndentChange = (e) => {
    setIsDisabled(true);
    setSelectedVendor("");
    setSelectedVendorLabel("");
    setDesc("");
    setSelectedIndent(e.label); //indent No
    setSelectedIndentValue(e.value); //PR_ID
    setSelected_pr_id(e.value)
    onChangeVendor(e.value);
  };
  const clearData = () => {
    setIsDisabled(true);
    setSelectedVendor("");
    setSelectedVendorLabel("");
    setDesc("");
    setSelected_pr_id("")
    setSelectedIndent(""); //indent No
    setSelectedIndentValue("");
  };

  const handleVendorChange = (e) => {
    setSelectedVendor(e.value); //vendor id
    setSelectedVendorLabel(e.label); //company name
    setDesc(e.desc);
    setIsDisabled(false);
  };

  const onChangeVendor = async (value) => {
    try {
      let res = await fetch(
        `${base_url}/Purchase_Request.svc/get_ALL_VENDOR_LIST_VENDOR_RFQ_BYPASS/${value}`
      );
      let data = await res.json();
      if (data.response.CODE == "200") {
        let temp_arr = [];
        data?.data?.forEach((ele) => {
          temp_arr.push({
            label: ele.VENDOR_COMPANY_NAME,
            value: ele.VENDOR_ID,
            desc: ele.PQ_ID,
          });
        });
        setVendorDropdownData(temp_arr);
        setIsDisabled(true);
      } else {
        msg_popUp("error", data.response.MESSAGE)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    if (selectedIndentValue == "" || selectedVendor == "" || selected_pr_id == "") {
      setIsDisabled(false);
      msg_popUp("error", "Something went wrong!")
    } else {
      // navigate(`/vendorRFQ/${selectedVendor}/${selectedIndentValue}`, {target: '_blank'})
      // window.open(`/vendorRFQ/${selectedVendor}/${selectedIndentValue}/${user_id}`, '_blank', 'noopener,noreferrer');
      window.open(
        `/vendorRFQ/${selectedVendor}/${desc}/${selected_pr_id}/${user_id}`,
        "_blank",
        "noopener,noreferrer"
      );
      handleCloseAddVendor();
    }
  };

  const handleCloseAddVendor = () => {
    handleClose();
    setSelectedVendor("");
    setSelectedVendorLabel("");
    setSelectedIndentValue("");
    setSelectedIndent("");
    setSelected_pr_id("")
    setVendorDropdownData([]);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseAddVendor}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 style={{ fontSize: "20px" }}>Add Vendor RFQ</h5>
            <button
              className={styles.closeButton}
              // type="button"
              // class="close"
              onClick={handleCloseAddVendor}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-md-5 col-sm-12">
              <label>
                Indent <span style={{ color: "red" }}>*</span>
              </label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selectedIndentValue == "" ? null :{ value: selectedIndentValue, label: selectedIndent }}
                isSearchable={true}
                isClearable={clearData}
                name="color"
                options={indentDropdownData}
                onChange={handleIndentChange}
                placeholder={<div>Select...</div>}
                styles={customStyles}
                maxMenuHeight="200px"
              />
            </div>
            <div className="col-md-5 col-sm-12">
              <label>
                Vendor <span style={{ color: "red" }}>*</span>
              </label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selectedVendor == "" ? null : {
                  value: selectedVendor,
                  label: selectedVendorLabel,
                  desc: desc,
                }}
                isSearchable={true}
                isClearable={clearData}
                name="color"
                options={vendorDropdownData}
                onChange={handleVendorChange}
                styles={customStyles}
                maxMenuHeight="200px"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "3vh",
              }}
            >
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSubmit}
                disabled={isDisabled}
              >
                Submit 
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default VendorRFQByPass;
