import { React, useState } from "react";
import MUIDataTable from "mui-datatables";
import Box from "@mui/material/Box";
import { Drawer, Modal, Tooltip, Typography } from "@mui/material";
import Swal from "sweetalert2";
import styles from "../styles/Modals.module.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  zIndex: "9",
};

function AddNewProducts({
  base_url,
  open,
  setOpen,
  handleOpen,
  handleClose,
  productName,
  setProductName,
  quantity,
  setQuantity,
  selectCategoryId,
  setSelectCategoryId,
  categoryList,
  unitList,
  selectedUnit,
  setSelectedUnit,
  handleAddProduct,
  productId,
  setProductId,
  categoryId,
  setCategoryId,
  quantityunitId,
  setQuantityUnitId,
  isEdit,
  setIsEdit,
  quantityTitle,
  msg_popUp,
}) {

  const handleUnitChange = (e) => {
    // setSelectedUnit(e.target.value);
    setQuantityUnitId(e.target.value);
  };

  const handleCategoryChange = (e) => {
    // setSelectCategoryId(e.target.value);
    setCategoryId(e.target.value);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    // if(productName == "" || selectCategoryId == "" || selectedUnit == ""){
    if (productName == "" || categoryId == "" || quantityunitId == "") {
      msg_popUp("error", "All the fields are mandatory!")
      // const Toast = Swal.mixin({
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 1500,
      //   timerProgressBar: true,
      //   didOpen: (toast) => {
      //     toast.addEventListener("mouseenter", Swal.stopTimer);
      //     toast.addEventListener("mouseleave", Swal.resumeTimer);
      //   },
      // });
      // Toast.fire({
      //   icon: "error",
      //   title: "All the fields are mandatory!",
      // });
    } else {
      handleAddProduct();
    }
  };

  const handleCloseModal = () => {
    setIsEdit(false);
    handleClose();
    setProductId("");
    setCategoryId("");
    setQuantityUnitId("");
    setProductName("");
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={handlesubmit}>
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5 style={{ fontSize: "20px" }}>Add Product</h5>
                  <button
                    className={styles.closeButton}
                    // type="button"
                    // class="close"
                    onClick={handleCloseModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div
                  className={`${styles.modalBody} col-10`}
                  style={{ margin: "5vh auto 5vh auto" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label style={{ width: "13vw" }}>
                      Product Name
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      style={{ fontSize: "14px" }}
                      className="form-control"
                      type="text"
                      maxlength="350"
                      placeholder="Product Name"
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                      required
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label style={{ width: "13vw" }}>
                      Quantity
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      onChange={handleUnitChange}
                      name=""
                      id=""
                      class="form-select"
                      value={quantityunitId}
                      style={{
                        marginTop: "2vh",
                        padding: "8px",
                        fontSize: "12px",
                      }}
                    >
                      <option value="">Select</option>
                      {unitList.map((ele) => (
                        <option value={ele.id}>{ele.text}</option>
                      ))}
                    </select>

                    {/* <select name="" id="" class="form-select"
                  onChange={handlePriority} 
                  value={priorityId}
                  style={{marginTop:"2vh", padding:"8px"}}
                  >
                    <option value="">Select Priority</option>
                    {
                      priorityList.map((ele)=>(
                        <option value={ele.id}>{ele.text}</option>
                      ))
                    }
                  </select> */}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label style={{ width: "13vw" }}>
                      Category
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      onChange={handleCategoryChange}
                      value={categoryId}
                      style={{
                        marginTop: "2vh",
                        padding: "8px",
                        fontSize: "12px",
                      }}
                    >
                      <option value="">Select</option>
                      {categoryList.map((ele) => (
                        <option value={ele.id}>{ele.text}</option>
                      ))}
                    </select>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      className="btn btn-primary btn-sm"
                      id="addSubmitButton" >
                      Submit
                      {/* <KeyboardArrowRightIcon style={{ fontSize: "20px" }} /> */}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default AddNewProducts;
