import React, { useEffect, useState,useRef } from "react";
import ReactDOMServer from 'react-dom/server';
import styles from "../styles/Modals.module.css"
import domtoimage from 'dom-to-image';


// const reactString = Test().toString();
// // console.log(reactString)
// // const htmlString = ReactDOMServer.renderToString(React.createElement('div', { dangerouslySetInnerHTML: { __html: Test() } }));
// const htmlString = ReactDOMServer.renderToString(<Test />);
// console.log(htmlString)

function Test() {
    function filter (node) {
        return (node.tagName !== 'i');
    }

    const elementRef = useRef(null);
     
     const get_image = () => {
      var node = document.getElementById('my-node');
      // domtoimage.toPng(node)
      // .then(function (dataUrl) {
      //     var img = new Image();
      //     img.src = dataUrl;
      //     document.body.appendChild(img);
      // })
      // .catch(function (error) {
      //     console.error('oops, something went wrong!', error);
      // });
let dataSVG = ""
        domtoimage.toSvg(document.getElementById('my-node'), {filter: filter})
        .then(function (dataUrl) {
            // console.log(dataUrl)
        //     dataSVG = dataUrl.replace("data:image/svg+xml;charset=utf-8,", "")
        // console.log(dataSVG)
        }); 
     }
    
    //  useEffect(() => {
    //     get_image();
    //   }, []);

    // get_image();
    const handleClick = () => {
      get_image()
    }
  return (
    <>
      <div className="container-fluid">
        <div ref={elementRef} id="my-node"></div>
        <button onClick={handleClick}>click</button>
      </div>
    </>
  );
}

export default Test;
