import { React, useState } from "react";
import MUIDataTable from "mui-datatables";
import Box from "@mui/material/Box";
import { Drawer, Modal, Tooltip, Typography } from "@mui/material";
import Swal from "sweetalert2";
import styles from "../styles/Modals.module.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  zIndex: "9",
};

function AddNewCategory({
  base_url,
  open,
  setOpen,
  handleOpenAddCategory,
  handleCloseAddCategory,
  category,
  setCategory,
  setCategoryId,
  priorityList,
  priorityId,
  setPriorityId,
  handleAddCategory,
  isEdit,
  setisEdit,
  // isDelete,
  handleEditCategory,
  selectedPriorityId,
  setSelectedPriorityId,
  msg_popUp,
}) {
  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");
  let role_id = storage.getItem("Role_id");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      category == ""  
      // selectedPriorityId == null
    ) {
      setSelectedPriorityId(null)
      msg_popUp("error", "Please enter Category!")
    } else {
      if (isEdit) {
        handleEditCategory();
      } else {
        setCategory("")
        handleAddCategory();
      }
    }
  };

  // const onChangePriority = (e) => {
  //   setSelectedPriorityId(e)
  // };

  const handleClose = () => { 
    handleCloseAddCategory();
    setisEdit(false);
    setCategory("");
    setCategoryId("");
    setSelectedPriorityId(null)
    setPriorityId("");
  };

  // *************Custom style of multiselect*************
  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
      marginTop:"2vh",
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "14px",
      marginTop:"2vh",
    }),
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={handleSubmit}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 style={{ fontSize: "20px" }}>Add Category</h5>
                <button
                  className={styles.closeButton}
                  // type="button"
                  // class="close"
                  onClick={handleClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="row">
                <div
                  className="col-md-9 col-sm-12"
                  style={{ margin: "5vh 0vh 5vh 0vh", margin:"auto", paddingTop:"2vh" }}
                >
                  <label>
                    Category
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    style={{ fontSize: "14px", marginBottom:"5vh" }}
                    className="form-control"
                    type="text"
                    value={category}
                    placeholder="Category Name"
                    onChange={(e) => setCategory(e.target.value)}
                    required
                  />
                </div>
                {/* <div
                  className="col-md-6 col-sm-12"
                  style={{ margin: "5vh 0vh 5vh 0vh" }}
                >
                  <label>
                    Priority
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    isMulti
                    name="label"
                    options={priorityList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    sx={{
                      color: "black",
                      maxHeight: "80px",
                      overflow: "auto",
                      
                    }}
                    defaultValue={selectedPriorityId}
                    value={selectedPriorityId}
                    closeMenuOnSelect={false}
                    // onChange={(e) => setSelectedPriorityId(e)}
                    onChange={(e) => onChangePriority(e)}
                    maxMenuHeight="250px"
                    styles={customStyles}
                  />
                </div> */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="btn btn-primary btn-sm"
                    id="addSubmitButton"
                    // style={{ width:"35vh", display:"flex", alignItems:"center", justifyContent:"center"}}
                  >
                    Submit
                    {/* <KeyboardArrowRightIcon style={{ fontSize: "20px" }} /> */}
                  </button>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default AddNewCategory;
