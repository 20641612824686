import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Drawer, Modal, Tooltip, Typography } from "@mui/material";
import Swal from "sweetalert2";
import styles from "../styles/Modals.module.css";
import Loader from "../components/Loader";
import MUIDataTable from "mui-datatables";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  zIndex: "9",
};

function ProductPQList({
  base_url,
  user_id,
  open,
  handleOpen,
  handleClose,
  productData,
  isDetailsLoading,
  productId,
  isApproved,
  setIsApproved,
  productName,
  get_product_list,
  msg_popUp,
  isDisplayEdit,
  setIsDisplayEdit,
}) {
  const [isRejected, setisRejected] = useState("");
  const [isEdit, setisEdit] = useState(false);
  const [editingPQI_Id, setEditingPQI_Id] = useState("");
  const [editedPricePerUnit, setEditedPricePerUnit] = useState("");
  const [editedDiscount, setEditedDiscount] = useState("");
  const [editedSubTotal, setEditedSubTotal] = useState("");
  const [editedFinalAmount, setEditedFinalAmount] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [onSelectApproveFlag, setOnSelectApproveFlag] = useState(false);
  const [onSelectRejectFlag, setOnSelectRejectFlag] = useState(false);
  
  const [pr_id, setPr_id] = useState("");
  const [product_id, setProduct_id] = useState("");

  let updatedArray = [];
  productData.forEach((ele) => {
    if(ele.IS_QUOTATION_APPROVED != "0"){
      updatedArray.push({
        PQI_ID: ele.PQI_ID,
        IS_QUOTATION_APPROVED: "",
        REMARKS: null,
      });
    }
  });

  const columns = [
    {
      name: "PR_ID",
      label: "PR ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PRODUCT_ID",
      label: "Product ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PQI_ID",
      label: "PQI ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "VENDOR_ID",
      label: "Vendor ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "IS_QUOTATION_APPROVED",
      label: "IS_QUOTATION_APPROVED",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "VENDOR_NAME",
      label: "Vendors",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ maxWidth: "20vw", marginBottom: "0px" }}>{row}</p>;
        },
      },
    },
    {
      name: "QUANTITY",
      label: "Quantity",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "PER_UNIT_COST",
      label: "Price Per Unit (₹)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          let price = new Intl.NumberFormat("en-IN").format(row);
          return (
            <div className="" style={{ display:"flex", justifyContent:"center"}}>
             {
              isEdit && data.rowData[2] == editingPQI_Id ?
              <input
                type="number"
                className="form-control"
                style={{width:"70%", textAlign:"center"}}
                id={`pricePerUnit-${data.rowData[2]}`}
                value = {editedPricePerUnit}
                onChange={(e) => handleChangePricePerUnit(e, data)}
              />
              :
              <p id={`pricePerUnit-${data.rowData[2]}`}>{price}</p>
             }
             {/* <p id={`pricePerUnit-${data.rowData[2]}`}>{price}</p> */}
            </div>
          );
        },
      },
    },
    

    isEdit ?
    {
      name: "SUB_COST",
      label: "Sub Total (₹)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data, updateValue) => {
          let price = new Intl.NumberFormat("en-IN").format(row);
          return (
            <p
              style={{
                textAlign: "center",
                marginBottom: "0px",
                padding: "7px",
                fontSize: "14px",
              }}
              id={`subTotal-${data.rowData[2]}`}
              // defaultValue={subPrice}
            >
              {row == "" ? "0" : price}
            </p>
          );
        },
      },
    }
    :
    {
      name: "SUB_COST",
      label: "Sub Total (₹)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          // let price = new Intl.NumberFormat("en-IN").format(row);
          if (row == "" || row == null) {
            return( 
            <>
                <p id={`subTotal-${data.rowData[2]}`}>0</p>
            </>
            );
          }
          return (
            <div className="" style={{}}>
              <p id={`subTotal-${data.rowData[2]}`}>
              {new Intl.NumberFormat("en-IN").format(row)}
              </p>
            </div>
          );
        },
      },
    },
    {
      name: "DISCOUNT",
      label: "Discount (%)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <div className="" style={{ display:"flex", justifyContent:"center"}}>
             {
              isEdit && data.rowData[2] == editingPQI_Id ?
              <input
                type="number"
                className="form-control"
                style={{width:"70%", textAlign:"center"}}
                id={`discount-${data.rowData[2]}`}
                value = {editedDiscount}
                onChange={(e) => handleChangeDiscount(e, data)}
              />
              :
              <p id={`discount-${data.rowData[2]}`}>{row == "" || row == null ? "0" : row}</p>
             }
             {/* <p id={`discount-${data.rowData[2]}`}>{row}</p> */}
            </div>
          );
        },
      },
    },

    isEdit ?
    {
      name: "TOTAL_COST",
      label: "Total Amount (₹)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data, updateValue) => {
          let price = new Intl.NumberFormat("en-IN").format(row);
          return (
            <p
              style={{
                textAlign: "center",
                marginBottom: "0px",
                padding: "7px",
                fontSize: "14px",
              }}
              id={`total-${data.rowData[2]}`}
              // defaultValue={finalPrice}
            >
              {/* {formattedTotalAmount == "" ? price : formattedTotalAmount} */}
              {row == "" || row == null ? "0" : price}
            </p>
          );
        },
      },
    }
    :
    {
      name: "TOTAL_COST",
      label: "Total Amount (₹)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          let price = new Intl.NumberFormat("en-IN").format(row);
          return (
            <div className="" style={{}}>
              <p id={`total-${data.rowData[2]}`}> {row == "" || row == null ? "0" : price}</p>
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <div className="" 
            style={{ 
              display: "flex", alignItems:"center", gap: "1vh",
              // marginLeft: data.rowData[4] != null ? "30px" : "0px", 
            }}
            >
              {
                data.rowData[4] == null ?
                <>
                {
                  isEdit && data.rowData[2] == editingPQI_Id ?
                    <Tooltip title="Save" placement="top" >
                        <SaveIcon 
                          style={{color:"#007cc3", cursor:"pointer", fontSize:"20px",}}
                          onClick={(e) => handleSave(data, e)}
                        />
                      </Tooltip>
                      :
                    <Tooltip title="Edit" placement="top">
                        <ModeEditIcon 
                          style={{color:"#007cc3", cursor:"pointer", fontSize:"20px",
                          display: isDisplayEdit ? "none" : data.rowData[4] != null ? "none" : "flex",
                          
                        }}
                          onClick={(e) => handleEdit(data, e)}
                        />
                      </Tooltip>
                  }
                <Tooltip title="Approve" placement="top">
                  <div className={styles.approveDiv}>
                    <input
                      // className="approve"
                      // className={data.rowData[4] != null ? "approveCheckbox" : "approve"}
                      className="approve"
                      type="radio"
                      id={`approve${data.rowData[2]}`}
                      name={`approval${data.rowData[2]}`}
                      value="1"
                      disabled= {data.rowData[4] != null ? true : false}
                      onChange={(e) => handleChangeApproval(data, e)} 
                      style={{ cursor: data.rowData[4] != null ? "not-allowed" : "auto",}}
                    />
                    <span 
                    style={{
                      border: "none",
                      fontSize: "15px",
                      color: "#007cc3",
                      cursor:"pointer",
                    }}>&#x2714;</span>
                  </div>
                </Tooltip>
                <Tooltip title="Reject" placement="top">
                  <div className={styles.rejectDiv} 
                  style={{opacity: data.rowData[4] != null ? "0.7" : "1",
                  cursor: data.rowData[4] != null ? "not-allowed" : "auto",
                  }}
                  >
                    <input
                      type="radio"
                      // className="reject"
                      // className={data.rowData[4] != null ? "rejectCheckbox" : "reject"}
                      className="reject"
                      id={`reject${data.rowData[2]}`}
                      name={`approval${data.rowData[2]}`}
                      value="0"
                      // disabled= {data.rowData[4] != null ? true : false}
                      // checked={isRejected == "1" ? true : false}
                      checked={data.rowData[4] != null ? true : false}
                      onChange={(e) => handleChangeApproval(data, e)}
                      style={{
                        pointerEvents: data.rowData[4] != null ? "none": "", 
                        
                      }}
                    />
                     <span 
                      style={{
                        border: "none",
                        fontSize: "15px",
                        color: "#ff0000",
                        cursor:"pointer",
                      }}
                        >&#x2717;
                      </span>
                  </div>
                </Tooltip>
                
                </>
                :
                <div style={{padding:"3px"}}>Quotation Rejected</div>
              }
              
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };


  const handleChangePricePerUnit = (e, data) => {
    let newValue = e.target.value;
    if (newValue < 0) {
      //********if price per unit is less than 0, show error */
      msg_popUp("error","Please enter valid Price.")
    }
    else {
      setEditedPricePerUnit(newValue)
      calculationFunc(newValue, editedDiscount, data);
    }
    
  }

  
  const handleChangeDiscount = (e, data) => {
    let newValue = e.target.value;
    if (newValue < 0 || newValue > 100) {
      msg_popUp("error","Please enter valid discount percent." )
      // const Toast = Swal.mixin({
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 1500,
      //   timerProgressBar: true,
      //   didOpen: (toast) => {
      //     toast.addEventListener("mouseenter", Swal.stopTimer);
      //     toast.addEventListener("mouseleave", Swal.resumeTimer);
      //   },
      // });
      // Toast.fire({
      //   icon: "error",
      //   title: "Please enter valid discount percent.",
      // });
    } else {
      setEditedDiscount(newValue)
      calculationFunc(editedPricePerUnit, newValue, data);
    }
  }

  let subTotal = 0;
  let discountPrice = 0;
  let finalPrice = 0;
  let formattedTotalAmount = "";
  let formattedSubTotal = "";
  const calculationFunc = (pricePerUnit, discount, data,) => {
    subTotal = Number(pricePerUnit * data.rowData[6]).toFixed(2);
    discountPrice = Number(subTotal * Number(discount/100)).toFixed(2)
    finalPrice = Number(subTotal - discountPrice).toFixed(2);

    formattedTotalAmount = new Intl.NumberFormat("en-IN").format(finalPrice);
    formattedSubTotal = new Intl.NumberFormat("en-IN").format(subTotal);
    
    setEditedSubTotal(subTotal)
    setEditedFinalAmount(finalPrice)
    document.getElementById(`subTotal-${data.rowData[2]}`).innerText = formattedSubTotal
    document.getElementById(`total-${data.rowData[2]}`).innerText = formattedTotalAmount
    // document.getElementById(`subTotal-${data.rowData[2]}`).innerText = subTotal
    // document.getElementById(`total-${data.rowData[2]}`).innerText = finalPrice
  }

const handleEdit = (data, e) => {
  setEditingPQI_Id(data.rowData[2]);
  setEditedPricePerUnit(data.rowData[7])
  setEditedDiscount(data.rowData[9])
  setPr_id(data.rowData[0])
  setProduct_id(data.rowData[1])
  setisEdit(true)
  calculationFunc(data.rowData[7], data.rowData[9], data);
  document.getElementById(`approve${data.rowData[2]}`).disabled = true;
  document.getElementById(`reject${data.rowData[2]}`).disabled = true;
} 

const handleSave = async (data, e) => {
  e.preventDefault();
  document.getElementById(`approve${data.rowData[2]}`).disabled = false;
  document.getElementById(`reject${data.rowData[2]}`).disabled = false;
  if(
      editedPricePerUnit <= 0 ||
      editedDiscount < 0 || 
      editedDiscount > 100 
    ) {
      msg_popUp("error","Please enter valid value." )
    }
    else {
      let updatedPurchaseQuotationRates = [];
        updatedPurchaseQuotationRates.push({
          UPDATED_BY: user_id,
          PQI_ID: data?.rowData[2],
          PREV_PER_UNIT_COST: data?.rowData[7],
          NEW_PER_UNIT_COST: editedPricePerUnit,
          PREV_DISCOUNT_IN_PER: data?.rowData[9],
          NEW_DISCOUNT_IN_PER: editedDiscount,
          PREV_SUB_COST: data?.rowData[8],
          NEW_SUB_COST: editedSubTotal,
          PREV_TOTAL_COST: data?.rowData[10],
          NEW_TOTAL_COST: editedFinalAmount,
        })
      try {
        let res = await fetch(
          `${base_url}/Purchase_Quotation.svc/update_INDENT_WISE_PURCHASE_QUOTATION_RATE`,
          {
            method: "POST",
            headers: { "Content-Type": "Application/JSON" },
            body: JSON.stringify({
              Purchase_Quotation_Rate_Update : updatedPurchaseQuotationRates,
            }),
          }
        );
        let data = await res.json();
        if (data?.CODE == "200") {
          setisEdit(false)
          // window.location.reload();
          handleClose()
          msg_popUp("success",data?.MESSAGE )
          handleOpen()
          get_product_list(pr_id, product_id);
        } else {
          msg_popUp("error", data?.MESSAGE )
        }
      } catch (error) {
        console.log(error);
        msg_popUp("error", "Something went wrong!" )
      }
    }

}

const handleClearAll = (e) => {
  setisEdit(false)

    const radioButtons = document.querySelectorAll('.approve');
    radioButtons.forEach((radioButton) => {
      radioButton.checked = false;
      radioButton.disabled = false;
    });
    const radioButtonsReject = document.querySelectorAll('.reject');
    radioButtonsReject.forEach((radioButton) => {
      radioButton.checked = false;
      radioButton.disabled = false;
    });

    updatedArray.forEach((ele) => {
      
        const index = updatedArray.findIndex(
          (object) => object.PQI_ID === ele.PQI_ID
        );
        const latestArray = [...updatedArray];
        latestArray[index] = {
          ...latestArray[index],
          IS_QUOTATION_APPROVED: "",
        };
        updatedArray = latestArray;
      
    });
    // document.getElementsByClassName('approve').checked = false;
    // document.getElementsByClassName('reject').checked = false;
  
    // document.getElementsByClassName('approve').disabled = false;
    // document.getElementsByClassName('reject').disabled = false; 
  
    // console.log(document.getElementsByClassName('approve').checked)
    // console.log(document.getElementsByClassName('reject').disabled)

}


  const handleChangeApproval = (data, e) => {
    let newValue = e.target.value;
    if (newValue == "1") {                    // approve
      //setisRejected("1");
      updatedArray.forEach((ele) => {
        if (ele.PQI_ID != data.rowData[2]) {
          document.getElementById(`approve${ele.PQI_ID}`).checked = false;
          document.getElementById(`reject${ele.PQI_ID}`).checked = true;
        }
      });
    } else {
      setTimeout(() => {
        document.getElementById(`approve${data.rowData[2]}`).checked = false;
        document.getElementById(`reject${data.rowData[2]}`).checked = true;
      }, 100);
    }
    updatedArray.forEach((ele) => {
      if (ele.PQI_ID == data.rowData[2]) {
        const index = updatedArray.findIndex(
          (object) => object.PQI_ID === ele.PQI_ID
        );
        const latestArray = [...updatedArray];
        latestArray[index] = {
          ...latestArray[index],
          IS_QUOTATION_APPROVED: newValue,
        };
        updatedArray = latestArray;
      } 
      else if (newValue == "1") {
        const index = updatedArray.findIndex(
          (object) => object.PQI_ID === ele.PQI_ID
        );
        const latestArray = [...updatedArray];
        latestArray[index] = {
          ...latestArray[index],
          IS_QUOTATION_APPROVED: "0",
        };
        updatedArray = latestArray;
      }
    });
    // console.log(updatedArray);
  };





// *****************HandleSubmit as per initial logic, 
// Applied condition for selecting All quotation status is mandatory *****************

  // const handleSubmit = async () => { 
  //   const index1 = updatedArray.findIndex(
  //     (object) => object.IS_QUOTATION_APPROVED == ""
  //   );
  //   // console.log(index1)
  //   if (index1 > -1) {
  //     msg_popUp("error", "Please select all the vendor's approval status!" )
  //   } else {
  //     console.log(index1)
  //     console.log(updatedArray)
  //     try {
  //       let res = await fetch(
  //         `${base_url}/Purchase_Quotation.svc/update_PURCHASE_QUOTATIONS_APPROVAL_STATUS`,
  //         {
  //           method: "POST",
  //           headers: { "Content-Type": "Application/JSON" },
  //           body: JSON.stringify({
  //             USER_ID: user_id,
  //             Purchase_Quotation_Approval: updatedArray,
  //           }),
  //         }
  //       );
  //       let data = await res.json();
  //       if (data?.CODE == "200") {
  //         window.location.reload();
  //         msg_popUp("success", data?.MESSAGE )
  //       } else {
  //         msg_popUp("error", data?.MESSAGE )
  //       }
  //     } catch (error) {
  //       msg_popUp("error", "Something went wrong!" )
  //       console.log(error);
  //     }
  //   }




  //   // else if (index1 === -1) {
  //   //   const index_APPROVED = updatedArray.findIndex((object) => object.IS_QUOTATION_APPROVED == "1");

  //   //   if(index_APPROVED > 0){
  //   //     let cr=0;
  //   //     productData.forEach((ele)=>{
  //   //       if(ele.IS_QUOTATION_APPROVED == "0")
  //   //       cr++;
  //   //     });

  //   //     if(cr>0 && cr!=(updatedArray.length-1)){
  //   //       const Toast = Swal.mixin({
  //   //         toast: true,
  //   //         position: "top-end",
  //   //         showConfirmButton: false,
  //   //         timer: 1500,
  //   //         timerProgressBar: true,
  //   //         didOpen: (toast) => {
  //   //           toast.addEventListener("mouseenter", Swal.stopTimer);
  //   //           toast.addEventListener("mouseleave", Swal.resumeTimer);
  //   //         },
  //   //       });
  //   //       Toast.fire({
  //   //         icon: "error",
  //   //         title: "Please select all the vendor's approval status!",
  //   //       });
  //   //     }
  //   //   }
  //   // }
  // };

  

// *****************Logic Changed, Applied condition for if one quotation is 
//  rejected and submitted then status will be PARTIALLY REJECT      *****************
  const handleSubmit = async () => { 
      let filteredArray = updatedArray.filter(item => item.IS_QUOTATION_APPROVED !== "")
    if (filteredArray.length == 0) {
      msg_popUp("error", "Please select at least one vendor's approval status!" )
    } else {
      try {
        let res = await fetch(
          `${base_url}/Purchase_Quotation.svc/update_PURCHASE_QUOTATIONS_APPROVAL_STATUS`,
          {
            method: "POST",
            headers: { "Content-Type": "Application/JSON" },
            body: JSON.stringify({
              USER_ID: user_id,
              Purchase_Quotation_Approval: filteredArray,
            }),
          }
        );
        let data = await res.json();
        if (data?.CODE == "200") {
          window.location.reload();
          msg_popUp("success", data?.MESSAGE )
        } else {
          msg_popUp("error", data?.MESSAGE )
        }
      } catch (error) {
        msg_popUp("error", "Something went wrong!" )
        console.log(error);
      }
    }
  };



  const handleClear = () => {
    handleClose()
    setIsDisplayEdit(false)
    setisEdit(false)
    subTotal = 0;
    discountPrice = 0;
    finalPrice = 0;
  }

  return (
    <>
      <Modal
        open={open}
        // onClose={handleClear}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="container-fluid">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5 className="tableName">
                Purchase Quotation -
                <span style={{ color: "#007cc3" }}> {productName}</span>
              </h5>
              <button
                className={styles.closeButton}
                // type="button"
                // class="close"
                onClick={handleClear}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {isDetailsLoading ? (
              <Loader />
            ) : (
              <>
                <div
                  className="row"
                  style={{ maxHeight: "60vh", overflowY: "auto" }}
                >
                  <MUIDataTable
                    data={productData}
                    columns={columns}
                    options={{
                      options: options,
                      selectableRows: "none",
                      viewColumns: false,
                      filter: false,
                      print: false,
                      responsive: "standard",
                      download: false,
                      fixedHeader: true,
                      tableBodyMaxHeight: "35vh",
                      pagination: false,
                      rowsPerPageOptions: [10, 25, 50, 100],
                      setTableProps: () => {
                        return {
                          padding: "default",
                        };
                      },
                      customToolbar: () => {
                        return (
                          <Tooltip title="Reset" placement="top" >
                          <RestartAltIcon 
                            style={{color:"#007cc3", cursor:"pointer",}}
                            onClick={(e) => handleClearAll(e)}
                          />
                    </Tooltip>
                        );
                      },
                      textLabels: {
                        body: {
                          noMatch: (
                            <p style={{ fontSize: "16px", margin: "5px 0px" }}>
                              Data Not Available
                            </p>
                          ),
                        },
                      },
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "4vh",
                  }}
                >
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit}
                    disabled={isEdit}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ProductPQList;
