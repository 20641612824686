import React, { useState, useRef } from "react";
import styles from "../styles/Modals.module.css";
import MUIDataTable from "mui-datatables";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  overflowY: "auto",
  // height: "550px",
  maxHeight: "85vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  zIndex: "9",
};

function CreatePOFormModal({
  base_url,
  user_id,
  open,
  handleOpen,
  handleClose,
  PO_product_list,
  PO_vendor_details,
  vendor_id,
  acceptedList,
  offerAcceptedList,
  flag,
  setFlag,
  msg_popUp,
  hubLocationList,
  hubLocationId,
  hubLocationAddress,
  setHubLocationAddress,
  hubLocationName,
  setHubLocationName,
}) {
  const navigate = useNavigate();
  let date = dayjs(new Date());
  let formatDate = moment(date).format("DD/MM/YYYY");
  // const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  let po_date = dayjs(new Date());
  const [subject, setSubject] = useState(
    "Purchase Order for the product of - "
  );

  const [packing, setPacking] = useState("");
  const [freight, setFreight] = useState("");
  const [delivery, setDelivery] = useState("");
  const [payment, setPayment] = useState("");
  const [warranty, setWarranty] = useState("");
  const [document, setDocument] = useState("");
  const [insurance, setInsurance] = useState("");
  const [dispatchAddress, setDispatchAddress] = useState(hubLocationAddress);
  const [gst, setGst] = useState("18");
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedHubAddress, setSelectedHubAddress] = useState("");

  const elementRef = useRef(null);
  const subjectRef = useRef("");
  let subTotal;
  let new_val_gst;
  let gst_price;
  let new_val_finalAmt;
  let finalAmt;

  const [gstAmount, setgstAmount] = useState("");
  const [showGstAmount, setShowGstAmount] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [showFinalAmount, setShowFinalAmount] = useState("");
  const [subTotalAmount, setSubTotalAmount] = useState("");

  // var flag = true;
  const handleCalculation = () => {
    let new_val_gst = (
      Number(PO_vendor_details?.TOTAL_SUB_COST) * 0.18
    ).toFixed(2);
    let gst_price = new Intl.NumberFormat("en-IN").format(new_val_gst);
    let new_val_finalAmt = (
      Number(PO_vendor_details?.TOTAL_SUB_COST) + Number(new_val_gst)
    ).toFixed(2);
    let finalAmt = new Intl.NumberFormat("en-IN").format(new_val_finalAmt);

    setgstAmount(new_val_gst);
    setFinalAmount(new_val_finalAmt);
  };

  setTimeout(() => {
    if (flag) {
      subTotal = new Intl.NumberFormat("en-IN").format(
        PO_vendor_details?.TOTAL_SUB_COST
      );
      setSubTotalAmount(subTotal);

      new_val_gst = (Number(PO_vendor_details?.TOTAL_SUB_COST) * 0.18).toFixed(
        2
      );
      gst_price = new Intl.NumberFormat("en-IN").format(new_val_gst);
      setgstAmount(new_val_gst);
      setShowGstAmount(gst_price);

      new_val_finalAmt = (
        Number(PO_vendor_details?.TOTAL_SUB_COST) + Number(new_val_gst)
      ).toFixed(2);
      finalAmt = new Intl.NumberFormat("en-IN").format(new_val_finalAmt);
      setFinalAmount(new_val_finalAmt);
      setShowFinalAmount(finalAmt);

      setSubject(
        "Purchase Order for the product of - " + PO_vendor_details.PRODUCT_NAMES
      );
    }
    // setFlag(false)
  }, 700);

  const columns = [
    {
      name: "PQI_ID",
      label: "PR ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PRODUCT_ID",
      label: "Product ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: "Sr. No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <p
              style={{
                // width: "3vw",
                // textAlign: "center",
                marginBottom: "0px",
                padding: "5px",
                // fontSize:"14px"
              }}
            >
              {data.rowIndex + 1}
            </p>
          );
        },
      },
    },
    {
      name: "PRODUCT_NAME",
      label: "Product Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "PACKAGING_UNIT",
      label: "Description",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return row ? (
            <p style={{ textAlign: "left" }}>{row}</p>
          ) : (
            <p style={{ textAlign: "center" }}>-</p>
          );
        },
      },
    },
    {
      name: "QUANTITY",
      label: "Quantity",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "PER_UNIT_COST",
      label: "Price Per Unit (₹)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          let price = new Intl.NumberFormat("en-IN").format(row);
          return <p style={{}}>{price ? price : "-"}</p>;
        },
      },
    },
    {
      name: "SUB_COST",
      label: "Sub Total (₹)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          let price = new Intl.NumberFormat("en-IN").format(row);
          return <p style={{}}>{price ? price : "-"}</p>;
        },
      },
    },

    {
      name: "DISCOUNT",
      label: "Discount (%)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return <p style={{}}>{row ? row : "-"}</p>;
        },
      },
    },

    {
      name: "TOTAL_COST",
      label: "Total Amount (₹)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          let price = new Intl.NumberFormat("en-IN").format(row);
          return <p style={{}}>{price ? price : "-"}</p>;
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  const handleGstChange = (e) => {
    setFlag(false);
    setGst(e.target.value);
    let gstAmt = 0;
    finalAmt = "";
    gst_price = "";
    let final_price = "";
    if (PO_vendor_details.TOTAL_SUB_COST) {
      let gstFrac = Number(e.target.value) / 100;
      gstAmt = Number(PO_vendor_details.TOTAL_SUB_COST) * gstFrac;

      new_val_gst = Number(gstAmt).toFixed(2);
      gst_price = new Intl.NumberFormat("en-IN").format(new_val_gst);
      setShowGstAmount(gst_price);
      setgstAmount(new_val_gst);

      let finalAmt = Number(
        +PO_vendor_details.TOTAL_SUB_COST +
          Number(PO_vendor_details.TOTAL_SUB_COST) * gstFrac
      ).toFixed(2);

      setFinalAmount(finalAmt);
      final_price = new Intl.NumberFormat("en-IN").format(finalAmt);
      setShowFinalAmount(final_price);
    }
  };

  function filter(node) {
    return node.tagName !== "i";
  }

  const handleSubmit = async () => {
    if (
      // packing == "" ||
      // freight == "" ||
      // freight == "" ||
      // payment == "" ||
      // warranty == "" ||
      // document == "" ||
      // insurance == "" ||
      // dispatchAddress == ""
      hubLocationAddress == ""
    ) {
      msg_popUp("error", "Please enter dispatch address!");
    } else {
      handleSubmitForm();
      // let dataSVG;
      // setHideStar(true);
      // var node = document?.getElementById('my-node');
      // setTimeout(() => {
      // var node = elementRef.current;
      // domtoimage.toSvg(node, { filter: filter }).then(function (dataUrl) {
      // console.log(dataUrl)
      // dataSVG = dataUrl.replace("data:image/svg+xml;charset=utf-8,", "");
      // handleSubmitForm(dataSVG);
      // console.log(dataSVG)
      //   });
      // }, 700);
    }
  };

  const handleSubmitForm = async () => {
    setIsDisabled(true);
    let subjectValue = subjectRef.current.value;
    try {
      let res = await fetch(
        `${base_url}/Purchase_Order.svc/create_PURCHASE_ORDER_V1`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            VENDOR_ID: vendor_id,
            USER_ID: user_id,
            PO_NUMBER: PO_vendor_details.PO_NUMBER,
            // PO_DATE: moment(selectedDate).format("DD-MM-YYYY"),
            PO_DATE: moment(po_date).format("DD-MM-YYYY"),
            // PO_SUBJECT: subject,
            PO_SUBJECT: subjectValue,
            // DISPATCH_ADDRESS: dispatchAddress,
            DISPATCH_ADDRESS: hubLocationAddress,
            PACKING: packing,
            FREIGHT: freight,
            DELIVERY: delivery,
            PAYMENT: payment,
            WARRANTY: warranty,
            DOCUMENT: document,
            INSURANCE: insurance,
            PURCHASE_ORDER_ITEM: offerAcceptedList,
            // PO_HTML_STRING: dataSVG,
            PO_HTML_STRING: "",
            GST_IN_PERC: gst,
            GST_AMOUNT: gstAmount,
            SUB_TOTAL: PO_vendor_details.TOTAL_SUB_COST,
            TOTAL_PO_AMOUNT: finalAmount,
          }),
        }
      );
      let data = await res.json();
      if (data?.CODE == "200") {
        msg_popUp("success", data?.MESSAGE);
        setIsDisabled(false);
        // navigate("/purchaseOrderList");
        window.location.reload();
      } else {
        setIsDisabled(false);
        msg_popUp("error", data?.MESSAGE);
      }
    } catch (error) {
      setIsDisabled(false);
      console.log(error);
    }
  };

  const onChangeHubLocation = (e) => {
    setSelectedHubAddress(e.target.value)
    setHubLocationAddress(e.target.value)
  }

  const onChangeDispatchAddress = (e) => {
    setHubLocationAddress(e.target.value)
  }

  const handleCancel = () => {
    handleClose();
    setPacking("");
    setFreight("");
    setDelivery("");
    setPayment("");
    setWarranty("");
    setDocument("");
    setInsurance("");
    setDispatchAddress("");
    setHubLocationAddress("");
    setSubject("");
    setIsDisabled(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div id="check"></div>
          <div className="container-fluid" ref={elementRef} id="my-node">
            <div
              // id="my-node"
              className=""
              style={{
                textAlign: "left",
                marginBottom: "5vh",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Create PO Form</h4>
              <button
                className={styles.closeButton}
                // type="button"
                // class="close"
                onClick={handleCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div>
              <div
                className="row mb-2"
                style={{ dispaly: "flex", justifyContent: "space-between" }}
              >
                <input
                  className="col-md-8 col-sm-12 form-control fs-14"
                  style={{ width: "82%" }}
                  type="text"
                  value={PO_vendor_details.PO_NUMBER}
                  disabled
                />
                <div className="col-md-2">
                  <Space direction="vertical">
                    <DatePicker
                      defaultValue={dayjs(formatDate, "DD/MM/YYYY")}
                      format="DD MMM YYYY"
                      disabled
                    />
                  </Space>
                </div>
              </div>
              <div
                className="row mb-2"
                style={{ dispaly: "flex", justifyContent: "space-between" }}
              >
                <input
                  className="col-md-6 col-sm-12 form-control fs-14"
                  style={{ width: "60%" }}
                  type="text"
                  value={PO_vendor_details.VENDOR_COMPANY_NAME}
                  disabled
                />
                <input
                  className="col-md-5 col-sm-12 form-control fs-14"
                  style={{ width: "39%" }}
                  type="text"
                  value={
                    "Kind Attn: " +
                    PO_vendor_details.VENDOR_NAME +
                    " (" +
                    PO_vendor_details.VENDOR_CONTACT_NO +
                    ")"
                  }
                  disabled
                />
              </div>
              <div className="row mb-2">
                <input
                  className="col-md-12 col-sm-12 form-control fs-14"
                  type="text"
                  value={PO_vendor_details.ADDRESS}
                  disabled
                />
              </div>
              <div className="row mb-2">
                <input
                  className="col-md-12 col-sm-12 form-control fs-14"
                  type="text"
                  defaultValue={
                    "Purchase Order for the product of - " +
                    PO_vendor_details.PRODUCT_NAMES
                  }
                  id="subjectID"
                  ref={subjectRef}
                  // onChange={(e) => setSubject(e.target.value)}
                  // onChange={(e) => handleSubjectChange(e.target.value)}
                  // value={ "Purchase Order for the product of - " + subject}
                  required
                />
              </div>
              <div
                className="row"
                style={{
                  maxHeight: "50vh",
                  overflowY: "auto",
                  marginTop: "5vh",
                }}
              >
                <MUIDataTable
                  data={PO_product_list}
                  columns={columns}
                  options={{
                    options: options,
                    selectableRows: "none",
                    viewColumns: false,
                    filter: false,
                    search: false,
                    print: false,
                    responsive: "standard",
                    download: false,
                    fixedHeader: true,
                    tableBodyMaxHeight: "500px",
                    pagination: false,
                    rowsPerPageOptions: [10, 25, 50, 100],
                    setTableProps: () => {
                      return {
                        padding: "default",
                      };
                    },
                    textLabels: {
                      body: {
                        noMatch: (
                          <p style={{ fontSize: "16px", margin: "5px 0px" }}>
                            Data Not Available
                          </p>
                        ),
                      },
                    },
                  }}
                />
              </div>
              <div className="row" style={{ marginTop: "3vh" }}>
                <div className="row">
                  <div className="col-md-8"></div>
                  <div
                    className="col-md-4"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      marginBottom: "2vh",
                    }}
                  >
                    <label>Sub-Total: </label>
                    <input
                      className=" form-control fs-14"
                      style={{ width: "75%" }}
                      type="text"
                      // value={PO_vendor_details.TOTAL_SUB_COST}
                      value={subTotalAmount}
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7"></div>
                  <div
                    className="col-md-5"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      marginBottom: "2vh",
                    }}
                  >
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "56%",
                      }}
                    >
                      GST @
                      <select
                        // className="form-control"
                        id="gstPercent"
                        style={{
                          width: "50%",
                          padding: "3px",
                          borderRadius: "6px",
                        }}
                        onChange={(e) => handleGstChange(e)}
                      >
                        <option value="18">18%</option>
                        <option value="12">12%</option>
                        <option value="5">5%</option>
                        <option value="0">0%</option>
                        {/* <ArrowDropDownIcon /> */}
                      </select>
                      :{" "}
                    </label>
                    <input
                      className=" form-control fs-14"
                      style={{ width: "80%" }}
                      type="text"
                      value={showGstAmount}
                      // value={
                      //   gstAmount == "" || isNaN(gstAmount)
                      //     ? gst_price
                      //     : gstAmount
                      // }
                      // defaultValue={gst_price}
                      disabled
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-7"></div>
                  <div
                    className="col-md-5"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      marginBottom: "2vh",
                    }}
                  >
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "48%",
                      }}
                    >
                      {" "}
                      Final P.O Amount :{" "}
                    </label>
                    <input
                      className=" form-control fs-14"
                      style={{ width: "70%" }}
                      type="text"
                      value={showFinalAmount}
                      // value={
                      //   finalAmount == "" || isNaN(finalAmount)
                      //     ? finalAmt
                      //     : finalAmount
                      // }
                      // defaultValue={finalAmt}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6" style={{ marginTop: "3vh" }}>
                  <h6>Commercial Terms & Conditions</h6>
                  <div
                    // className="col-md-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                      marginBottom: "2vh",
                    }}
                  >
                    <label>Packing :</label>
                    <input
                      className=" form-control fs-14"
                      style={{ width: "70%" }}
                      type="text"
                      // value={"₹ - " + "6541"}
                      onChange={(e) => setPacking(e.target.value)}
                      value={packing}
                    />
                  </div>
                  <div
                    // className="col-md-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                      marginBottom: "2vh",
                    }}
                  >
                    <label>Freight :</label>
                    <input
                      className=" form-control fs-14"
                      style={{ width: "70%" }}
                      type="text"
                      // value={"₹ - " + "6541"}
                      onChange={(e) => setFreight(e.target.value)}
                      value={freight}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                      marginBottom: "2vh",
                    }}
                  >
                    <label>Delivery :</label>
                    <input
                      className=" form-control fs-14"
                      style={{ width: "70%" }}
                      type="text"
                      // value={"₹ - " + "6541"}
                      onChange={(e) => setDelivery(e.target.value)}
                      value={delivery}
                    />
                  </div>
                  <div
                    // className="col-md-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                      marginBottom: "2vh",
                    }}
                  >
                    <label>Payment :</label>
                    <input
                      className=" form-control fs-14"
                      style={{ width: "70%" }}
                      type="text"
                      // value={"₹ - " + "6541"}
                      onChange={(e) => setPayment(e.target.value)}
                      value={payment}
                    />
                  </div>
                  <div
                    // className="col-md-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                      marginBottom: "2vh",
                    }}
                  >
                    <label>Warranty :</label>
                    <input
                      className=" form-control fs-14"
                      style={{ width: "70%" }}
                      type="text"
                      // value={"₹ - " + "6541"}
                      onChange={(e) => setWarranty(e.target.value)}
                      value={warranty}
                    />
                  </div>
                  <div
                    // className="col-md-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                      marginBottom: "2vh",
                    }}
                  >
                    <label>Document :</label>
                    <input
                      className=" form-control fs-14"
                      style={{ width: "70%" }}
                      type="text"
                      // value={"₹ - " + "6541"}
                      onChange={(e) => setDocument(e.target.value)}
                      value={document}
                    />
                  </div>
                  <div
                    // className="col-md-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                      // marginBottom: "2vh",
                    }}
                  >
                    <label>Insurance :</label>
                    <input
                      className=" form-control fs-14"
                      style={{ width: "70%" }}
                      type="text"
                      // value={"₹ - " + "6541"}
                      onChange={(e) => setInsurance(e.target.value)}
                      value={insurance}
                    />
                  </div>
                </div>
                
                  <div
                    className="col-md-6"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginTop: "3vh",
                    }}
                  >
                    <h6>
                      Dispatch Address<span style={{ color: "red" }}>*</span> :
                    </h6>
                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "5px",
                          marginBottom: "12px",
                        }}
                      >
                        <label style={{width:"80%"}}>Delivery Location :</label>
                        <select
                            className="form-control"
                            aria-label="Default select example"
                            defaultValue={hubLocationName}
                            name="LocationSelect"
                            style={{
                              fontSize:"12px"
                            }}
                            onChange={(e) => onChangeHubLocation(e)}
                          >
                            <option>{hubLocationName}</option>
                            {
                              hubLocationList?.map((ele) => (
                                <>
                                  <option 
                                    value={ele.HUB_ADDRESS}
                                    // selected={ele.HUB_GUID == hubLocationId ? true : false}
                                  >
                                  {ele.HUB_NAME}
                                </option>
                                </>
                              ))
                            }
                            {/* <ArrowDropDownIcon /> */}
                          </select>
                      </div>
                    </div>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="4"
                      className="form-control"
                      style={{
                        // width: "80%",
                        height: "200px",
                        minHeight: "150px",
                        maxHeight: "230px",
                        fontSize:"12px",
                      }}
                      // defaultValue={hubLocationAddress}
                      // onChange={(e) => setDispatchAddress(e.target.value)}
                      onChange={(e) => onChangeDispatchAddress(e)}
                      value={hubLocationAddress}
                      // value={dispatchAddress}
                    ></textarea>

                    <div style={{ margin: "auto", display: "flex", gap: "4vw" }}>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={handleSubmit}
                        disabled={isDisabled}
                      >
                        Submit
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={handleCancel}
                        disabled={isDisabled}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                
              </div>
            </div>

            {/* // ))} */}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default CreatePOFormModal;
