import "./App.css";
import All_Routes from "./routes/All_Routes";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";


function App({

}) {
  const storage = window.sessionStorage;
  const navigate = useNavigate();
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");
  let DEPT_ID = storage.getItem("DEPT_ID");

    useEffect(() => {
    if (USER_GUID == "" || Role_id == "" || DEPT_ID == "") {
      navigate("/");
    }
  }, [storage]);

  
  return (
    <div className="App" style={{ marginTop: "5em", marginLeft: "4em" }}>
      <All_Routes />
    </div>
  );
}

export default App;
