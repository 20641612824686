import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/MiniDrawer";
import MUIDataTable from "mui-datatables";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loader from "../components/Loader";
import {  useNavigate } from "react-router-dom";
import CallSplitIcon from '@mui/icons-material/CallSplit';
import VendorRFQByPass from "../Modals/VendorRFQByPass";

function PurchaseQuotation({ base_url, msg_popUp }) {
  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");
  let role_id = storage.getItem("Role_id");
  const navigate = useNavigate();

  const [purchaseQuoteList, setPurchaseQuoteList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [indentNoList, setIndentNoList] = useState([]);
  const [indentDropdownData, setIndentDropdownData] = useState([]);

  const [openVendorRFQ, setOpenVendorRFQ] = useState(false);
  const handleOpenVendorRFQ = () => setOpenVendorRFQ(true);
  const handleCloseVendorRFQ = () => setOpenVendorRFQ(false);


  const get_purchaseQuote_list = async () => {
    setIsLoading(true);
    try {
      let res = await fetch(
        `${base_url}/Purchase_Quotation.svc/get_PURCHASE_QUOTATIONS_SENT_LIST`
      );
      let data = await res.json();
      setPurchaseQuoteList(data?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_purchaseQuote_list();
  }, []);

  const columns = [
    {
      name: "PR_ID",
      label: "PR ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "CREATED_BY",
      label: "PR ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "DEPT_ID",
      label: "Dept ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "INDENT_NO",
      label: "Indent No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ maxWidth: "20vw", marginBottom: "0px" }}>{row}</p>;
        },
      },
    },
    {
      name: "CREATED_BY_NAME",
      label: "Added By",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "DEPT_NAME",
      label: "Department",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "TOTAL_PRODUCT_COUNT",
      label: "No. of Products",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "TOTAL_RECEIVED_QUOTATION_COUNT",
      label: "Quotations Received",
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (row, data, updateValue) => {
        //   return (
        //     <div style={{maxWidth:"25vw", width:"20vw"}}>

        //     </div>
        //   );
        // },
      },
    },
    {
      name: "",
      label: "View",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          
          return (
              <div
            // style={{maxWidth:"25vw", width:"20vw"}}
            >
              <VisibilityIcon
                onClick={() => handleOpenView(data)}
                style={{ 
                  fill: "#007cc3", 
                  cursor: "pointer",
                  display: data.rowData[7] > 0 ? "flex" : "none",
                }}
              />
            </div>
            
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  const handleOpenView = (data) => {
    let pr_id = data.rowData[0];
    storage.setItem("PR_ID", pr_id);
    navigate("/viewPurchaseQuotation");
  };

  
  const handleOpenVendorBypass = async () => {
    handleOpenVendorRFQ();
    try{
      let res = await fetch(`${base_url}/Purchase_Request.svc/get_ALL_INDENT_SENT_LIST_VENDOR_RFQ_BYPASS`);
      let data = await res.json();
      if(data?.response?.CODE == "200"){
        let temp_arr = [];
        data?.data?.forEach((ele) => {
          temp_arr.push({
            label: ele.INDENT_NO,
            value: ele.PR_ID,
          });
        });
        setIndentDropdownData(temp_arr)
        setIndentNoList(data?.data);
      }
      else {
        handleCloseVendorRFQ();
        msg_popUp("error", "Something went wrong!");
      }
      
    } 
    catch(error){
      console.log(error)
    }
  }
 
  return (
    <>
      <MiniDrawer header_name="Purchase Quotation" />
      <div className="container-fluid">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <MUIDataTable
              title={
                <h4
                  className="tableName"
                  style={{ textAlign: "left", marginBottom: "0px" }}
                >
                  Purchase Quotation
                </h4>
              }
              data={purchaseQuoteList}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: false,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                tableBodyMaxHeight: "63vh",
                rowsPerPageOptions: [10, 25, 50, 100],
                setTableProps: () => {
                  return {
                    padding: "default",
                  };
                },
                customToolbar: () => {
                  return (
                    <button
                      className="btn btn-outline-primary btn-sm addButton"
                      onClick={handleOpenVendorBypass}
                    >
                      Add Vendor RFQ&nbsp;<CallSplitIcon style={{fontSize:"16px"}} />
                    </button>
                  );
                },
                textLabels: {
                  body: {
                    noMatch: (
                      <p style={{ fontSize: "16px", margin: "5px 0px" }}>
                        Data Not Available
                      </p>
                    ),
                  },
                },
              }}
            />
          </>
        )}
        <VendorRFQByPass 
          base_url={base_url}
          user_id={user_id}
          open={openVendorRFQ}
          handleOpen={handleOpenVendorRFQ}
          handleClose={handleCloseVendorRFQ}
          indentNoList={indentNoList}
          indentDropdownData={indentDropdownData}
          setIndentDropdownData={setIndentDropdownData}
          msg_popUp={msg_popUp}
        />
      </div>
    </>
  );
}

export default PurchaseQuotation;
