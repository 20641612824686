import React from 'react'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import financialStrategy from "../Media/financial-strategy.png"

function Loader() {

  return (
    <>
    <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"15vh"}}>
      <Box sx={{ width: '20%',display:"flex", flexDirection:"column" }}>
        <img className="img-fluid" src={financialStrategy} alt="" />
        <LinearProgress  />
      </Box>
    </div>
    </>
  )
}

export default Loader
