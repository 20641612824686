import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Drawer, Modal, Tooltip, Typography } from "@mui/material";
import styles from "../styles/Modals.module.css";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import Select from "react-select";
import Loader from "../components/Loader";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "75%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  overflowY: "auto",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  zIndex: "9",
};

function ViewPurchaseRequest({
  user_id,
  base_url,
  purchaseData,
  purchaseDetailData,
  open,
  handleOpen,
  handleClose,
  isDetailsLoading,
  setIsDetailsLoading,
  indentNo,
  addedBy,
  addedOn,
  vendorList,
  selectedVendors,
  setSelectedVendors,
  vendorDataList,
  setVendorDataList,
  msg_popUp,
}) {
  // const theme = useTheme();
  // const storage = window.sessionStorage;
  // let user_id = storage.getItem("USER_GUID");
  // let role_id = storage.getItem("Role_id");

  // const [vendorDataList, setVendorDataList] = useState([])
  const [isDisableButton, setIsDisableButton] = useState(true);
  const [updated_val,setUpdated_Val] = useState([])
  const [isDisabled,setIsDisabled] = useState(false)


  var wholeArray = [];
  // let counter = 1;

  // const handleSelectVendor = (e, data, updateValue) => {
  //   if (wholeArray.length == 0) {
  //     for (let i = 0; i < data.currentTableData.length; i++) {
  //       wholeArray.push(data.currentTableData[i].data);
  //     }
  //     let newar = [];
  //     for (let j = 0; j < wholeArray.length; j++) {
  //       if (j == data.rowIndex) {

  //         newar.push({
  //           0: wholeArray[j][0],
  //           1: wholeArray[j][1],
  //           2: wholeArray[j][2],
  //           3: wholeArray[j][3],
  //           4: wholeArray[j][4],
  //           5: wholeArray[j][5],
  //           6: e,
  //         });
  //       }
  //       else {
  //         // newar.push(wholeArray[j]);
  //         newar.push({
  //           0: wholeArray[j][0],
  //           1: wholeArray[j][1],
  //           2: wholeArray[j][2],
  //           3: wholeArray[j][3],
  //           4: wholeArray[j][4],
  //           5: wholeArray[j][5],
  //           6:  typeof(wholeArray[j][6]) == String ? [] : wholeArray[j][6]
  //         })
  //       }
  //     }

  //     wholeArray = [];
  //     wholeArray.push(newar);
  //   } else {
  //     let newar1 = [];
  //     for (let i = 0; i < wholeArray[0].length; i++) {
  //       if (i == data.rowIndex) {
  //         newar1.push({
  //           0: wholeArray[0][i][0],
  //           1: wholeArray[0][i][1],
  //           2: wholeArray[0][i][2],
  //           3: wholeArray[0][i][3],
  //           4: wholeArray[0][i][4],
  //           5: wholeArray[0][i][5],
  //           6: e,
  //         });
  //       } else {
  //         // newar1.push(wholeArray[0][i]);
  //         newar1.push({
  //             0:wholeArray[0][i][0],
  //             1:wholeArray[0][i][1],
  //             2:wholeArray[0][i][2],
  //             3:wholeArray[0][i][3],
  //             4:wholeArray[0][i][4],
  //             5:wholeArray[0][i][5],
  //             // 6:wholeArray[0][i][6] == undefined ? [] : wholeArray[0][i][6]
  //             6:typeof wholeArray[0][i][6] == String ? [] : wholeArray[0][i][6]
  //           })
  //       }
  //     }

  //     wholeArray = [];
  //     wholeArray.push(newar1);
  //     console.log(wholeArray)
  //   }
  //   // if (wholeArray.length > 0) {
  //   //   setIsDisableButton(false);
  //   // } else {
  //   //   setIsDisableButton(true);
  //   // }
  // };

  const handleSelectVendor = (e, data, updateValue) => {
    if (wholeArray.length == 0) {
      for (let i = 0; i < data.currentTableData.length; i++) {
        wholeArray.push(data.currentTableData[i].data);
      }
      let newar = [];
      for (let j = 0; j < wholeArray.length; j++) {
        if (j == data.rowIndex) {
          const latestArray = [...wholeArray];
          latestArray[j] = {
            ...latestArray[j],
            6: e,
          };
          wholeArray = latestArray;
        } else {
          // newar.push(wholeArray[j]);
          const latestArray = [...wholeArray];
          latestArray[j] = {
            ...latestArray[j],
            6: [],
          };
          wholeArray = latestArray;
        }
      }

      // wholeArray = [];
      // wholeArray.push(newar);
    } else {
      let newar1 = [];
      const latestArray = [...wholeArray];
      latestArray[data.rowIndex] = {
        ...latestArray[data.rowIndex],
        6: e,
      };
      wholeArray = latestArray;
      // wholeArray = [];
      // wholeArray.push(newar1);
    }
    // if (wholeArray.length > 0) {
    //   setIsDisableButton(false);
    // } else {
    //   setIsDisableButton(true);
    // }
  };

  const handleSubmit = async () => {
    setIsDisabled(true)
    let errorCount = 0;
    let finalArray = [];

    wholeArray?.forEach((e) => {
      //ele.forEach((e) => {
      if (
        e[6] == undefined ||
        e[6] == [] ||
        e[6].length == 0 ||
        wholeArray.length == 0
      ) {
        errorCount++;
      }
      if (
        e[6] != undefined ||
        e[6] != [] ||
        e[6].length > 0 ||
        wholeArray.length > 0
      ) {
        e[6].forEach((i) => {
          finalArray.push({
            VENDOR_ID: i.value,
            PRI_ID: e[0],
          });
        });
      } else {
        errorCount++;
      }
      //});
    });

    if (errorCount > 0 || finalArray.length == 0) {
      setIsDisabled(false)
      msg_popUp("error", "Please select vendor of all the listed products!")
    } else {
      setIsDisabled(true)
      try {
        let res = await fetch(
          `${base_url}/Purchase_Request.svc/send_PURCHASE_REQUEST_QUOTATION`,
          {
            method: "POST",
            headers: { "Content-Type": "Application/JSON" },
            body: JSON.stringify({
              USER_ID: user_id,
              PURCHASE_QUOTATION_ITEMS: finalArray,
            }),
          }
        );
        let data = await res.json();
        if (data?.CODE == "200") {
          wholeArray = [];
          finalArray = [];
          handleCloseModal();
          window.location.reload();
          msg_popUp("success", "Purchase request sent Successfully!")
          setIsDisabled(false)
        } else {
          setIsDisabled(false)
          msg_popUp("error", "Something Went Wrong!")
        }
      } catch (error) {
        setIsDisabled(false)
        console.log(error);
      }
    }
  };

  const columns = [
    {
      name: "PRI_ID",
      label: "PRI ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "PR_ID",
      label: "PR ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "VENDOR_DATA",
      label: "VENDOR DATA",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "CATEGORY_NAME",
      label: "Category",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ 
            width: "10vw", display: "flex", justifyContent:"center" ,marginBottom: "0px" 
          }}>
            {row}
          </p>
        },
      },
    },
    {
      name: "PRODUCT_NAME",
      label: "Product Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ 
            width: "13vw", display: "flex", justifyContent:"center" ,marginBottom: "0px" 
          }}>
            {row}
          </p>
        },
      },
    },
    {
      name: "PACKAGING_UNIT",
      label: "Description",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ 
            width: "12vw", display: "flex", justifyContent:"center" ,marginBottom: "0px" 
          }}>
            {row}
          </p>
        },
      },
    },
    {
      name: "QUANTITY",
      label: "Quantity",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ 
            width: "5vw", display: "flex", justifyContent:"center" ,marginBottom: "0px" 
          }}>
            {row}
          </p>
        },
      },
    },
    {
      name: "",
      label: "Vendors",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data, updateValue) => {
          return (
            <div style={{ maxWidth: "20vw", width: "15vw" }}>
              <Select
                isMulti
                name="label"
                options={data?.rowData[2] ? JSON.parse(data?.rowData[2]) : []}
                className="basic-multi-select"
                classNamePrefix="select"
                sx={{ color: "black" }}
                closeMenuOnSelect={false}
                onChange={(e) => handleSelectVendor(e, data, updateValue)}
                // menuPosition="fixed"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                maxMenuHeight="140px"
              />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  const handleCloseModal = () => {
    handleClose();
    wholeArray = [];
    setIsDisableButton(true);
    // counter = 0;
  };

  return (
    <>
      <Modal
        open={open}
        // onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="container-fluid">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>Purchase Details</h5>
              <button
                className={styles.closeButton}
                // type="button"
                // class="close"
                onClick={handleCloseModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {isDetailsLoading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <div>
                <div className="row mt-3 ml-0 mb-2">
                  <div className="col-6">
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                      <span>Indent No.: </span>
                      {indentNo == "" ? "---" : indentNo}
                    </p>
                  </div>
                  <div className="col-3 p-0">
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                      <span>Added On: </span>
                      {addedOn == "" ? "---" : moment(addedOn).format("DD MMM YYYY")}
                    </p>
                  </div>
                  <div className="col-3 p-0">
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                      <span>Added By: </span>
                      {addedBy == "" ? "---" : addedBy}
                    </p>
                  </div>
                </div>

                <div id="categoryTable" className="categoryTable">
                  <MUIDataTable
                    className="myTable"
                    data={purchaseDetailData}
                    columns={columns}
                    options={{
                      options: options,
                      selectableRows: "none",
                      viewColumns: false,
                      filter: false,
                      print: false,
                      responsive: "standard",
                      download: false,
                      fixedHeader: true,
                      // tableBodyMaxHeight: "30vh",
                      pagination: false,
                      // tableBodyHeight: "260px",
                      rowsPerPageOptions: [10, 25, 50, 100],
                      setTableProps: () => {
                        return {
                          padding: "default",
                        };
                      },
                      // customToolbar: () => {
                      //   return (
                      //     <div style={{ display: "inline" }}>
                      //       <Space direction="vertical">
                      //         <DatePicker
                      //           onChange={onMonthChange}
                      //           picker="month"
                      //           format="MMM-YYYY"
                      //           value={selectedMonth}   
                      //         />
                      //       </Space>
                      //     </div>
                      //   );
                      // },
                      textLabels: {
                        body: {
                          noMatch: "Data Not Available",
                        },
                      },
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "4vh",
                    }}
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleSubmit}
                      disabled={isDisabled}
                      // disabled={counter > 0 ? true : false}
                    >
                      Send Purchase Quote
                    </button>
                  </div>

                </div>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ViewPurchaseRequest;
