import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import PaymentsIcon from "@mui/icons-material/Payments";
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';

function PaymentTimelineDrawer({
  base_url,
  user_id,
  open,
  handleOpen,
  handleClose,
  po_id,
  POPaymentTimeline,
  isTimelineLoading
}) {
  return (
    <>
      <div className="">
        <h4
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Payment Timeline
        </h4>
      </div>
      <div style={{ width: "26vw", marginTop: "20px" }}>
        {
        isTimelineLoading ?
        <>
          <div style={{position:"absolute", left:"50%", top:"50%"}}>
            <CircularProgress />
          </div>
        </>
        :
        POPaymentTimeline.length > 0 ? (
          <Timeline>
            {POPaymentTimeline?.map((ele) => (
              <Timeline position="alternate">
                <TimelineItem
                  sx={{
                    borderBottom: "1px solid #c1bbbb",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                  }}
                >
                  <TimelineOppositeContent
                    sx={{
                      m: "auto 0",
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "12px",
                    }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                  >
                    <p sx={{ fontSize: "14px" }}>
                      ₹&nbsp;
                      {new Intl.NumberFormat("en-IN").format(
                        ele.PAYMENT_AMOUNT
                      )}
                    </p>
                    {ele.PAYMENT_ID.length > 20 ? (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          width: "12vw",
                          overflowX: "auto",
                        }}
                      >
                        <marquee scrollamount="5">
                          <span
                            style={{ fontSize: "10px", fontStyle: "italic" }}
                          >
                            Ref:
                          </span>
                          {ele.PAYMENT_ID}
                        </marquee>
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          width: "12vw",
                          overflowX: "auto",
                        }}
                      >
                        <span style={{ fontSize: "10px", fontStyle: "italic" }}>
                          Ref:
                        </span>
                        {ele.PAYMENT_ID}
                      </Typography>
                    )}
                    <div style={{width:"100%"}}>
                      <a 
                        sx={{ fontSize: "10px", width:"fit-content" }} 
                        href={ele.PAYMENT_RECEIPT_PATH} target="blank"
                      >
                        View File
                      </a>
                    </div>
                    
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                      sx={{
                        margin: "5px 0",
                        backgroundColor: "#007cc3",
                        // ele.PAYMENT_TYPE == "Cash" ? "#D8AE7E" :
                        //   ele.PAYMENT_TYPE == "Cheque" ? "#CA8787" :
                        //   ele.PAYMENT_TYPE == "RTGS" ? "#F97300" :
                        //   ele.PAYMENT_TYPE == "NEFT" ? "00215E" :
                        //   ele.PAYMENT_TYPE == "Instamojo" ? "#4F6F52" :
                        //   ele.PAYMENT_TYPE == "IMPS" ? "#803D3B" :
                        //   ele.PAYMENT_TYPE == "UPI" ? "#135D66" :
                        //  "#54B435",
                      }}
                    >
                      <PaymentsIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Typography
                      variant="h6"
                      component="span"
                      sx={{ fontSize: "12px" }}
                    >
                      {ele.PAYMENT_TYPE}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        maxWidth: "10vw",
                        overflowX: "auto",
                      }}
                    >
                      {/* {ele.PAYMENT_DATE} */}
                      {moment(ele.PAYMENT_DATE).format("DD MMM YYYY")}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            ))}
          </Timeline>
        ) : (
          <>
            <div style={{ position: "absolute", left: "38%", top: "50%" }}>
              <h6>No Data Available</h6>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default PaymentTimelineDrawer;
